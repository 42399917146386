import React from 'react';

import {
  Container,
  InputContainer,
  Input,
} from './styles'
import { colors } from 'theme/colors';

export const SearchTextInput = ({
  value,
  isDisabled = false,
  placeholder,
  keyboardType,
  autoCapitalize,
  onChangeText,
  autoFocus,
}) => {
  return (
    <Container>
      <InputContainer style={{ backgroundColor: (isDisabled ? colors.ui.disabled : colors.pure.white) }}>
        <Input
          value={value}
          placeholder={placeholder}
          onChangeText={onChangeText}
          keyboardType={keyboardType}
          autoCapitalize={autoCapitalize}
          autoFocus={autoFocus}
          editable={typeof onChangeText === 'function'}
        />
      </InputContainer>
    </Container>
  );
};
