import React from 'react';

import {
  Container,
  TitleContainer,
  LabelText,
  RequiredText,
  InputContainer,
  Input,
} from './styles'
import { colors } from 'theme/colors';

export const FormFieldText = ({
  label,
  value,
  required = false,
  isDisabled = false,
  placeholder,
  keyboardType,
  autoCapitalize,
  autoComplete,
  onChangeText,
  autoFocus,
}) => {
  return (
    <Container>
      <TitleContainer>
        <LabelText>{label}</LabelText>
        {required && <RequiredText>(Required)</RequiredText>}
      </TitleContainer>
      <InputContainer style={{ backgroundColor: (isDisabled ? colors.ui.disabled : colors.pure.white) }}>
        <Input
          value={value}
          placeholder={placeholder}
          onChangeText={onChangeText}
          keyboardType={keyboardType}
          autoCapitalize={autoCapitalize}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          editable={typeof onChangeText === 'function'}
        />
      </InputContainer>
    </Container>
  );
};
