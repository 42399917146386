import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createImportPlayer, getCompletedImportPlayers, getImportPlayer, getImportPlayers, updateImportPlayer } from '../api/ImportPlayersApi';

export const useGetImportPlayers = ({ clubId, page = 1, perPage = 25 }) => {
  return useQuery({
    queryKey: queryKeys({clubId, page, perPage}),
    queryFn: () => getImportPlayers({ clubId, page, perPage }),
    refetchInterval: 1000 * 60 * 5, // every 5 minutes
    placeholderData: keepPreviousData,
    enabled: !!clubId
  });
};

export const useGetCompleteImportPlayers = ({ clubId, page = 1, perPage = 25 }) => {
  return useQuery({
    queryKey: queryCompleteKey({clubId, page, perPage}),
    queryFn: () => getCompletedImportPlayers({ clubId, page, perPage }),
    refetchInterval: 1000 * 60 * 5, // every 5 minutes
    placeholderData: keepPreviousData,
    enabled: !!clubId
  });
};

export const useGetImportPlayer = ({ clubId, importPlayerId }) => {
  return useQuery({
    queryKey: queryKey({ clubId, importPlayerId }),
    queryFn: getImportPlayer,
    enabled: !!clubId && !!importPlayerId
  });
};

export const useCreateImportPlayer = ({ clubId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createImportPlayer,
    onSuccess: async (data) => {
      if (!clubId) return;
      queryClient.invalidateQueries({ queryKey: ['clubs', clubId, 'importPlayers'] })
    },
  })
};

export const useUpdateImportPlayer = ({ clubId }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateImportPlayer,
    onSuccess: (data) => {
      queryClient.setQueryData(['clubs', clubId, 'importPlayers', data.id], data)
      queryClient.invalidateQueries({ queryKey: ['clubs', clubId, 'importPlayers'] })
    },
  });
};

export const refreshImportPlayers = ({ queryClient, clubId, page = 1, perPage = 25 }) => {
  queryClient.invalidateQueries({
    queryKey: queryKeys({clubId, page, perPage})
  });
};

const queryKeys = ({ clubId, page = 1, perPage = 25 }) => {
  return ['clubs', clubId, 'importPlayers', { page, perPage }];
}

export const refreshImportPlayer = ({ queryClient, clubId, importPlayerId }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey({ clubId, importPlayerId })
  });
};

const queryKey = ({ clubId, importPlayerId }) => {
  return ['clubs', clubId, 'importPlayers', importPlayerId];
}

export const refreshCompleteImportPlayers = ({ queryClient, clubId, page = 1, perPage = 25 }) => {
  queryClient.invalidateQueries({
    queryKey: queryCompleteKey({clubId, page, perPage})
  });
};

const queryCompleteKey = ({ clubId, page = 1, perPage = 25 }) => {
  return ['clubs', clubId, 'importPlayers', { page, perPage, status: 'complete' }];
}