import React from 'react';

import {
  Container,
  ClosedContainer,
  Background,
  Content,
  TitleLine,
  Title,
  Date,
  ClosedDate,
  ClosedTitle,
  Message,
  ClosedMessage,
  Icon,
  DeleteIcon,
  DeleteButton,
  EditIcon,
  EditButton,
} from './styles'

export const CardMessage = ({ title, date, isEditing, isClosed, onDelete, onUpdate, children }) => {
  return (
    <>
      {isClosed ? (
        <ClosedContainer>
          <Background>
            <Content>
              <TitleLine>
                <ClosedTitle>{title}</ClosedTitle>
                <ClosedDate>{date}</ClosedDate>
                <Icon />
              </TitleLine>
              <ClosedMessage>{children}</ClosedMessage>
            </Content>
          </Background>
        </ClosedContainer>
      ) : (
        <Container>
          <Background>
            {isEditing && (
              <DeleteButton onPress={() => {
                if (onDelete) {
                  onDelete();
                }
              }}>
                <DeleteIcon />
              </DeleteButton>
            )}
            <Content>
              <TitleLine>
                <Title>{title}</Title>
                <Date>{date}</Date>
                <Icon />
              </TitleLine>
              <Message>{children}</Message>
            </Content>
            {isEditing && (
              <EditButton onPress={() => {
                if (onUpdate) {
                  onUpdate();
                }
              }}>
                <EditIcon />
              </EditButton>
            )}
          </Background>
        </Container>
      )}
    </>
  );
};
