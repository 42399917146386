import camelize from 'camelize';
import { apiSecureGet, apiSecurePost } from 'api/ApiService';

export const getClubChatMessages = async ({ queryKey, pageParam }) => {
  const clubId = queryKey[1];
  const date = queryKey[3];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/chat_messages?page=${pageParam}&from=${date}`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return camelize(data);
};

export const createClubChatMessage = async ({ clubId, content }) => {
  const response = await apiSecurePost(`/v1/clubs/${clubId}/chat_messages`, {
    chat_message: {
      content
    }
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return camelize(data);
};
