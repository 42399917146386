import styled from 'styled-components/native';

export const CardInformationRow = styled.View`
  flex-direction: row;
`;

export const Subheading = styled.Text`
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  margin-bottom: 10px;
  text-transform: uppercase;
`;
// font-family: "SF Pro Display";

export const FieldItem = styled.View`
  padding-bottom: 16px;
`

export const FieldItemLast = styled.View``