import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/native';

import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { FormTableSelect } from 'components/FormTableSelect';

import { ProfileContext } from 'contexts/ProfileContext';
import { GlobalStateContext } from 'contexts/GlobalStateContext';

const Background = styled.View``;

export const ChangeClubScreen = ({ navigation }) => {
  const [clubPermissions, setClubPermissions] = useState([]);
  const { profile, currentClub, setCurrentClub } = useContext(ProfileContext);
  const { setCurrentClubPermission } = useContext(GlobalStateContext);

  useEffect(() => {
    if (profile?.clubPermissions) {
      setClubPermissions(profile.clubPermissions);
    }
  }, [profile]);

  return (
    <Background>
      <BarHeaderSmall
        title="Change Club role"
        leftButtonTitle="Cancel"
        onLeftPress={() => navigation.goBack()}
      />
      <FormTableSelect
        label={''}
        values={
          clubPermissions.map((clubPermission) => ({
            key: clubPermission.id,
            value: `${clubPermission.roleFormatted} for ${clubPermission.club.abbreviation || clubPermission.club.name}`,
          }))
        }
        initialKeys={[currentClub?.id]}
        multiselect={false}
        onSelected={(selected) => {
          if (selected.length > 0) {
            const clubPermission = clubPermissions.find(
              (clubPermission) => (clubPermission.id === selected[0]),
            );
            setCurrentClub(clubPermission);
            setCurrentClubPermission(clubPermission)
            navigation.goBack();
          }
        }}
      />
    </Background>
  );
};
