import styled from 'styled-components/native';

export const BarButton = styled.Pressable`
  padding-top: 6px;
  padding-left: 8px;
  padding-bottom: 4px;
`;

// import PlusPNG from '../../../../assets/icons/add-full_blue.png';
import PlusPNG from '../../../assets/icons/plus-orange.png';
export const PlusIcon = styled.Image.attrs({
  source: PlusPNG,
})`
  width: 24px;
  height: 24px;
`;

