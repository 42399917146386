import styled from 'styled-components/native';
import { colors } from 'theme/colors';

export const Description = styled.Text`
  color: #728596;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  padding-horizontal: 16px;
  padding-bottom: 32px;
`;
// font-family: "SF Pro Display";

export const OtherContainer = styled.View`
  padding-top: 16px;
  border-top-color: ${(props) => props.theme.colors.border};
  border-top-width: 0.5px;
`;

export const FilterContainer = styled.View`
  padding-vertical: 8px;
  padding-horizontal: 16px;
  background-color: ${colors.pure.white};
  z-index: 10;
`;
