import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Alert } from 'components/Alert';
import { AvoidingView } from 'components/AvoidingView';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { ButtonBlue } from 'components/ButtonBlue';
import { CardInformation } from 'components/CardInformation';
import { CardInformationRow } from './styles';
import { Centered } from 'components/Centered';
import { Confirm } from 'components/Confirm';
import { Footer } from 'components/Footer';
import { FormFieldText } from 'components/FormFieldText';
import { FormTableSelect } from 'components/FormTableSelect';
import { SizeClassView, sizeClasses } from 'components/SizeClassView';
import { Spacer } from 'components/Spacer';
import { Text } from 'components/Text';
import { VerticalScroll } from 'components/VerticalScroll';
import { titleCase } from 'utils/Strings';

import { ProfileContext } from 'contexts/ProfileContext';
import { useQueryClient } from '@tanstack/react-query';
import { refreshMe } from 'hooks/useMe';
import { useGetTeams } from 'hooks/useTeam';
import { useGetTrainer, useDeleteTrainer, useUpdateTrainer } from 'hooks/useTrainer';

export const TrainerUpdateScreen = ({ navigation, route }) => {
  const [recordDeleted, setRecordDeleted] = useState(false);
  const queryClient = useQueryClient()
  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => { return currentClub?.club?.id }, [currentClub]);
  const trainerId = useMemo(() => { return recordDeleted ? null : route?.params?.trainerId }, [route.params, recordDeleted]);
  const leagueId = useMemo(() => {
    if (currentClub) {
      return currentClub?.club.affiliations[0]?.leagueId;
    }
  }, [currentClub]);

  const { data: teams } = useGetTeams({ clubId });
  const { data: trainer } = useGetTrainer({ clubId, trainerId });
  const { mutate: updateTrainer, isPending, isSuccess, isError, error } = useUpdateTrainer({ clubId });
  const { mutate: deleteTrainer, isPending: isDeleting, isSuccess: hasDeleted, isError: isDeletionError, error: deletionError } = useDeleteTrainer({ clubId });

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [trainerStatus, setTrainerStatus] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);

  const [activeDropDown, setActiveDropDown] = useState('');

  const teamItems = useMemo(() => {
    if (!teams) return [];

    var newTeams = teams
    return newTeams.map((team) => ({
      key: team.id,
      value: team.name,
    }))
  }, [teams])

  useEffect(() => {
    if (!trainer) return;
    setEmail(trainer?.invitationEmail);
    setFirstName(trainer?.invitationFirstName);
    setLastName(trainer?.invitationLastName);
    setTrainerStatus([trainer?.status]);
    setSelectedTeams(trainer?.teams?.map((team) => team.id));
  }, [trainer]);

  const canUpdateEmail = useMemo(() => {
    return trainer?.status === 'whitelist'
  }, [trainer]);

  const canUpdateStatus = useMemo(() => {
    return trainer?.status !== 'whitelist'
  }, [trainer]);

  const statusValues = useMemo(() => {
    return [{ key: 'active', value: 'Active' }, { key: 'disabled', value: 'Deactivate' }]
  }, [])

  const canSave = useMemo(() => {
    if (canUpdateEmail) {
      if (email !== '') {
        // let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        let reg = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        if (reg.test(email) === false) { return false }
      } else {
        return canUpdateStatus;
      }
    }
    return true;
  }, [email, canUpdateStatus])

  const saveLabel = useMemo(() => {
    return isPending ? "Saving..." : "Save"
  }, [isPending, canSave])

  const save = () => {
    const club_trainer = canUpdateEmail ? (
      {
        invitation_email: email,
        invitation_first_name: firstName,
        invitation_last_name: lastName,
        status: trainerStatus[0],
        teams: selectedTeams
      }
    ) : (
      {
        status: trainerStatus[0],
        teams: selectedTeams
      }
    )
    updateTrainer({ clubId, trainerId, club_trainer });
  };

  const deleteRecord = () => {
    if (trainer?.status == 'whitelist') {
      Confirm('Confirm Delete', `Delete ${email}`, () => {
        deleteTrainer({ clubId, trainerId });
        setRecordDeleted(true);
      }, () => { });
    }
  };

  useEffect(() => {
    if (isError) Alert('Something went wrong', error?.toString());
  }, [isError]);

  useEffect(() => {
    if (isSuccess || hasDeleted) {
      refreshMe({ queryClient });
      navigation.navigate('TrainersScreen');
    }
  }, [isSuccess, hasDeleted]);

  return (
    <>
      <BarHeaderSmall
        title={'Update trainer'}
        leftButtonTitle="Cancel"
        onLeftPress={() => {
          navigation.navigate('TrainersScreen');
        }}
        rightButtonTitle={trainer?.status == 'whitelist' ? 'Delete' : ''}
        onRightPress={deleteRecord}
      />
      <AvoidingView>
        <VerticalScroll>
          <Centered>
            {canUpdateEmail ? (
              <>
                <FormFieldText
                  label="Email"
                  value={email}
                  onChangeText={setEmail}
                  keyboardType="email-address"
                  autoCapitalize={false}
                  autoFocus={true}
                />
                <FormFieldText
                  label="First Name"
                  value={firstName}
                  onChangeText={setFirstName}
                />
                <FormFieldText
                  label="Last Name"
                  value={lastName}
                  onChangeText={setLastName}
                />
              </>
            ) : (
              <>
                <CardInformationRow>
                  <CardInformation title='First Name'>
                    {trainer?.user?.profile?.firstName}
                  </CardInformation>
                  <CardInformation title='Last Name'>
                    {trainer?.user?.profile?.lastName}
                  </CardInformation>
                </CardInformationRow>
                <CardInformationRow>
                  <CardInformation title='Email'>
                    {trainer?.user?.email}
                  </CardInformation>
                </CardInformationRow>
              </>
            )}
            <CardInformationRow>
              <CardInformation title='Added to whitelist'>
                {trainer?.createdAtShort}
              </CardInformation>
              {canUpdateStatus && (
                <CardInformation title='User Activated'>
                  {trainer?.user?.createdAtShort}
                </CardInformation>
              )}
              {!canUpdateStatus && (
                <CardInformation title='Status'>
                  {titleCase(trainer?.status)}
                </CardInformation>
              )}
            </CardInformationRow>
            {canUpdateStatus && (
              <FormTableSelect
                label={'Status'}
                values={statusValues}
                initialKeys={trainerStatus}
                multiselect={false}
                onSelected={setTrainerStatus}
              />
            )}
            {teamItems.length == 0 ? (
              <>
                <Spacer type="bottom" size="large" />
                <Text style={{ textAlign: 'center' }}>No teams yet</Text>
                <Spacer type="bottom" size="large" />
              </>
            ) : (
              <FormTableSelect
                label={'Assign teams'}
                values={teamItems}
                initialKeys={selectedTeams}
                multiselect={true}
                onSelected={setSelectedTeams}
              />
            )}
            <Spacer type="bottom" size="large" />
          </Centered>
        </VerticalScroll>
        <Footer>
          <Centered>
            <ButtonBlue
              label={saveLabel}
              isDisabled={!canSave || isPending}
              onPress={save}
            />
          </Centered>
          <SizeClassView size={sizeClasses.compact}>
            <Spacer type="bottom" size="large" />
          </SizeClassView>
        </Footer>
      </AvoidingView>
    </>
  );
};
