import camelize from 'camelize';
import {
  parseDate,
  parseDateTime,
  formattedDate,
  formattedShortDate,
} from 'utils/Dates';
import { apiSecureGet, apiSecurePost, apiSecurePut } from 'api/ApiService';

export const getPlayers = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/players`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return playersTransform(data);
};

export const getPlayer = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const playerId = queryKey[3];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/players/${playerId}`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return playerTransform(data);
};

export const createPlayer = async ({ clubId, player }) => {
  const response = await apiSecurePost(`/v1/clubs/${clubId}/players`, {
    player
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const responseData = await response.json();
  return playerTransform(responseData);
};

export const updatePlayer = async ({ clubId, playerId, player }) => {
  const response = await apiSecurePut(`/v1/clubs/${clubId}/players/${playerId}`, {
    player
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return playerTransform(data);
};

const transform = (result = {}) => {
  result.createdAtShort = formattedShortDate(parseDateTime(result.created_at));
  result.updatedAtShort = formattedShortDate(parseDateTime(result.updated_at));
  return camelize(result);
};

export const playerTransform = (result = {}) => {
  return transform(result.data);
};

export const playersTransform = (results = {}) => {
  return results.data.map((result) => transform(result));
};
