import React from 'react';
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';

import { DocumentsScreen } from 'screens/DocumentsScreen';
import { DocumentDetailsScreen } from 'screens/DocumentDetailsScreen';

const DocumentsStack = createStackNavigator();

const createScreenOptions = () => {
  return {
    headerShown: false,
    ...TransitionPresets.DefaultTransition,
  };
};

export const DocumentsNavigator = () => {
  return (
    <DocumentsStack.Navigator screenOptions={createScreenOptions}>
      <DocumentsStack.Screen
        name="DocumentsScreen"
        component={DocumentsScreen}
        options={{ title: 'Documents - Headfirst' }}
      />
      <DocumentsStack.Screen
        name="DocumentDetailsScreen"
        component={DocumentDetailsScreen}
        options={{ title: 'Document - Headfirst' }}
      />
    </DocumentsStack.Navigator>
  );
};
