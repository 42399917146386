import styled from 'styled-components/native';
import { SafeAreaView } from 'react-native-safe-area-context';

export const BlackBackground = styled.View`
  flex: 1;
  background-color: ${(props) => props.theme.colors.pure.black};
`;

export const SafeArea = styled(SafeAreaView).attrs({
  edges: ['top', 'right', 'left'],
})`
  flex: 1;
`;

export const BlueBackground = styled.View`
  flex: 1;
  background-color: ${(props) => props.theme.colors.twoWeeks.green};
`;

export const Content = styled.View`
  flex: 1;
  align-items: center;
`;

export const Header = styled.View`
  align-items: center;
  width: 100%;
  padding: ${(props) => props.theme.space[5]};
`;

export const IconImage = styled.Image`
  width: 216px;
  height: 53px;
`;
// 271/66

export const Footer = styled.View`
  position: absolute;
  bottom: ${(props) => props.theme.space[5]};
  align-items: center;
  width: 100%;
`;