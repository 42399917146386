import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createReport,
  getJuniorClubRoundPdf,
  getJuniorClubSeasonPdf,
  getReport,
  getReportPdf,
  getReports,
  getReportsCSV,
  getSeniorClubRoundPdf,
  getSeniorClubSeasonPdf,
  updateReport
} from 'api/ReportsApi';

export const useGetReports = ({ clubId }) => {
  return useQuery({
    queryKey: queryKey({ clubId }),
    queryFn: getReports,
    refetchInterval: 1000 * 60, // every minute
    enabled: !!clubId
  });
};

export const useGetReportsCSV = ({ clubId }) => {
  return useQuery({
    querysKey: queryCSVKey({ clubId }),
    queryFn: getReportsCSV,
    refetchOnWindowFocus: false,
    enabled: false
  });
};

export const useGetReport = ({ clubId, reportId }) => {
  return useQuery({
    queryKey: queryKey({ clubId, reportId: reportId ?? 'new' }),
    queryFn: getReport,
    enabled: !!reportId
  });
};

export const useGetReportPdf = ({ clubId, reportId }) => {
  return useQuery({
    queryKey: queryPDFKey({ clubId, reportId }),
    queryFn: getReportPdf,
    refetchOnWindowFocus: false,
    enabled: false
  });
};

export const useGetJuniorClubRoundPdf = ({ clubId, roundId }) => {
  return useQuery({
    queryKey: queryJuniorClubRoundPDFKey({ clubId, roundId }),
    queryFn: getJuniorClubRoundPdf,
    refetchOnWindowFocus: false,
    enabled: false
  });
};

export const useGetJuniorClubSeasonPdf = ({ clubId, fromRoundId, toRoundId }) => {
  return useQuery({
    queryKey: queryJuniorClubSeasonPDFKey({ clubId, fromRoundId, toRoundId }),
    queryFn: getJuniorClubSeasonPdf,
    refetchOnWindowFocus: false,
    enabled: false
  });
};

export const useGetSeniorClubRoundPdf = ({ clubId, roundId }) => {
  return useQuery({
    queryKey: querySeniorClubRoundPDFKey({ clubId, roundId }),
    queryFn: getSeniorClubRoundPdf,
    refetchOnWindowFocus: false,
    enabled: false
  });
};

export const useGetSeniorClubSeasonPdf = ({ clubId, fromRoundId, toRoundId }) => {
  return useQuery({
    queryKey: querySeniorClubSeasonPDFKey({ clubId, fromRoundId, toRoundId }),
    queryFn: getSeniorClubSeasonPdf,
    refetchOnWindowFocus: false,
    enabled: false
  });
};

export const useCreateReport = ({ clubId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createReport,
    onSuccess: async (data) => {
      var reportId = data.id;
      queryClient.setQueryData(queryKey({ clubId, reportId }), data)
      refreshReports({ queryClient, clubId })
    }
  });
};

export const useUpdateReport = ({ clubId, reportId }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateReport,
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey({ clubId, reportId }), data)
      refreshReports({ queryClient, clubId });
    },
  });
};

export const refreshReports = ({ queryClient, clubId }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey({ clubId })
  });
};

export const refreshReport = ({ queryClient, clubId, reportId }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey({ clubId, reportId })
  });
};

const queryKey = ({ clubId, reportId }) => {
  if (reportId) {
    return ['clubs', clubId, 'reports', reportId];
  } else {
    return ['clubs', clubId, 'reports'];
  }
}

const queryCSVKey = ({ clubId }) => {
  return ['clubs', clubId, { format: 'csv' }];
}

const queryPDFKey = ({ clubId, reportId }) => {
  return ['clubs', clubId, 'reports', reportId, { format: 'pdf' }];
}

const queryJuniorClubRoundPDFKey = ({ clubId, roundId }) => {
  return ['clubs', clubId, 'rounds', roundId, { summary_type: 'junior', format: 'pdf' }];
}

const queryJuniorClubSeasonPDFKey = ({ clubId, fromRoundId, toRoundId }) => {
  return ['clubs', clubId, 'roundRange', fromRoundId, toRoundId, { summary_type: 'junior', format: 'pdf' }];
}

const querySeniorClubRoundPDFKey = ({ clubId, roundId }) => {
  return ['clubs', clubId, 'rounds', roundId, { summary_type: 'senior', format: 'pdf' }];
}

const querySeniorClubSeasonPDFKey = ({ clubId, fromRoundId, toRoundId }) => {
  return ['clubs', clubId, 'roundRange', fromRoundId, toRoundId, { summary_type: 'senior', format: 'pdf' }];
}
