import React, { useContext, useEffect, useMemo } from 'react';
import { Alert } from 'react-native';

import { CardMessage } from 'components/CardMessage';
import { AddButton, RefreshControl, ReportsList } from './styles.compact';

import { useQueryClient } from '@tanstack/react-query';
import { useGetReports, refreshReports } from 'hooks/useReport';
import { ProfileContext } from 'contexts/ProfileContext';

export const ReportListCompact = ({ navigation }) => {
  const queryClient = useQueryClient()
  const { profile, currentClub, onMe } = useContext(ProfileContext);
  const clubId = useMemo(() => currentClub?.clubId, [currentClub]);

  const { data: reports, isLoading, isError, error } = useGetReports({ clubId });

  const filteredReports = useMemo(() => {
    if (!reports) return [];

    var results = reports;
    if (currentClub?.role === 'trainer') {
      var teamIds = currentClub.teams.map(team => team.id);
      results = results.filter(result => teamIds.includes(result.teamDetails?.id) || result?.userId == profile?.id )
    }
    return results;
  }, [currentClub, reports]);

  useEffect(() => {
    if (isError) {
      Alert.alert('Something went wrong', error?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [isError]);

  return (
    <>
      <ReportsList
        data={filteredReports}
        renderItem={({ item }) => (
          <AddButton
            onPress={() => {
              navigation.navigate('ReportDetailsScreen', {
                reportId: item.id
              });
            }}
          >
            <CardMessage
              title={item?.fieldData?.player}
              date={item?.fieldData?.incidentOnShort}
              isClosed={item?.status === 'closed_cleared_to_play'}
            >
              {item?.statusDescription}
            </CardMessage>
          </AddButton>
        )}
        keyExtractor={(item) => item.id}
        refreshControl={
          <RefreshControl
            refreshing={isLoading}
            onRefresh={() => {
              onMe();
              refreshReports({ queryClient, clubId });
            }}
          />
        }
      />
    </>
  );
};
