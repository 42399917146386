import styled from 'styled-components/native';
import { DataTable } from 'react-native-paper';

export const Container = styled.View`
  background-color: white;
  flex: 1;
`;

export const RequiredText = styled.Text`
  color: red;
`;

export const DataTableView = styled(DataTable)`
  background-color: white;
  flex: 1;
`;

export const DataTableTitleCenter = styled(DataTable.Title).attrs({
  justifyContent: 'center',
})``;

export const DataTableCellCenter = styled(DataTable.Cell).attrs({
  justifyContent: 'center',
})``;

export const DataTableCellTitleCase = styled(DataTable.Cell)`
  text-transform: capitalize;
`;

export const CardInformationRow = styled.View`
  flex-direction: row;
`;

export const CellText = styled.Text`
  font-size: 16,
  font-weight: 500,
`;

export const DataList = styled.FlatList.attrs({
  contentContainerStyle: {
    paddingTop: 0,
  },
})`
  margin: 0;
  background-color: white;
`;

