import styled from 'styled-components/native';

export const ErrorContainer = styled.View`
  align-items: center;
  align-self: center;
  background: ${(props) => props.theme.colors.pure.white};
  border-color: ${(props) => props.theme.colors.error.red};
  border-width: 1px;
`;

export const ErrorText = styled.Text`
  color: ${(props) => props.theme.colors.error.red};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  padding: 16px;
`;
// font-family: "SF Pro Display";
