import { useQuery } from '@tanstack/react-query';
import { getSeasons } from 'api/SeasonsApi';

export const useGetSeasons = ({ leagueId }) => {
  return useQuery({
    queryKey: queryKey({ leagueId }),
    queryFn: getSeasons,
    refetchInterval: 1000 * 60 * 4, // every 4 hours
    enabled: !!leagueId
  });
};

export const refreshSeasons = ({ queryClient, leagueId }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey({ leagueId })
  });
};

const queryKey = ({ leagueId }) => {
  return ['leagues', leagueId, 'seasons'];
}