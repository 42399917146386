import React, { createContext, useEffect, useState } from 'react';

export const GlobalStateContext = createContext();

export const GlobalStateContextProvider = ({ children }) => {
  const [currentClubPermission, setCurrentClubPermission] = useState(null);
  const [currentRound, setCurrentRound] = useState(null);
  const [currentTeam, setCurrentTeam] = useState(null);
  const [currentFeatures, setCurrentFeatures] = useState(null);

  return (
    <GlobalStateContext.Provider value={{
      currentClubPermission, setCurrentClubPermission,
      currentRound, setCurrentRound,
      currentTeam, setCurrentTeam,
      currentFeatures, setCurrentFeatures,
    }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
