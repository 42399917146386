import styled from 'styled-components/native';
import { Pressable } from 'react-native';

// import BackIcon from "../../../assets/back.svg";
// export const BackIcon = styled.View``;

export const Background = styled.View`
  background-color: ${(props) => props.theme.colors.pure.white};
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
  padding-vertical: ${(props) => props.theme.space[1]};
  padding-horizontal: ${(props) => props.theme.space[3]};
`;

export const ContainerButtons = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-content: baseline;
`;

export const Container = styled.View`
  align-items: center;
  justify-content: center;
  padding-vertical: 8px;
`;

export const Title = styled.Text.attrs({
  numberOfLines: 1,
  ellipsizeMode: 'tail',
  adjustsFontSizeToFit: true,
})`
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
`;
// font-family: "SF Pro Display";

export const LeftContainer = styled.View`
  // position: absolute;
  // left: 16px;
  // top: 4px;
`;

export const LeftTitle = styled.Text.attrs({
  numberOfLines: 1,
  adjustsFontSizeToFit: true,
})`
  color: ${(props) => props.theme.colors.twoWeeks.green};
  font-size: 15px;
  letter-spacing: 0;
`;
// font-family: "SF Pro Display";

export const LeftButton = styled(Pressable)`
`;

export const RightContainer = styled.View`
`;

export const RightButton = styled(Pressable)`
  align-items: flex-end;
  text-align: center;
`;

export const RightTitle = styled.Text.attrs({
  numberOfLines: 1,
  adjustsFontSizeToFit: true,
})`
  color: ${(props) => props.theme.colors.twoWeeks.green};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
`;
// font-family: "SF Pro Display";