import React from 'react';

import {
  Container,
  Scroll,
} from './styles'

export const VerticalScroll = ({ children }) => {
  return (
    <Container>
      <Scroll>{children}</Scroll>
    </Container>
  );
};
