import styled from 'styled-components/native';

export const Background = styled.View`
  background-color: ${(props) => props.theme.colors.pure.white};
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
  padding-top: ${(props) => props.theme.space[5]};
  padding-bottom: ${(props) => props.theme.space[2]};
  padding-horizontal: ${(props) => props.theme.space[3]};
`;

export const Date = styled.Text`
  color: #000000;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  text-transform: uppercase;
`;

export const TitleRow = styled.View`
  flex-direction: row;
  align-items: center;
  padding-top: ${(props) => props.theme.space[1]};
`;

// font-family: "SF Pro Display";
export const Title = styled.Text`
  flex: 1;
  color: #000000;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
`;
// font-family: "SF Pro Display";