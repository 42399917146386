import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components/native';

import { Alert } from 'components/Alert';
import { CardMessage } from 'components/CardMessage';
import { Loading } from 'components/Loading';

import { ProfileContext } from 'contexts/ProfileContext';
import { useQueryClient } from '@tanstack/react-query';
import { refreshDocuments, useGetDocuments } from 'hooks/useDocument';

import { openLink } from 'utils/links';

const DocumentsList = styled.FlatList``;
const PressRow = styled.Pressable``;
const RefreshControl = styled.RefreshControl``;

export const DocumentListCompact = ({ navigation }) => {
  const queryClient = useQueryClient();
  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => currentClub?.clubId, [currentClub]);

  const { data: documents, isLoading, isError, error } = useGetDocuments({ clubId })

  const canAddDocument = useMemo(() => {
    return currentClub?.role === 'club_admin';
  }, [currentClub]);

  useEffect(() => {
    if (error) {
      Alert('Something went wrong', error?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [error]);

  return (
    <>
      <DocumentsList
        data={documents}
        renderItem={({ item }) => (
          <PressRow
            onPress={() => {
              if (canAddDocument) {
                navigation.navigate('DocumentDetailsScreen', { document: item });
              } else {
                openLink(item?.file?.url);
              }
            }}
          >
            <CardMessage title={item?.title} date={item?.updatedAtShort}>
              {item?.description}
            </CardMessage>
          </PressRow>
        )}
        keyExtractor={(item) => item.id}
        refreshControl={
          <RefreshControl
            refreshing={isLoading}
            onRefresh={() => {
              if (clubId) {
                refreshDocuments({ queryClient, clubId });
              }
            }}
          />
        }
      />
      <Loading isLoading={isLoading} />
    </>
  );
};
