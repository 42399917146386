import React, { useContext, useMemo } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { theme } from '../theme';
import styled from 'styled-components/native';

// import { OverviewNavigator } from "./overview.navigator";
import { ReportsNavigator } from './ReportsNavigator';
import { DocumentsNavigator } from './DocumentsNavigator';
import { SettingsNavigator } from './SettingsNavigator';
import { ClubChatNavigator } from './ClubChatNavigator';
import { PlayersNavigator } from './PlayersNavigator';

import { ProfileContext } from 'contexts/ProfileContext';

const Tab = createBottomTabNavigator();

import HomePNG from '../../assets/icons/home-full_blue.png';
const Home = styled.Image.attrs({
  source: HomePNG,
})``;
import ListPNG from '../../assets/icons/list_blue.png';
const List = styled.Image.attrs({
  source: ListPNG,
})``;
// import RequestsSVG from "../../../assets/icons/requests.svg";
const RequestsSVG = styled.View``;
// import ClubsSVG from "../../../assets/icons/requests.svg";
const ClubsSVG = styled.View``;
import DocumentPNG from '../../assets/document.png';
const Document = styled.Image.attrs({
  source: DocumentPNG,
})``;
// import UserSVG from "../../../assets/icons/user-full.svg";
import SettingsPNG from '../../assets/icons/settings.png';
const Settings = styled.Image.attrs({
  source: SettingsPNG,
})``;
import WarningPNG from '../../assets/icons/warning-full_blue.png';
const Warning = styled.Image.attrs({
  source: WarningPNG,
})``;
import StarPNG from '../../assets/icons/star-full_blue.png';
const Star = styled.Image.attrs({
  source: StarPNG,
})``;
import ChatPNG from '../../assets/icons/message-full_green-active.png';
const Chat = styled.Image.attrs({
  source: ChatPNG,
})``;

const createScreenOptions = ({ route }) => {
  return {
    tabBarIcon: ({ size, color }) => {
      switch (route.name) {
        case 'Overview':
          return (
            <Home
              style={{
                height: size * 0.9,
                width: size * 0.9,
                tintColor: color,
              }}
            />
          );
        case 'Reports':
          return (
            <Warning
              style={{
                height: size * 0.9,
                width: size * 0.9,
                tintColor: color,
              }}
            />
          );
        case 'Clubs':
          return (
            <List
              style={{
                height: size * 0.9,
                width: size * 0.9,
                tintColor: color,
              }}
            />
          );
        case 'Documents':
          return (
            <Document
              style={{
                height: size * 0.9,
                width: size * 0.9,
                tintColor: color,
              }}
            />
          );
        case 'Players':
          return (
            <Star
              style={{
                height: size * 0.9,
                width: size * 0.9,
                tintColor: color,
              }}
            />
          );
        case 'Settings':
          return (
            <Settings
              style={{
                height: size * 0.9,
                width: size * 0.9,
                tintColor: color,
              }}
            />
          );
        case 'Chat':
          return (
            <Chat
              style={{
                height: size * 0.9,
                width: size * 0.9,
                tintColor: color,
              }}
            />
          );
      }
    },
    tabBarActiveTintColor: theme.colors.twoWeeks.green,
    tabBarInactiveTintColor: theme.colors.grey,
    headerShown: false,
  };
};

export const AppTabNavigator = () => {
  const { currentFeatures } = useContext(ProfileContext);

  const clubChatEnabled = useMemo(() => {
    return currentFeatures?.includes('club_chat') || false
  }, [currentFeatures]);

  return (
    <Tab.Navigator screenOptions={createScreenOptions}>
      {/* <Tab.Screen name="Overview" component={OverviewNavigator} /> */}
      <Tab.Screen name="Reports" component={ReportsNavigator} />
      <Tab.Screen name="Documents" component={DocumentsNavigator} />
      <Tab.Screen name="Players" component={PlayersNavigator} />
      {clubChatEnabled && (
        <Tab.Screen name="Chat" component={ClubChatNavigator} />
        // <Tab.Screen name="Chat" component={ClubChatNavigator} options={{ tabBarBadge: 3 }} />
      )}
      <Tab.Screen name="Settings" component={SettingsNavigator} />
    </Tab.Navigator>
  );
};
