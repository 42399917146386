import camelize from 'camelize';
import { parseDateTime, formattedShortDate } from 'utils/Dates';
import { apiSecureGet } from 'api/ApiService';

export const getMe = async () => {
  return apiSecureGet('/v1/me')
    .then((response) => response.json())
};

export const getMe2 = async ({ queryKey }) => {
  const response = await apiSecureGet(`/v1/me`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return profileTransform(data);
};

const transform = (result = {}) => {
  if (result?.created_at) {
    result.createdAtShort = formattedShortDate(parseDateTime(result.created_at));
  }
  if (result?.updated_at) {
    result.createdAtShort = formattedShortDate(parseDateTime(result.updated_at));
  }
  if (result?.club_permissions) {
    result.club_permissions = result.club_permissions.map((clubPermission) => transformClubPermission(clubPermission));
  }
  return camelize(result);
};

const transformClubPermission = (result = {}) => {
  if (result.role === 'club_admin') {
    result.role_formatted = 'Club Admin'
  }
  if (result.role === 'trainer') {
    result.role_formatted = 'Trainer'
  }
  result.created_at_short = formattedShortDate(parseDateTime(result.created_at));
  result.updated_at_short = formattedShortDate(parseDateTime(result.updated_at));
  return result;
};

export const profileTransform = (result = {}) => {
  return transform(result.data);
};

export const profilesTransform = (results = {}) => {
  return results.data.map((result) => transform(result));
};
