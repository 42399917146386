import styled from 'styled-components/native';
import DropDownPicker from 'react-native-dropdown-picker';
import { Picker } from '@react-native-picker/picker';

export const Container = styled.View``;
export const DropDown = styled(DropDownPicker)``;
export const WebDropDown = styled(Picker)`
  padding: 5px;
  border-radius: 8px;
`;
export const WebDropDownItem = styled(Picker.Item)``;