import { apiSecureGet } from 'api/ApiService';
import camelize from 'camelize';

export const getImportPlayerRows = async ({ queryKey, pageParam }) => {
  const clubId = queryKey[1];
  const importPlayerId = queryKey[3];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/players/import/${importPlayerId}/import_rows?page=${pageParam}`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return camelize(data);
};
