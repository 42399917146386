import React, { useLayoutEffect, useState } from 'react';

import {
  CheckIcon,
  Container,
  Icon,
  LabelContainer,
  LabelText,
  PressableRow,
  RowContainer,
  RowIconContainer,
  RowLabelText,
  TableRowsList,
} from './styles'

const TableRow = ({ isSelected, onPress, children }) => {
  return (
    <PressableRow onPress={onPress}>
      <RowContainer>
        <RowIconContainer>{isSelected && <Icon />}</RowIconContainer>
        <RowLabelText>{children}</RowLabelText>
      </RowContainer>
    </PressableRow>
  );
};

export const FormTableSelect = ({
  label,
  values,
  initialKeys,
  multiselect,
  onSelected,
  showSelection = true
}) => {
  const [selectedKeys, setSelectedKeys] = useState([]);

  useLayoutEffect(() => {
    setSelectedKeys(initialKeys);
  }, [initialKeys]);

  const toggleItem = (key) => {
    var keys = [];
    if (multiselect) {
      const index = selectedKeys.indexOf(key);
      if (index > -1) {
        keys = selectedKeys.filter((i) => i !== key);
      } else {
        keys = [...selectedKeys, key];
      }
    } else {
      keys = [key];
    }
    onSelected(keys);
    setSelectedKeys(keys);
  };

  return (
    <Container>
      <LabelContainer>
        {!!label && (
          <LabelText>{label}</LabelText>
        )}
      </LabelContainer>
      <TableRowsList>
        {values.map((item) => (
          <TableRow
            isSelected={showSelection && selectedKeys?.includes(item.key)}
            onPress={() => toggleItem(item.key)}
            key={item.key}
          >
            {item.value}
          </TableRow>
        ))}
      </TableRowsList>
    </Container>
  );
};
