import styled from 'styled-components/native';

export const Background = styled.View`
  width: 100%;
  padding: 0px;
  background-color: ${(props) => props.theme.colors.pure.white};
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
`;

export const Switcher = styled.View`
  width: 100%;
  padding: 4px;
  flex-direction: row-reverse;
  vertical-align: middle;
`;
