import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ScrollView, View } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';

import { CardInformationRow, FieldItem, FieldItemLast, Subheading } from './styles';
import { ButtonBlue } from 'components/ButtonBlue';
import { Centered } from 'components/Centered';
import { CardInformation } from 'components/CardInformation';
import { DropDownList } from 'components/DropDownList';
import { FormFieldTextDate } from 'components/FormFieldTextDate';
import { Loading } from 'components/Loading';
import { colors } from 'theme/colors';
import { sizeClasses, SizeClassView } from 'components/SizeClassView';
import { formattedShortDate, parseDate } from 'utils/Dates';
import { titleCase } from 'utils/Strings';

import { ProfileContext } from 'contexts/ProfileContext';
import { useGetReport, useUpdateReport } from 'hooks/useReport';

export const ReportReturnToPlay = ({ clubId, reportId, navigation }) => {
  const { currentClub, currentFeatures } = useContext(ProfileContext);

  const { data: report, isLoading } = useGetReport({ clubId, reportId });
  const { mutate: updateReport, isPending: isUpdating } = useUpdateReport({ clubId, reportId });

  const [activeDropDown, setActiveDropDown] = useState('');

  const [returnToNonContact, setReturnToNonContact] = useState('');
  const [returnToContact, setReturnToContact] = useState('');
  const [doctorCertificateOpen, setDoctorCertificateOpen] = useState(false);
  const [doctorCertificateValue, setDoctorCertificateValue] = useState('');
  const [doctorCertificateItems, setDoctorCertificateItems] = useState([
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ]);

  const [reportStatusOpen, setReportStatusOpen] = useState(false);
  const [reportStatusValue, setReportStatusValue] = useState('');
  const [reportStatusItems, setReportStatusItems] = useState([
    { label: 'Under assessment', value: 'under_assessment' },
    { label: 'Not cleared to play', value: 'not_cleared_to_play' },
    { label: 'Closed cleared to play', value: 'closed_cleared_to_play' },
  ]);

  const [concussionConfirmedOpen, setConcussionConfirmedOpen] = useState(false);
  const [concussionConfirmedValue, setConcussionConfirmedValue] = useState('');
  const [concussionConfirmedItems, setConcussionConfirmedItems] = useState([
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ]);

  const currentRole = useMemo(() => {
    return currentClub?.role;
  }, [currentClub]);

  const canEditMeta = useMemo(() => {
    return (currentRole === 'club_admin') || (currentRole === 'trainer' && currentFeatures?.includes('trainer_admin'))
  }, [currentRole, currentFeatures]);

  const shouldUpdate = useMemo(() => {
    var shouldUpdate = false;
    if (reportStatusValue !== '' && reportStatusValue !== report?.status) { shouldUpdate = true }
    if (doctorCertificateValue !== '' && doctorCertificateValue !== report?.metaData?.doctorCertificate) { shouldUpdate = true }
    if (concussionConfirmedValue !== '' && concussionConfirmedValue !== report?.metaData?.concussionConfirmed) { shouldUpdate = true }
    if (returnToNonContact !== report?.metaData?.returnToNonContact) { shouldUpdate = true }
    if (returnToContact !== report?.metaData?.returnToContact) { shouldUpdate = true }
    return shouldUpdate;
  }, [report, reportStatusValue, doctorCertificateValue, concussionConfirmedValue, returnToNonContact, returnToContact]);

  const updateMetaData = ({ clubId, reportId, doctorCertificate, concussionConfirmed, returnToNonContact, returnToContact }) => {
    updateReport({
      clubId,
      reportId,
      report: {
        status: reportStatusValue,
        meta_data: {
          doctor_certificate: doctorCertificate,
          concussion_confirmed: concussionConfirmed,
          return_to_non_contact: returnToNonContact,
          return_to_contact: returnToContact,
        }
      }
    });
  }

  useEffect(() => {
    if (!!report) {
      setReportStatusValue(report?.status);
      setConcussionConfirmedValue(report?.metaData?.concussionConfirmed);
      setDoctorCertificateValue(report?.metaData?.doctorCertificate);
      setReturnToNonContact(report?.metaData?.returnToNonContact ?? report?.fieldData?.incidentOn);
      setReturnToContact(report?.metaData?.returnToContact ?? report?.fieldData?.incidentOn);
      if (!!currentRole && currentRole === 'club_admin' && report?.status == 'pending_assessment') {
        // setReportStatusValue('under_assessment');
        updateReport({
          clubId,
          reportId,
          report: { status: 'under_assessment' }
        })
      }
    }
  }, [report])

  const saveLabel = useMemo(() => {
    return isUpdating ? "Saving..." : "Save"
  }, [isUpdating, shouldUpdate])

  return (
    <>
      <ScrollView>
        <Centered>
          {canEditMeta ? (
            <>
              <SizeClassView size={sizeClasses.regular}>
                <CardInformationRow style={{ zIndex: 30 }}>
                  <CardInformation title="Status">
                    <DropDownPicker
                      placeholder=""
                      containerStyle={{
                        marginTop: 8
                      }}
                      style={{
                        borderWidth: 0.5,
                        minHeight: 36,
                        backgroundColor: (isUpdating || isLoading ? colors.ui.disabled : colors.pure.white),
                      }}
                      dropDownContainerStyle={{
                        borderWidth: 0.5,
                      }}
                      disabled={isUpdating || isLoading}
                      open={reportStatusOpen}
                      value={reportStatusValue}
                      items={reportStatusItems}
                      setOpen={setReportStatusOpen}
                      setValue={setReportStatusValue}
                      setItems={setReportStatusItems}
                    />
                  </CardInformation>
                </CardInformationRow>
                <CardInformationRow style={{ zIndex: 20 }}>
                  <CardInformation title="Concussion confirmed by Healthcare Professional (HCP)">
                    <DropDownPicker
                      placeholder=""
                      containerStyle={{
                        marginTop: 8,
                      }}
                      style={{
                        borderWidth: 0.5,
                        minHeight: 36,
                        backgroundColor: (isUpdating || isLoading ? colors.ui.disabled : colors.pure.white),
                      }}
                      dropDownContainerStyle={{
                        borderWidth: 0.5,
                      }}
                      disabled={isUpdating || isLoading}
                      open={concussionConfirmedOpen}
                      value={concussionConfirmedValue}
                      items={concussionConfirmedItems}
                      setOpen={setConcussionConfirmedOpen}
                      setValue={setConcussionConfirmedValue}
                      setItems={setConcussionConfirmedItems}
                    />
                  </CardInformation>
                </CardInformationRow>
                <CardInformationRow style={{ zIndex: 10 }}>
                  <CardInformation title="Certificate from Healthcare Professional (HCP) Provided">
                    <DropDownPicker
                      placeholder=""
                      containerStyle={{
                        marginTop: 8
                      }}
                      style={{
                        borderWidth: 0.5,
                        minHeight: 36,
                        backgroundColor: (isUpdating || isLoading ? colors.ui.disabled : colors.pure.white),
                      }}
                      dropDownContainerStyle={{
                        borderWidth: 0.5,
                      }}
                      disabled={isUpdating || isLoading}
                      open={doctorCertificateOpen}
                      value={doctorCertificateValue}
                      items={doctorCertificateItems}
                      setOpen={setDoctorCertificateOpen}
                      setValue={setDoctorCertificateValue}
                      setItems={setDoctorCertificateItems}
                    />
                  </CardInformation>
                </CardInformationRow>
                <CardInformationRow>
                  <CardInformation title="Return to non-contact activity">
                    <FormFieldTextDate
                      required={false}
                      value={returnToNonContact}
                      isDisabled={isUpdating || isLoading}
                      onBlur={(value) => setReturnToNonContact(value)}
                    />
                  </CardInformation>
                  <CardInformation title="Unrestricted return to play">
                    <FormFieldTextDate
                      required={false}
                      value={returnToContact}
                      isDisabled={isUpdating || isLoading}
                      onBlur={(value) => setReturnToContact(value)}
                    />
                  </CardInformation>
                </CardInformationRow>
              </SizeClassView>

              <SizeClassView size={sizeClasses.compact}>
                <View style={{ paddingHorizontal: 16, zIndex: 30 }}>
                  <Subheading>Status</Subheading>
                  <FieldItem style={{ zIndex: 30 }}>
                    <DropDownList
                      placeholder=""
                      items={[
                        { name: 'Under assessment', id: 'under_assessment' },
                        { name: 'Not cleared to play', id: 'not_cleared_to_play' },
                        { name: 'Closed cleared to play', id: 'closed_cleared_to_play' },
                      ]}
                      isActive={activeDropDown == 'reportStatus'}
                      onOpen={() => {
                        setActiveDropDown('reportStatus');
                      }}
                      value={reportStatusValue}
                      // setValue={setStatusDropDownValue}
                      onChangeValue={setReportStatusValue}
                      containerStyle={{
                        marginHorizontal: 4
                      }}
                    />
                  </FieldItem>
                  <Subheading>Concussion confirmed by Healthcare Professional (HCP)</Subheading>
                  <FieldItem style={{ zIndex: 20 }}>
                    <DropDownList
                      placeholder=""
                      items={[
                        { name: 'Yes', id: 'yes' },
                        { name: 'No', id: 'no' },
                      ]}
                      isActive={activeDropDown == 'concussionConfirmed'}
                      onOpen={() => {
                        setActiveDropDown('concussionConfirmed');
                      }}
                      value={concussionConfirmedValue}
                      // setValue={setStatusDropDownValue}
                      onChangeValue={setConcussionConfirmedValue}
                      containerStyle={{
                        marginHorizontal: 4
                      }}
                    />
                  </FieldItem>
                  <Subheading>Certificate from Healthcare Professional (HCP) Provided</Subheading>
                  <FieldItemLast style={{ zIndex: 10 }}>
                    <DropDownList
                      placeholder=""
                      items={[
                        { name: 'Yes', id: 'yes' },
                        { name: 'No', id: 'no' },
                      ]}
                      isActive={activeDropDown == 'doctorCertificate'}
                      onOpen={() => {
                        setActiveDropDown('doctorCertificate');
                      }}
                      value={doctorCertificateValue}
                      // setValue={setStatusDropDownValue}
                      onChangeValue={setDoctorCertificateValue}
                      containerStyle={{
                        marginHorizontal: 4
                      }}
                    />
                  </FieldItemLast>
                </View>
                <View style={{ flexDirection: "row", gap: 8, flex: 1 }}>
                  <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <FormFieldTextDate
                      label={"Return to non-contact activity"}
                      required={false}
                      value={returnToNonContact}
                      isDisabled={isUpdating || isLoading}
                      onBlur={(value) => setReturnToNonContact(value)}
                    />
                  </View>
                  <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <FormFieldTextDate
                      label={"Unrestricted return to play"}
                      required={false}
                      value={returnToContact}
                      isDisabled={isUpdating || isLoading}
                      onBlur={(value) => setReturnToContact(value)}
                    />
                  </View>
                </View>

              </SizeClassView>
              <ButtonBlue
                label={saveLabel}
                isDisabled={!shouldUpdate || isUpdating || isLoading}
                onPress={() => {
                  updateMetaData({
                    clubId, reportId,
                    doctorCertificate: doctorCertificateValue,
                    concussionConfirmed: concussionConfirmedValue,
                    returnToNonContact,
                    returnToContact
                  })
                }}
              />
            </>
          ) : (
            <>
              <CardInformationRow>
                <CardInformation title="Concussion confirmed by doctor">
                  {titleCase(report?.metaData?.concussionConfirmed ?? 'Unknown')}
                </CardInformation>
                <CardInformation title="Doctor Certificate">
                  {titleCase(report?.metaData?.doctorCertificate ?? 'Unknown')}
                </CardInformation>
              </CardInformationRow>
              <CardInformationRow>
                <CardInformation title="Return to non-contact activity">
                  {report?.metaData?.returnToNonContact ? formattedShortDate(parseDate(report?.metaData?.returnToNonContact)) : 'Unknown'}
                </CardInformation>
                <CardInformation title="Unrestricted return to play">
                  {report?.metaData?.returnToContact ? formattedShortDate(parseDate(report?.metaData?.returnToContact)) : 'Unknown'}
                </CardInformation>
              </CardInformationRow>
            </>
          )}
        </Centered>
      </ScrollView>
      <Loading isLoading={isLoading || isUpdating} />
    </>
  );
};
