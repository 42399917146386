import React from 'react';
import { Platform } from 'react-native';

import { BackgroundMobile } from './BackgroundMobile';
import { BackgroundWeb } from './BackgroundWeb';

const isWeb = Platform.OS === 'web';

export const Background = ({ children }) => {
  return (
    <>
      {isWeb ? (
        <BackgroundWeb>{children}</BackgroundWeb>
      ) : (
        <BackgroundMobile>{children}</BackgroundMobile>
      )}
    </>
  );
};
