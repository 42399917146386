import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getReportFiles, createReportFile, updateReportFile, deleteReportFile } from 'api/ReportFilesApi';

export const useGetReportFiles = ({ clubId, reportId }) => {
  return useQuery({
    queryKey: queryKey({ clubId, reportId }),
    queryFn: getReportFiles,
    refetchInterval: 1000 * 60, // every minute
    enabled: !!reportId
  });
};

export const useCreateReportFile = ({ clubId, reportId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createReportFile,
    onSuccess: async (data) => {
      if (!reportId) return;
      refreshReportFiles({ queryClient, clubId, reportId })
    },
  })
};

export const useUpdateReportFile = ({ clubId, reportId }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateReportFile,
    onSuccess: (data) => {
      queryClient.setQueryData(['clubs', clubId, 'reports', reportId, 'files', data.id], data)
      refreshReportFiles({ queryClient, clubId, reportId })
    },
  });
};

export const useDeleteReportFile = ({ clubId, reportId }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteReportFile,
    onSuccess: (data) => {
      refreshReportFiles({ queryClient, clubId, reportId })
    },
  });
};

export const refreshReportFiles = ({ queryClient, clubId, reportId }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey({ clubId, reportId })
  });
};

const queryKey = ({ clubId, reportId }) => {
  return ['clubs', clubId, 'reports', reportId, 'files'];
}
