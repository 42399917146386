import React from 'react';

import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';

import { ClubChatScreen } from 'screens/ClubChatScreen';

const PlayersStack = createStackNavigator();

const createScreenOptions = () => {
  return {
    headerShown: false,
    ...TransitionPresets.DefaultTransition,
  };
};

export const ClubChatNavigator = () => {
  return (
    <PlayersStack.Navigator screenOptions={createScreenOptions}>
      <PlayersStack.Screen
        name="ClubChatScreen"
        component={ClubChatScreen}
        options={{ title: 'Chat - Headfirst' }}
      />
    </PlayersStack.Navigator>
  );
};
