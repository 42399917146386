import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Alert } from 'components/Alert';
import { AvoidingView } from 'components/AvoidingView';
import { Background } from 'components/Background';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { ButtonBlue } from 'components/ButtonBlue';
import { Centered } from 'components/Centered';
import { Confirm } from 'components/Confirm';
import { FormFieldText } from 'components/FormFieldText';
import { FormTableSelect } from 'components/FormTableSelect';
import { FormFieldDropDown } from 'components/FormFieldDropDown';
import { Loading } from 'components/Loading';
import { Spacer } from 'components/Spacer';
import { VerticalScroll } from 'components/VerticalScroll';

import { ProfileContext } from 'contexts/ProfileContext';
import { useQueryClient } from '@tanstack/react-query';
import { refreshMe } from 'hooks/useMe';
import { useGetLeagueAgeGroups } from 'hooks/useLeagueAgeGroup';
import { useGetPlayers } from 'hooks/usePlayer';
import { useGetTeam, useDeleteTeam, useUpdateTeam } from 'hooks/useTeam';

export const TeamUpdateScreen = ({ navigation, route }) => {
  const [recordDeleted, setRecordDeleted] = useState(false);
  const queryClient = useQueryClient()
  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => { return currentClub?.club?.id }, [currentClub]);
  const teamId = useMemo(() => { return recordDeleted ? null : route?.params?.teamId }, [route.params, recordDeleted]);
  const leagueId = useMemo(() => {
    if (currentClub) {
      return currentClub?.club.affiliations[0]?.leagueId;
    }
  }, [currentClub]);

  const { data: team, isLoading: teamLoading } = useGetTeam({ clubId, teamId });
  const { mutate: updateTeam, isPending, isSuccess, isError, error } = useUpdateTeam({ clubId });
  const { mutate: deleteTeam, isPending: isDeleting, isSuccess: hasDeleted, isError: isDeletionError, error: deletionError } = useDeleteTeam({ clubId });
  const { data: players, isLoading: playersLoading } = useGetPlayers({ clubId });
  const { data: ageGroups, isLoading: ageGroupsLoading } = useGetLeagueAgeGroups({ leagueId });

  const ageGroupValues = useMemo(() => {
    if (!ageGroups?.data) return [];

    return [
      { id: 'Select an age group', name: 'Select an age group' },
      ...ageGroups.data.map((ageGroup) => { return { id: ageGroup.name, name: ageGroup.name }})
    ];
  }, [ageGroups]);

  const showLoading = useMemo(() => {
    return teamLoading || playersLoading || ageGroupsLoading
  }, [teamLoading, playersLoading, ageGroupsLoading])

  const [name, setName] = useState('');
  const [ageGroup, setAgeGroup] = useState('');
  const [gradeName, setGradeName] = useState('');
  const [playerSearch, setPlayerSearch] = useState('');
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  useEffect(() => {
    if (!team) return;
    setName(team?.name);
    setAgeGroup(team?.ageGroup);
    setGradeName(team?.gradeName);
    setSelectedPlayers(team?.players?.map((player) => player.id))
  }, [team]);

  const filteredPlayers = useMemo(() => {
    if (!players) return []

    var p = [];
    if (!!playerSearch) {
      p = players
        .filter(player => (player.firstName + ' ' + player.lastName).toLowerCase().includes(playerSearch.toLowerCase()))
    } else {
      p = players
        .filter(player => selectedPlayers?.includes(player.id))
    }
    p = p
      .map((player) => {
        return {
          id: player.id,
          name: player.firstName + ' ' + player.lastName,
          teamPlayers: player.teamPlayers,
        };
      })

    return p;
  }, [players, playerSearch, selectedPlayers]);

  const canSave = useMemo(() => {
    if (name !== '') {
      return true
    }
    return true;
  }, [name])

  const saveLabel = useMemo(() => {
    return isPending ? "Saving..." : "Save"
  }, [isPending, canSave])

  const save = () => {
    const club_team = {
      name,
      age_group: ageGroup,
      grade_name: gradeName,
      player_ids: selectedPlayers
    }
    updateTeam({ clubId, teamId, club_team });
  };

  const deleteRecord = () => {
    Confirm('Confirm Delete', `Delete ${name}`, () => {
      deleteTeam({ clubId, teamId });
      setRecordDeleted(true);
    }, () => { });
  };

  useEffect(() => {
    if (isError) Alert('Something went wrong', error?.toString());
  }, [isError]);

  useEffect(() => {
    if (isSuccess || hasDeleted) {
      refreshMe({ queryClient });
      navigation.navigate('TeamsScreen');
    }
  }, [isSuccess, hasDeleted]);

  return (
    <Background>
      <BarHeaderSmall
        title={'Update team'}
        leftButtonTitle="Cancel"
        onLeftPress={() => {
          navigation.navigate('TeamsScreen');
        }}
        rightButtonTitle={'Delete'}
        onRightPress={deleteRecord}
      />
      <AvoidingView>
        <VerticalScroll>
          <Centered>
            <FormFieldText
              label="Name"
              value={name}
              onChangeText={setName}
              autoFocus={true}
            />
            <FormFieldDropDown
              label="Age Group"
              value={ageGroup}
              options={ageGroupValues}
              onChangeText={(value) => {
                if (value != 'Select an age group') {
                  setAgeGroup(value);
                } else {
                  setAgeGroup('');
                }
              }}
            />
            <FormFieldText
              label="Grade/Division"
              value={gradeName}
              onChangeText={setGradeName}
            />
            <FormFieldText
              label="Player Search"
              placeholder="Search"
              value={playerSearch}
              onChangeText={(text) => {
                setPlayerSearch(text);
              }}
              autoFocus={true}
            />
            <FormTableSelect
              label={!!playerSearch ? 'Selecting players' : "Team's Players"}
              values={filteredPlayers.map((player) => ({
                key: player.id,
                value: player.name,
              }))}
              initialKeys={selectedPlayers}
              multiselect={true}
              onSelected={(selected) => {
                setSelectedPlayers(selected)
              }}
            />

            <ButtonBlue
              label={saveLabel}
              isDisabled={!canSave || isPending}
              onPress={save}
            />
            <Spacer type="bottom" size="large" />
          </Centered>
        </VerticalScroll>
        <Loading isLoading={showLoading} />
      </AvoidingView>
    </Background>
  );
};
