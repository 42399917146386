import React, { useContext, useEffect } from 'react';

import { Background } from 'components/Background';
import { BarHeaderLarge } from 'components/BarHeaderLarge';
import { sizeClasses, SizeClassView } from 'components/SizeClassView';
import { PlusIcon, BarButton } from './styles';

import { ProfileContext } from 'contexts/ProfileContext';

import { ReportListRegular } from './ReportListRegular';
import { ReportListCompact } from './ReportListCompact';

export const ReportsScreen = ({ navigation, route }) => {
  const { onMe, currentClub } = useContext(ProfileContext);

  useEffect(() => {
    onMe();
  }, [currentClub, route.params?.updateTime]);

  return (
    <Background>
      <BarHeaderLarge title="Reports">
        <BarButton onPress={() => navigation.navigate('CreateReportScreen')}>
          <PlusIcon />
        </BarButton>
      </BarHeaderLarge>
      <SizeClassView size={sizeClasses.compact}>
        <ReportListCompact navigation={navigation} />
      </SizeClassView>
      <SizeClassView size={sizeClasses.regular}>
        <ReportListRegular navigation={navigation} />
      </SizeClassView>
    </Background>
  );
};
