import React from 'react';

import {
  Container,
  CenteredView,
} from './styles';

export const Centered = ({ children }) => {
  return (
    <Container>
      <CenteredView>{children}</CenteredView>
    </Container>
  );
};
