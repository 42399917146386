import React, { useLayoutEffect, useMemo, useState } from 'react';
import styled from 'styled-components/native';

import { AvoidingView } from 'components/AvoidingView';
import { Background } from 'components/Background';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { Centered } from 'components/Centered';
import { FormTableSelect } from 'components/FormTableSelect';
import { FormFieldText } from 'components/FormFieldText';
import { VerticalScroll } from 'components/VerticalScroll';

const Description = styled.Text`
  color: #728596;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  padding-horizontal: 16px;
  padding-bottom: 32px;
`;
// font-family: "SF Pro Display";

const OtherContainer = styled.View`
  padding-top: 16px;
  border-top-color: ${(props) => props.theme.colors.border};
  border-top-width: 0.5px;
`;

export const ListSelectionScreen = ({ navigation, route }) => {
  const [title, setTitle] = useState('Select');
  const [header, setHeader] = useState('Select');
  const [description, setDescription] = useState('');
  const [multiselect, setMultiselect] = useState(false);
  const [allowSearch, setAllowSearch] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [other, setOther] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useLayoutEffect(() => {
    if (route.params?.title) {
      setTitle(route.params?.title);
    }
    if (route.params?.header) {
      setHeader(route.params?.header);
    }
    if (route.params?.description) {
      setDescription(route.params?.description);
    }
    if (route.params?.multiselect) {
      setMultiselect(route.params?.multiselect);
    }
    if (route.params?.allowSearch) {
      setAllowSearch(route.params?.allowSearch);
    }
    if (route.params?.items) {
    }
    if (route.params?.selectedItems) {
      let otherValue;
      if (Array.isArray(route.params?.selectedItems)) {
        otherValue = route.params?.selectedItems.filter(
          (x) => !route.params?.items.includes(x),
        );
      } else {
        otherValue = [];
      }
      const selectableItems = route.params?.items.filter(
        (x) => !otherValue.includes(x),
      );
      setItems(selectableItems);
      setSelectedItems(route.params?.selectedItems);
      setOther(otherValue?.join(', '));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params]);

  const filteredItems = useMemo(() => {
    var searchItems = items;

    if (!!searchTerm) {
      searchItems = searchItems
        .filter(item => item.toLowerCase().includes(searchTerm.toLowerCase()))
    }
    return searchItems;
  }, [items, searchTerm]);

  const onSave = ({ selected }) => {
    if (selected) {
      navigation.navigate('CreateReportScreen', {
        items: selected,
      });
    } else {
      if (multiselect) {
        var returnItems = selectedItems
          .filter(Boolean)
          .filter((x) => items.includes(x));
        if (other) {
          returnItems = [...returnItems, other];
        }
      } else {
        returnItems = [other];
      }
      navigation.navigate('CreateReportScreen', {
        items: returnItems,
      });
    }
  };

  return (
    <>
      <Background>
        {multiselect || !!other ? (
          <BarHeaderSmall
            title={title}
            leftButtonTitle="Cancel"
            onLeftPress={() => {
              navigation.navigate('CreateReportScreen', {});
            }}
            rightButtonTitle="Save"
            onRightPress={onSave}
          />
        ) : (
          <BarHeaderSmall
            title={title}
            leftButtonTitle="Cancel"
            onLeftPress={() => {
              navigation.navigate('CreateReportScreen', {});
            }}
          />
        )}
        <AvoidingView>
          {allowSearch && (
            <Centered>
              <OtherContainer>
                <FormFieldText
                  label="Search"
                  placeholder="Search"
                  value={searchTerm}
                  onChangeText={(text) => {
                    setSearchTerm(text);
                  }}
                  autoFocus={true}
                />
              </OtherContainer>
            </Centered>
          )}
          <VerticalScroll>
            <Centered>
              <FormTableSelect
                label={header}
                values={filteredItems.map((item) => ({ key: item, value: item }))}
                initialKeys={selectedItems}
                multiselect={multiselect}
                onSelected={(selected) => {
                  setSelectedItems(selected);
                  if (!multiselect) {
                    setTimeout(() => {
                      onSave({ selected });
                    }, 50);
                  }
                }}
              />
              <Description>{description}</Description>
            </Centered>
          </VerticalScroll>
          {/* <Centered>
            <OtherContainer>
              <FormFieldTextMulti
                label="Need another opton?"
                placeholder="Start typing to add another..."
                value={other}
                onChangeText={setOther}
              />
            </OtherContainer>
          </Centered> */}
        </AvoidingView>
      </Background>
    </>
  );
};
