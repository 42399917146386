import React from 'react';

import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';

import { ReportsScreen } from 'screens/ReportsScreen';
import { ReportDetailsScreen } from 'screens/ReportDetailsScreen';
import { CreateReportScreen } from 'screens/CreateReportScreen';
import { ListSelectionScreen } from 'screens/ListSelectionScreen';
import { PlayerSelectionScreen } from 'screens/PlayerSelectionScreen';
import { TeamSelectionScreen } from 'screens/TeamSelectionScreen';

const ReportsStack = createStackNavigator();

const createScreenOptions = () => {
  return {
    headerShown: false,
    ...TransitionPresets.DefaultTransition,
  };
};

export const ReportsNavigator = () => {
  return (
    <ReportsStack.Navigator screenOptions={createScreenOptions}>
      <ReportsStack.Screen
        name="ReportsScreen"
        component={ReportsScreen}
        options={{ title: 'Reports - Headfirst' }}
      />
      <ReportsStack.Screen
        name="ReportDetailsScreen"
        component={ReportDetailsScreen}
        options={{ title: 'Injury Reports - Headfirst' }}
      />
      <ReportsStack.Screen
        name="CreateReportScreen"
        component={CreateReportScreen}
        options={{ title: 'Create Injury Reports - Headfirst' }}
      />
      <ReportsStack.Screen
        name="ListSelectionScreen"
        component={ListSelectionScreen}
        options={{ title: 'Select - Headfirst' }}
      />
      <ReportsStack.Screen
        name="PlayerSelectionScreen"
        component={PlayerSelectionScreen}
        options={{ title: 'Select a player - Headfirst' }}
      />
      <ReportsStack.Screen
        name="TeamSelectionScreen"
        component={TeamSelectionScreen}
        options={{ title: 'Select a team - Headfirst' }}
      />
    </ReportsStack.Navigator>
  );
};
