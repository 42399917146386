import React, { useContext, useMemo } from 'react';

import { Background } from 'components/Background';
import { BarHeaderLarge } from 'components/BarHeaderLarge';
import { sizeClasses, SizeClassView } from 'components/SizeClassView';
import { VerticalScroll } from 'components/VerticalScroll';
import {
  PlusIcon,
  BarButton
} from './styles';

import { ProfileContext } from 'contexts/ProfileContext';

import { DocumentListCompact } from './DocumentListCompact';
import { DocumentListRegular } from './DocumentListRegular';

export const DocumentsScreen = ({ navigation, route }) => {
  const { currentClub } = useContext(ProfileContext);

  const canAddDocument = useMemo(() => {
    return currentClub?.role === 'club_admin';
  }, [currentClub]);

  return (
    <Background>
      <BarHeaderLarge title="Documents">
        {canAddDocument && (
          <BarButton onPress={() => navigation.navigate('DocumentDetailsScreen')}>
            <PlusIcon />
          </BarButton>
        )}
      </BarHeaderLarge>
      <SizeClassView size={sizeClasses.compact}>
        <DocumentListCompact navigation={navigation} />
      </SizeClassView>
      <SizeClassView size={sizeClasses.regular}>
        <VerticalScroll>
          <DocumentListRegular navigation={navigation} />
        </VerticalScroll>
      </SizeClassView>
    </Background>
  );
};
