import camelize from 'camelize';
import { apiSecureGet } from 'api/ApiService';

export const getLeagueFields = async ({ queryKey }) => {
  const leagueId = queryKey[1];
  const response = await apiSecureGet(`/v1/leagues/${leagueId}/league_fields`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return camelize(data);
};
