import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createTeam, deleteTeam, getTeam, getTeams, updateTeam } from 'api/TeamsApi';

export const useGetTeams = ({ clubId }) => {
  return useQuery({
    queryKey: queryKey({ clubId }),
    queryFn: getTeams,
    refetchInterval: 1000 * 60, // every minute
    enabled: !!clubId
  });
};

export const useGetTeam = ({ clubId, teamId }) => {
  return useQuery({
    queryKey: queryKey({ clubId, teamId }),
    queryFn: getTeam,
    enabled: !!clubId && !!teamId
  });
};

export const useCreateTeam = ({ clubId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createTeam,
    onSuccess: async (data) => {
      if (!clubId) return;
      refreshTeams({ queryClient, clubId });
    },
  })
};

export const useUpdateTeam = ({ clubId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateTeam,
    onSuccess: async (data) => {
      queryClient.setQueryData(['clubs', clubId, 'teams', data.id], data)
      refreshTeams({ queryClient, clubId });
    },
  })
};

export const useDeleteTeam = ({ clubId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteTeam,
    onSuccess: async (data) => {
      refreshTeams({ queryClient, clubId });
    },
  })
};

export const refreshTeams = ({ queryClient, clubId }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey({ clubId })
  });
};

const queryKey = ({ clubId, teamId }) => {
  if (teamId) {
    return ['clubs', clubId, 'teams', teamId];
  } else {
    return ['clubs', clubId, 'teams'];
  }
}