import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Platform, ScrollView, Share, View } from 'react-native';
import * as FileSystem from 'expo-file-system';

import { ProfileContext } from 'contexts/ProfileContext';
import { useGetReport, useGetReportPdf } from 'hooks/useReport';

import { Background } from 'components/Background';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { BarSwitcher } from 'components/BarSwitcher';
import { Centered } from 'components/Centered';
import { SizeClassView, sizeClasses } from 'components/SizeClassView';
import { VerticalScroll } from 'components/VerticalScroll';

import { ReportDetails } from './ReportDetails';
import { ReportNotes } from './ReportNotes';
import { ReportFiles } from './ReportFiles';
import { ReportReturnToPlay } from './ReportReturnToPlay';

import { format } from 'date-fns'

export const ReportDetailsScreen = ({ navigation, route }) => {
  const { currentClub } = useContext(ProfileContext);
  const { reportId } = route.params;

  const currentRole = useMemo(() => currentClub?.role, [currentClub]);
  const clubId = useMemo(() => currentClub?.clubId, [currentClub]);

  const { data: report } = useGetReport({ clubId, reportId })
  const { data: reportPdf, refetch } = useGetReportPdf({ clubId, reportId })
  const [requestPDF, setRequestPDF] = useState(false);

  const [tabItems, setIabItems] = useState(['Details', 'Notes', 'Files']);
  const [currentTab, setCurrentTab] = useState(0);
  const [showNotes, setShowNotes] = useState(true);

  useEffect(() => {
    if (!requestPDF) return;
    setRequestPDF(false);
    if (!!reportPdf) {
      const player_name = report?.fieldData?.player || 'No Player Name'
      const today = format(new Date(), 'dd MMM yyyy');
      const filename = `Injury report - ${player_name} - ${today}.pdf`;

      if (Platform.OS === 'web') {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(reportPdf);
        link.download = filename;
        document.body.append(link);
        link.click();
        link.remove();
      } else {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          const fileUri = `${FileSystem.documentDirectory}/${encodeURIComponent(filename)}`;
          await FileSystem.writeAsStringAsync(fileUri, fileReader.result.split(',')[1], { encoding: FileSystem.EncodingType.Base64 });
          await Share.share({
            message: filename,
            title: "Share file",
            url: fileUri,
          });
        }
        fileReader.readAsDataURL(reportPdf);
      }
    }
  }, [reportPdf])

  useEffect(() => {
    if (!!currentClub && !!currentRole) {
      switch (currentRole) {
        case 'simpler':
          setShowNotes(true);
          break;
        case 'trainer':
          setShowNotes(true);
          break;
        case 'club_admin':
          setShowNotes(true);
          break;
      }
    }
  }, [currentClub, currentRole]);

  useEffect(() => {
    if (showNotes) {
      setIabItems(['Details', 'Return to Play', 'Notes', 'Files']);
    } else {
      setIabItems(['Details']);
    }
  }, [showNotes]);

  const downloadReportPDF = () => {
    setRequestPDF(true);
    refetch();
  };

  return (
    <Background>
      <BarHeaderSmall
        title={'Injury Report'}
        leftButtonTitle="Back"
        onLeftPress={() => {
          navigation.navigate('ReportsScreen', { updateTime: new Date().getTime() });
        }}
        rightButtonTitle={"Print PDF"}
        onRightPress={downloadReportPDF}
      />
      <SizeClassView size={sizeClasses.regular}>
        <View style={{ flexDirection: "row", flex: 1 }}>
          <View style={{ flex: 1 }}>
            <BarHeaderSmall title="Injury Report Details" />
            <VerticalScroll>
              <View style={{ flex: 1, paddingTop: 16 }}>
                <ReportDetails clubId={clubId} reportId={reportId} navigation={navigation} />
              </View>
            </VerticalScroll>
          </View>
          <View style={{ flex: 1, backgroundColor: '#d5d7da' }}>
            <Centered>
              <BarSwitcher
                items={['Return to Play']}
                tab={0}
              />
            </Centered>
            <VerticalScroll>
              <View style={{ flex: 1, paddingTop: 16, paddingHorizontal: 16 }}>
                <ReportReturnToPlay clubId={clubId} reportId={reportId} />
              </View>
            </VerticalScroll>
          </View>
          <View style={{ flex: 1, backgroundColor: '#d5d7da' }}>
            <Centered>
              <BarSwitcher
                items={['Notes', 'Files']}
                tab={currentTab}
                onSelected={(item) => {
                  setCurrentTab(['Notes', 'Files'].findIndex((i) => i === item));
                }}
              />
            </Centered>
            <VerticalScroll>
              <View style={{ flex: 1, paddingTop: 16, paddingHorizontal: 16 }}>
                {reportId && (
                  <>
                    {currentTab === 0 && <ReportNotes clubId={clubId} reportId={reportId} />}
                    {currentTab === 1 && <ReportFiles clubId={clubId} reportId={reportId} />}
                  </>
                )}
              </View>
            </VerticalScroll>
          </View>
        </View>
      </SizeClassView>
      <SizeClassView size={sizeClasses.compact}>
        <Centered>
          <BarSwitcher
            items={tabItems}
            tab={currentTab}
            onSelected={(item) => {
              setCurrentTab(tabItems.findIndex((i) => i === item));
            }}
          />
        </Centered>
        {reportId && (
          <>
            {currentTab === 0 && (
              <ReportDetails clubId={clubId} reportId={reportId} navigation={navigation} />
            )}
            {currentTab === 1 && <ReportReturnToPlay clubId={clubId} reportId={reportId} />}
            {currentTab === 2 && <ReportNotes clubId={clubId} reportId={reportId} />}
            {currentTab === 3 && <ReportFiles clubId={clubId} reportId={reportId} />}
          </>
        )}
      </SizeClassView>
    </Background >
  );
};
