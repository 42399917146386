import camelize from 'camelize';
import { parseDateTime, formattedShortDate } from 'utils/Dates';
import { apiSecureGet } from 'api/ApiService';

export const getReportTemplates = async ({ leagueId }) => {
  return apiSecureGet(`/v1/leagues/${leagueId}/report_templates`)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

const transform = (result = {}) => {
  result.createdAtShort = formattedShortDate(parseDateTime(result.created_at));
  result.updatedAtShort = formattedShortDate(parseDateTime(result.updated_at));
  return camelize(result);
};

export const reportTemplateTransform = (result = {}) => {
  return transform(result.data);
};

export const reportTemplatesTransform = (results = {}) => {
  return results.data.map((result) => transform(result));
};
