import styled from 'styled-components/native';

export const Container = styled.View`
  background-color: ${(props) => props.theme.colors.pure.white};
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
`;

export const ClosedContainer = styled.View`
  background-color: #f7f7f7;
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
`;

export const Background = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const Content = styled.View`
  flex: 1;
  flex-grow: 1;
  padding-vertical: 12px;
  padding-horizontal: 16px;
`;

export const TitleLine = styled.View`
  flex-direction: row;
`;

export const Title = styled.Text.attrs({
  numberOfLines: 1,
  ellipsizeMode: 'tail',
})`
  flex: 1;
  flex-grow: 1;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  margin-right: 16px;
`;
// font-family: "SF Pro Display";

export const Date = styled.Text`
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 15px;
  text-align: center;
`;
// font-family: "SF Pro Display";

export const ClosedDate = styled.Text`
  color: #a5a5a5;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-align: center;
`;
// font-family: "SF Pro Display";

export const ClosedTitle = styled.Text.attrs({
  numberOfLines: 1,
  ellipsizeMode: 'tail',
})`
  flex: 1;
  color: #a5a5a5;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  margin-right: 16px;
`;
// font-family: "SF Pro Display";

export const Message = styled.Text`
  width: 100%;
  color: #728596;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

export const ClosedMessage = styled.Text`
  width: 100%;
  color: #a5a5a5;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

// import Forward from "../../../assets/chevron_forward.svg";
// export const Icon = styled(Forward).attrs({ width: 16, height: 16 })``;
export const Icon = styled.View``;

import DeleteIconPNG from '../../../assets/icons/delete-icon-red.png';
export const DeleteIcon = styled.Image.attrs({
  source: DeleteIconPNG,
})`
  width: 24px;
  height: 24px;
`;

export const DeleteButton = styled.Pressable`
  padding-horizontal: 16px;
`;

import EditIconPNG from '../../../assets/icons/edit-icon-green.png';
export const EditIcon = styled.Image.attrs({
  source: EditIconPNG,
})`
  width: 24px;
  height: 24px;
`;

export const EditButton = styled.Pressable`
  padding-horizontal: 16px;
`;