import React from 'react';

import { Container, LinkText, Pressable } from './styles';

export const ActivateLink = ({ onPress, isDisabled, children }) => {
  return (
    <Container>
      <Pressable
        onPress={onPress}
        disabled={isDisabled}
        style={({ pressed }) => [
          isDisabled
            ? { opacity: 0.3 }
            : pressed
              ? { opacity: 0.7 }
              : { opacity: 1.0 },
        ]}
      >
        <LinkText>{children}</LinkText>
      </Pressable>
    </Container>
  );
};
