import React, { useContext, useMemo } from 'react';

import { BarChanger } from 'components/BarChanger';

import { ProfileContext } from 'contexts/ProfileContext';

export const ChangeTeam = ({ navigation }) => {
  const { currentClub, currentTeam } = useContext(ProfileContext);
  const teamName = useMemo(() => {
    return currentTeam?.name || 'All my teams';
  }, [currentTeam]);

  const clubTeams = useMemo(() => {
    return currentClub?.teams;
  }, [currentClub]);

  return (
    <>
      {clubTeams?.length > 1 ? (
        <BarChanger
          title={teamName}
          link="Change"
          onPress={() => navigation.navigate('ChangeTeamScreen')}
        />
      ) : (
        <BarChanger title={teamName} />
      )}
    </>
  );
};
