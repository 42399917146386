import { SafeAreaView } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

export const BlackBackground = styled.View`
  flex: 1;
  background-color: ${(props) => props.theme.colors.pure.black};
`;

export const SafeArea = styled(SafeAreaView).attrs({
  edges: ['top', 'right', 'left'],
})`
  flex: 1;
`;

export const GreyBackground = styled.View`
  flex: 1;
  background-color: ${(props) => props.theme.colors.backgroundGrey};
`;

export const Content = styled.View`
  flex: 1;
`;

export const WebContent = styled.View`
  flex: 1;
`;