import React, { useLayoutEffect, useState } from 'react';
import Svg, { Circle } from 'react-native-svg';

import {
  Container,
  Switcher,
  BubbleContainer,
  Bubble,
  PressableBubble,
  NotificationContainer,
} from './styles'

const NotificationImage = () => (
  <NotificationContainer>
    <Svg height="16" width="16">
      <Circle cx="8" cy="8" r="8" fill="#EB4E3D" />
    </Svg>
  </NotificationContainer>
);

const BarBubble = ({ isActive, hasNotification, onPress, children }) => {
  return (
    <BubbleContainer mode={isActive ? 'active' : 'inactive'}>
      <PressableBubble onPress={onPress}>
        <Bubble mode={isActive ? 'active' : 'inactive'}>{children}</Bubble>
        {hasNotification === true && <NotificationImage />}
      </PressableBubble>
    </BubbleContainer>
  );
};

export const BarSwitcher = ({ items, tab, onSelected }) => {
  const [current, setCurrent] = useState('');
  useLayoutEffect(() => {
    setCurrent(items[tab]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <Switcher>
        {items.map((item) => {
          return (
            <BarBubble
              key={item}
              isActive={item === current}
              onPress={() => {
                setCurrent(item);
                if (onSelected) {
                  onSelected(item);
                }
              }}
            >
              {item}
            </BarBubble>
          );
        })}
      </Switcher>
    </Container>
  );
};
