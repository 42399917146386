import camelize from 'camelize';
import { parseDateTime, formattedShortDate } from 'utils/Dates';
import { apiSecureDelete, apiSecureGet, apiSecurePost, apiSecurePut } from 'api/ApiService';

export const getReportFiles = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const reportId = queryKey[3];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/reports/${reportId}/files`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return reportFilesTransform(data);
};

function dataURItoFile(dataURI, file_name, file_type) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  var blob = new Blob([ia], { type: mimeString });

  return new File([blob], file_name, {
    type: file_type,
  });
}

export const createReportFile = async ({ clubId, reportId, reportFile }) => {
  var formdata = new FormData();
  formdata.append('file[title]', reportFile.title);
  formdata.append('file[description]', reportFile.description);
  if (reportFile.file?.uri?.startsWith('file')) {
    formdata.append('file[file]', {
      name: reportFile.file?.name || reportFile.file?.fileName,
      type: reportFile.file?.mimeType || reportFile.file?.type,
      uri: reportFile.file.uri,
    });
  }
  if (reportFile.file?.uri?.startsWith('data')) {
    formdata.append(
      'file[file]',
      dataURItoFile(
        reportFile.file.uri,
        reportFile.file.name,
        reportFile.file.mimeType
      ),
    );
  }

  const response = await apiSecurePost(`/v1/clubs/${clubId}/reports/${reportId}/files`, formdata)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return reportFileTransform(data);
};

export const updateReportFile = async ({ clubId, reportId, reportFile }) => {
  var formdata = new FormData();
  formdata.append('file[title]', reportFile.title);
  formdata.append('file[description]', reportFile.description);
  if (reportFile.file?.uri?.startsWith('file')) {
    formdata.append('file[file]', {
      name: reportFile.file?.name || reportFile.file?.fileName,
      type: reportFile.file?.mimeType || reportFile.file?.type,
      uri: reportFile.file.uri,
    });
  }
  if (reportFile.file?.uri?.startsWith('data')) {
    formdata.append(
      'file[file]',
      dataURItoFile(
        reportFile.file.uri,
        reportFile.file.name,
        reportFile.file.type,
      ),
    );
  }
  const response = await apiSecurePut(`/v1/clubs/${clubId}/reports/${reportId}/files/${reportFile.id}`, formdata)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return reportFileTransform(data);
};

export const deleteReportFile = async ({ clubId, reportId, file }) => {
  return apiSecureDelete(`/v1/clubs/${clubId}/reports/${reportId}/files/${file.id}`)
    .then((response) => {
      return { success: true };
    })
    .catch((error) => {
      return { errors: [error] };
    });
};

const fileTransform = (result = {}) => {
  result.createdAtShort = formattedShortDate(parseDateTime(result.created_at));
  result.updatedAtShort = formattedShortDate(parseDateTime(result.updated_at));
  return camelize(result);
};

export const reportFileTransform = (result = {}) => {
  return fileTransform(result.data);
};

export const reportFilesTransform = (results = {}) => {
  return results.data.map((result) => fileTransform(result));
};
