import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getPlayer, getPlayers, createPlayer, updatePlayer } from 'api/PlayersApi';
import { refreshTeams } from './useTeam';

export const useGetPlayers = ({ clubId }) => {
  return useQuery({
    queryKey: queryKey({ clubId }),
    queryFn: getPlayers,
    refetchInterval: 1000 * 60, // every minute
    enabled: !!clubId
  });
};

export const useGetPlayer = ({ clubId, playerId }) => {
  return useQuery({
    queryKey: queryKey({ clubId, playerId }),
    queryFn: getPlayer,
    enabled: !!playerId
  });
};

export const useCreatePlayer = ({ clubId }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createPlayer,
    onSuccess: async (data) => {
      if (!clubId) return;
      refreshPlayers({ queryClient, clubId });
      refreshTeams({ queryClient, clubId })
    },
  });
};

export const useUpdatePlayer = ({ clubId, playerId }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updatePlayer,
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey({ clubId, playerId }), data)
      refreshPlayers({ queryClient, clubId })
      refreshTeams({ queryClient, clubId })
    },
  });
};

export const refreshPlayers = ({ queryClient, clubId }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey({ clubId })
  });
};

const queryKey = ({ clubId, playerId }) => {
  if (playerId) {
    return ['clubs', clubId, 'players', playerId];
  } else {
    return ['clubs', clubId, 'players'];
  }
}
