import React from 'react';

import {
  Container,
  Subheading,
  Text
} from './styles';

export const CardInformation = ({ title, children }) => {
  return (
    <Container>
      {title && (
        <Subheading>{title}</Subheading>
      )}
      <Text>{children}</Text>
    </Container>
  );
};
