import camelize from 'camelize';
import { parseDateTime, formattedShortDate } from 'utils/Dates';
import { apiGet } from 'api/ApiService';

export const getAppVersion = async () => {
  const response = await apiGet('/app_version')
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return appVersionTransform(data);
};

const appVersionTransform = (result = {}) => {
  result.data.released_at_short = formattedShortDate(parseDateTime(result.data.released_at));
  return camelize(result.data);
};
