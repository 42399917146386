import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createTrainer, deleteTrainer, getTrainer, getTrainers, updateTrainer } from 'api/TrainersApi';

export const useGetTrainers = ({ clubId }) => {
  return useQuery({
    queryKey: queryKey({ clubId }),
    queryFn: getTrainers,
    refetchInterval: 1000 * 60, // every minute
    enabled: !!clubId
  });
};

export const useGetTrainer = ({ clubId, trainerId }) => {
  return useQuery({
    queryKey: queryKey({ clubId, trainerId }),
    queryFn: getTrainer,
    enabled: !!clubId && !!trainerId
  });
};

export const useCreateTrainer = ({ clubId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createTrainer,
    onSuccess: async (data) => {
      if (!clubId) return;
      refreshTrainers({ queryClient, clubId });
    },
  })
};

export const useUpdateTrainer = ({ clubId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateTrainer,
    onSuccess: async (data) => {
      queryClient.setQueryData(['clubs', clubId, 'trainers', data.id], data)
      refreshTrainers({ queryClient, clubId });
    },
  })
};

export const useDeleteTrainer = ({ clubId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteTrainer,
    onSuccess: async (data) => {
      refreshTrainers({ queryClient, clubId });
    },
  })
};

export const refreshTrainers = ({ queryClient, clubId }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey({ clubId })
  });
};

const queryKey = ({ clubId, trainerId }) => {
  if (trainerId) {
    return ['clubs', clubId, 'trainers', trainerId];
  } else {
    return ['clubs', clubId, 'trainers'];
  }
}
