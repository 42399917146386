import React from 'react';

import { Spacer } from 'components/Spacer';
import { AccountContainer } from 'components/AccountContainer';
import { AccountButton } from 'components/AccountButton';
import { AccountBackground } from 'components/AccountBackground';

export const AccountScreen = ({ navigation }) => {
  return (
    <AccountBackground>
      <AccountContainer>
        <AccountButton title={'Login'} onPress={() => navigation.navigate('Login')} />
        <Spacer size="large">
          <AccountButton
            title={'Register'}
            onPress={() => navigation.navigate('Register')}
          />
        </Spacer>
      </AccountContainer>
    </AccountBackground>
  );
};
