import { StyleSheet } from 'react-native';

import { colors } from 'theme/colors';

export const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 4,
    marginHorizontal: '10%',
  },
  text: {
    fontSize: 16,
    lineHeight: 20,
    fontWeight: '600', //Semibold
    color: colors.pure.white,
  },
  initial: {
    borderWidth: 1,
    borderColor: colors.pure.white,
  },
  pressed: {
    borderWidth: 1,
    borderColor: colors.pure.white,
    opacity: 0.75,
  },
  disabled: {
    borderWidth: 1,
    borderColor: colors.twoWeeks.greenDisabled,
    opacity: 0.5,
  },
});
