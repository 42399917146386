import styled from 'styled-components/native';
import { colors } from 'theme/colors';

export const Container = styled.View`
  margin-horizontal: 8px;
`;

export const InputContainer = styled.View`
  background-color: ${(props) => props.theme.colors.pure.white};
  border-color: ${(props) => props.theme.colors.border};
  border-width: 1px;
  border-radius: 16px;
`;

export const Input = styled.TextInput.attrs({
  multiline: false,
  outlineStyle: 'none',
  placeholderTextColor: colors.selectedGrey
  // autoComplete: "username",
  // keyboardType: "email-address",
  // textContentType: "username",
  // autoCapitalize: "none",
  // returnKeyType: "next",
  // autoFocus: true,
})`
  color: ${(props) => props.theme.colors.pure.black};
  font-size: 14px;
  letter-spacing: 0;
  padding-horizontal: 12px;
  padding-vertical: 12px;
  border-radius: 16px;
`;
// font-family: "SF Pro Display";