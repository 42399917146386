import React from 'react';
import { Pressable, Text } from 'react-native';

import { styles } from './styles';

export const AccountButton = ({ isDisabled, ...props }) => {
  return (
    <Pressable
      onPress={props.onPress}
      disabled={isDisabled}
      style={({ pressed }) => [
        isDisabled
          ? styles.disabled
          : pressed
          ? styles.pressed
          : styles.initial,
        styles.button,
        props.style,
      ]}
    >
      <Text style={styles.text}>{props.title}</Text>
    </Pressable>
  );
};

