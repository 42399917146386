import React from 'react';

import {
  Container,
  Background,
  Row,
  Author,
  Date,
  Text,
} from './styles'

export const CardNote = ({ date, author, type, children }) => {
  return (
    <Container>
      <Background type={type}>
        <Row>
          <Author>{author}</Author>
          <Date>{date}</Date>
        </Row>
        <Text>{children}</Text>
      </Background>
    </Container>
  );
};
