import { Alert as ReactNativeAlert, Platform } from 'react-native';

export const Confirm = (title, message = '', onConfirm = null, onCancel = null) => {
  if (Platform.OS === 'web') {
    const result = window.confirm([message.toString()])
    if (result) {
      onConfirm()
    } else {
      onCancel()
    }
  } else {
    ReactNativeAlert.alert(title, message.toString(), [
      {
        text: "Cancel",
        onPress: onCancel,
        style: "cancel",
      },
      {
        text: "Delete",
        onPress: onConfirm,
        style: "destructive",
      },
    ],
      {
        cancelable: true,
        onDismiss: onCancel,
      }
    );
  }
}
