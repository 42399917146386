import React, { useContext, useEffect, useMemo, useState } from 'react';
import * as DocumentPicker from 'expo-document-picker';

import { Alert } from 'components/Alert';
import { Background } from 'components/Background';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { ButtonBlue } from 'components/ButtonBlue';
import { ButtonWhite } from 'components/ButtonWhite';
import { CardInformation } from 'components/CardInformation';
import { CardInformationRow } from './styles';
import { Centered } from 'components/Centered';
import { FormFieldText } from 'components/FormFieldText';
import { Loading } from 'components/Loading';
import { VerticalScroll } from 'components/VerticalScroll';

import { ProfileContext } from 'contexts/ProfileContext';
import { useCreateImportPlayer } from 'hooks/useImportPlayer';

export const ImportPlayerUploadScreen = ({ route, navigation }) => {
  const { currentClub, profile } = useContext(ProfileContext);
  const clubId = useMemo(() => { return currentClub?.club?.id }, [currentClub]);
  const { mutate: createImportPlayer, data: importPlayer, isPending, isSuccess, isError, error } = useCreateImportPlayer({ clubId });

  const [importFile, setImportFile] = useState(null);

  const pickFile = async () => {
    const result = await DocumentPicker.getDocumentAsync({ type: ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] })
    if (!result.cancelled) {
      setImportFile(result?.assets[0]?.file)
    }
  }
  const navBack = () => navigation.goBack()

  useEffect(() => {
    if (isError) Alert('Something went wrong', error?.toString());
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      navigation.navigate('ImportPlayerScreen', { importPlayerId: importPlayer.id });
    }
  }, [isSuccess]);

  return (
    <Background>
      <BarHeaderSmall
        title={'Upload a players file'}
        leftButtonTitle="Back"
        onLeftPress={navBack}
      />
      <VerticalScroll>
        <Centered>
          <CardInformationRow>
            <CardInformation title="Filename">{importFile?.name}</CardInformation>
          </CardInformationRow>
          <ButtonWhite label="Pick a file" onPress={() => pickFile()} />
          <ButtonBlue
            label={'Upload'}
            isDisabled={!importFile}
            onPress={() => {
              createImportPlayer({ clubId, importFile });
            }}
          />
        </Centered>
      </VerticalScroll>
      <Loading isLoading={isPending} />
    </Background>
  );
};
