import React from 'react';

import { Background } from 'components/Background';
import { BarHeaderLarge } from 'components/BarHeaderLarge';
import { VerticalScroll } from 'components/VerticalScroll';

import { RoundSummaryJuniorClubs } from './RoundSummaryJuniorClubs';
import { SeasonOverviewJuniorClubs } from './SeasonOverviewJuniorClubs';
import { RoundSummarySeniorClubs } from './RoundSummarySeniorClubs';
import { SeasonOverviewSeniorClubs } from './SeasonOverviewSeniorClubs';

export const InsightsScreen = ({ navigation }) => {
  return (
    <Background>
      <BarHeaderLarge title="Insights" />
      <VerticalScroll>
        <RoundSummaryJuniorClubs />
        <SeasonOverviewJuniorClubs />
        <RoundSummarySeniorClubs />
        <SeasonOverviewSeniorClubs />
      </VerticalScroll>
    </Background>
  );
};
