import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
`;

export const HeaderContainer = styled.View`
  flex-direction: row;
  margin-vertical: 8px;
`;

export const HeaderText = styled.Text`
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
  padding-horizontal: 16px;
  padding-bottom: 8px;
`;
// font-family: "SF Pro Display";

export const RequiredText = styled.Text`
  color: ${(props) => props.theme.colors.error.red};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
`;
// font-family: "SF Pro Display";

export const RowSelection = styled.View`
  margin-top: 2px;
  margin-bottom: 5px;
  background-color: ${(props) => props.theme.colors.pure.white};
  border-top-color: ${(props) => props.theme.colors.border};
  border-top-width: 0.5px;
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
`;

export const RowContainer = styled.View`
  width: 100%;
  flex-direction: row;
  padding: 16px;
  align-items: center;
`;

export const LabelText = styled.Text`
  flex: 1;
  color: ${(props) => props.theme.colors.pure.black};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  min-width: 64px;
`;
// font-family: "SF Pro Display";

export const ValueText = styled.Text.attrs({
  numberOfLines: 1,
  ellipsizeMode: 'tail',
})`
  color: ${(props) => props.theme.colors.selectedGrey};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: right;
  max-width: 60%;
  padding-horizontal: 16px;
`;
// font-family: "SF Pro Display";

export const Extra = styled.Text`
  color: ${(props) => props.theme.colors.selectedGrey};
`;

// import Forward from "../../../assets/chevron_forward.svg";
// export const Icon = styled(Forward).attrs({ width: 19, height: 19 })``;
export const Icon = styled.View``;

export const Switch = styled.Switch``;