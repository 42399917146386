import styled from 'styled-components/native';

export const Container = styled.View`
  padding-vertical: 8px;
  padding-horizontal: 16px;
  border-top-color: ${(props) => props.theme.colors.border};
  border-top-width: 0.5px;
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
`;

export const InputContainer = styled.View`
  border-radius: 10px;
`;

export const Input = styled.TextInput.attrs({
  multiline: true,
  outlineStyle: 'none',
  numberOfLines: 5,
  maxHeight: 160,
  minHeight: 36,
})`
  color: ${(props) => props.theme.colors.pure.black};
  font-size: 15px;
  letter-spacing: 0;
  padding-left: 12px;
  padding-right: 36px;
  padding-vertical: 8px;
  border-radius: 10px;
`;
// font-family: "SF Pro Display";

export const BarButton = styled.Pressable`
  padding-top: 16px;
  padding-horizontal: 16px;
  padding-bottom: 4px;
  position: absolute;
  bottom: 10px;
  right: 8px;
`;

import PlusPNG from '../../../assets/icons/plus-orange.png';
export const PlusIcon = styled.Image.attrs({
  source: PlusPNG,
})`
  width: 24px;
  height: 24px;
`;
