import React, { useState } from 'react';

import {
  Container,
  InputContainer,
  Input,
  BarButton,
  PlusIcon,
} from './styles'
import { colors } from 'theme/colors';

export const MessageTextInput = ({
  value,
  onChangeText,
  isDisabled = false,
  placeholder,
  keyboardType,
  autoCapitalize,
  autoFocus,
  onPress,
  inputRef
}) => {
  return (
    <Container>
      <InputContainer style={{ backgroundColor: (isDisabled ? colors.ui.disabled : colors.pure.white) }}>
        <Input
          ref={inputRef}
          value={value}
          onChangeText={(text) => {
            const cleanText = text.replace(/(\r\n|\n|\r)/gm, "");
            if (onChangeText) onChangeText(cleanText)
          }}
          placeholder={placeholder}
          keyboardType={keyboardType}
          autoCapitalize={autoCapitalize}
          autoFocus={autoFocus}
          returnKeyType={"send"}
          onSubmitEditing={() => {
            if (onPress) onPress(value);
          }}
          onKeyPress={(e) => {
            if (e.nativeEvent.key == 'Enter') {
              if (onPress) onPress(value);
              e.preventDefault();
              return false;
            }
          }}
        />
      </InputContainer>
      <BarButton onPress={() => {
        if (onPress) onPress(value);
      }}>
        <PlusIcon />
      </BarButton>
    </Container>
  );
};
