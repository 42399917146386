import React from 'react';

import { LinkText, Pressable } from './styles';

export const AccountLink = ({ onPress, isDisabled, children }) => {
  return (
    <Pressable
      onPress={onPress}
      disabled={isDisabled}
      style={({ pressed }) => [
        isDisabled
          ? { opacity: 0.3 }
          : pressed
          ? { opacity: 0.7 }
          : { opacity: 1.0 },
      ]}
    >
      <LinkText>{children}</LinkText>
    </Pressable>
  );
};
