import React, { useContext, useMemo } from 'react';

import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { ButtonBlue } from 'components/ButtonBlue';
import { Container, DataList } from './styles';
import { Centered } from 'components/Centered';
import { Footer } from 'components/Footer';
import { Loading } from 'components/Loading';
import { DataTable } from 'react-native-paper';
import {
  CellText,
  DataTableView,
  DataTableTitleCenter,
  DataTableCellCenter,
} from './styles';

import { ProfileContext } from 'contexts/ProfileContext';
import { useUpdateImportPlayer } from 'hooks/useImportPlayer';
import { useGetInfiniteImportPlayerRows } from 'hooks/useImportPlayerRow';

export const PreviewPage = ({ importPlayer, navigation }) => {
  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => currentClub?.clubId, [currentClub]);
  const importPlayerId = useMemo(() => importPlayer?.id, [importPlayer]);

  const { mutate: updateImportPlayer, isPending: isUpdating, isSuccess: hasUpdated, isError: isErrorUpdate, error: updateError } = useUpdateImportPlayer({ clubId });
  const { isLoading, data: importPlayerRows, hasPreviousPage, fetchPreviousPage, hasNextPage, fetchNextPage, isError, error } = useGetInfiniteImportPlayerRows({ clubId, importPlayerId })

  const rowData = useMemo(() => {
    if (!importPlayerRows) return [];

    const itemsData = importPlayerRows.pages.map(page => page.data).flat();
    return itemsData;
  }, [importPlayerRows])

  const totalToImport = useMemo(() => {
    if (!importPlayerRows?.pages) return 0;

    return importPlayerRows.pages[0].meta.totalCount;
  }, [importPlayerRows])

  const loadNext = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const canContinue = useMemo(() => {
    return true
  }, []);

  const save = () => {
    const formData = {
      id: importPlayer?.id,
      club_id: importPlayer?.clubId,
      status: 'import'
    };
    updateImportPlayer(formData);
  };

  const navBack = () => navigation.navigate('PlayersScreen');

  const renderItem = ({ item: row }) => {
    return (
      <DataTable.Row key={row.id}>
        <DataTableCellCenter style={{ flex: 0.5 }}>
          <CellText>
            {row.rowIndex}
          </CellText>
        </DataTableCellCenter>
        <DataTableCellCenter style={{ flex: 1 }}>
          <CellText>
            {row?.playerDetails?.firstName}
          </CellText>
        </DataTableCellCenter>
        <DataTableCellCenter style={{ flex: 1 }}>
          <CellText>
            {row?.playerDetails?.lastName}
          </CellText>
        </DataTableCellCenter>
        <DataTableCellCenter style={{ flex: 2 }}>
          <CellText>
            {row?.teamDetails?.teamName}
          </CellText>
        </DataTableCellCenter>
        <DataTableCellCenter style={{ flex: 2 }}>
          <CellText>
            {row?.teamDetails?.gradeName}
          </CellText>
        </DataTableCellCenter>
        <DataTableCellCenter style={{ flex: 1 }}>
          <CellText>
            {row?.playerMeta?.status}
          </CellText>
        </DataTableCellCenter>
        <DataTableCellCenter style={{ flex: 1 }}>
          <CellText>
            {row?.playerMeta?.role}
          </CellText>
        </DataTableCellCenter>
      </DataTable.Row>)
  };

  return (
    <>
      <BarHeaderSmall
        title={'Import Players - Preview'}
        leftButtonTitle="Back"
        onLeftPress={navBack}
      />
      <>
        <Container>
          <DataTableView>
            <DataTable.Header>
              <DataTableTitleCenter style={{ flex: 0.5 }}>Row</DataTableTitleCenter>
              <DataTableTitleCenter style={{ flex: 1 }}>First Name</DataTableTitleCenter>
              <DataTableTitleCenter style={{ flex: 1 }}>Last Name</DataTableTitleCenter>
              <DataTableTitleCenter style={{ flex: 2 }}>Team</DataTableTitleCenter>
              <DataTableTitleCenter style={{ flex: 2 }}>Grade</DataTableTitleCenter>
              <DataTableTitleCenter style={{ flex: 1 }}>Status</DataTableTitleCenter>
              <DataTableTitleCenter style={{ flex: 1 }}>Role</DataTableTitleCenter>
            </DataTable.Header>
            {rowData ? (
              <DataList
                data={rowData}
                renderItem={renderItem}
                keyExtractor={(item, index) => {
                  const key = item.id;
                  return key;
                }}
                onEndReached={loadNext}
                onEndReachedThreshold={0.5}
              />
            ) : (
              <Loading isLoading={true} />
            )}
          </DataTableView>
        </Container>
        <Footer>
          <Centered>
            <ButtonBlue
              label={importPlayerRows ? `Import (${totalToImport})` : "Import"}
              isDisabled={!canContinue}
              onPress={() => { save() }}
            />
          </Centered>
        </Footer>
      </>
    </>
  );
};
