import React, { useContext, useState, useRef, useMemo } from 'react';
import { ActivityIndicator } from 'react-native-paper';
import * as Linking from 'expo-linking';

import { AccountBackground } from 'components/AccountBackground';
import { AccountButton } from 'components/AccountButton';
import { AccountContainer } from 'components/AccountContainer';
import { AuthFields } from 'components/AuthFields';
import { AuthInput } from 'components/AuthInput';
import { AuthInputBorder } from 'components/AuthInputBorder';
import { ErrorContainer, ErrorText } from './styles';
import { Spacer } from 'components/Spacer';
import { Text } from 'components/Text';

import { AuthenticationContext } from 'contexts/AuthenticationContext';
import { colors } from '../../theme/colors';

export const CreatePasswordScreen = ({ navigation }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { onResetPassword, passwordResetToken, isLoading, error } = useContext(
    AuthenticationContext,
  );
  const confirmPasswordField = useRef(null);
  const url = Linking.useURL();

  const errorMessage = useMemo(() => {
    if (error == 'Unauthorized') {
      return 'Password reset link expired or has already been used. Please try resetting your password again.'
    } else {
      return error
    }
  }, [error])

  const isSubmittable = () => {
    return (
      password !== '' && confirmPassword !== '' && password === confirmPassword
    );
  };

  return (
    <AccountBackground>
      <AccountContainer>
        <Spacer size="heading" position="bottom">
          <Text variant="accountHeading">Create New Password</Text>
        </Spacer>
        <AuthFields>
          <AuthInputBorder>
            <AuthInput
              value={password}
              placeholder="Password"
              autoComplete="password"
              textContentType="password"
              autoCapitalize="none"
              secureTextEntry={true}
              returnKeyType="next"
              onChangeText={setPassword}
              onSubmitEditing={() => {
                confirmPasswordField.current.focus();
              }}
            />
            <AuthInput
              ref={confirmPasswordField}
              value={confirmPassword}
              placeholder="Confirm Password"
              autoComplete="password"
              textContentType="password"
              autoCapitalize="none"
              secureTextEntry={true}
              returnKeyType="go"
              onChangeText={setConfirmPassword}
              onSubmitEditing={() => onResetPassword(password)}
            />
          </AuthInputBorder>
        </AuthFields>
        {errorMessage && (
          <ErrorContainer>
            <ErrorText>{errorMessage}</ErrorText>
          </ErrorContainer>
        )}
        <Spacer size="large">
          {!isLoading ? (
            <AccountButton
              title={'Let Me In'}
              isDisabled={!isSubmittable()}
              onPress={() => onResetPassword(password, confirmPassword, passwordResetToken)}
            />
          ) : (
            <ActivityIndicator animating={true} color={colors.twoWeeks.green} />
          )}
        </Spacer>
      </AccountContainer>
    </AccountBackground>
  );
};
