import styled from 'styled-components/native';

export const LogoutText = styled.Text`
  color: ${(props) => props.theme.colors.twoWeeks.green};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  padding-horizontal: 8px;
  padding-vertical: 2px;
  border-radius: 7px;
`;

export const BarButton = styled.Pressable`
  padding-vertical: 6px;
`;

export const TitleContainer = styled.View`
  flex-direction: row;
`;

export const LabelText = styled.Text`
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
  padding-horizontal: 16px;
  padding-bottom: 8px;
`;