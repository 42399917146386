import styled from 'styled-components/native';

export const Pressable = styled.Pressable``;

export const LinkText = styled.Text`
  margin-top: 0px;
  height: 24px;
  width: 100%;
  text-align: right;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: right;
`;
// font-family: "SF Pro Display";
