import camelize from 'camelize';
import { formattedShortDateTime } from 'utils/Dates';
import { apiSecureGet, apiSecurePost } from 'api/ApiService';
import { parseJSON } from 'date-fns';

export const getReportNotes = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const reportId = queryKey[3];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/reports/${reportId}/notes`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return reportNotesTransform(data);
};

export const createReportNote = async ({ clubId, reportId, note }) => {
  const response = await apiSecurePost(`/v1/clubs/${clubId}/reports/${reportId}/notes`, {
    note: {
      note
    }
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return reportNoteTransform(data);
};

const transform = (result = {}) => {
  const createdAt = parseJSON(result.created_at);
  const updatedAt = parseJSON(result.updated_at);
  result.createdAtShort = formattedShortDateTime(createdAt);
  result.updatedAtShort = formattedShortDateTime(updatedAt);
  return camelize(result);
};

export const reportNoteTransform = (result = {}) => {
  return transform(result.data);
};

export const reportNotesTransform = (results = {}) => {
  return results.data.map((result) => transform(result));
};
