import styled from 'styled-components/native';
import Svg, { Circle } from 'react-native-svg';

export const Container = styled.View`
  width: 100%;
  padding: 16px;
`;

export const Switcher = styled.View`
  background-color: ${(props) => props.theme.colors.pure.white};
  border-radius: 8.91px;
  width: 100%;
  padding: 2px;
  flex-direction: row;
`;

export const BubbleContainer = styled.View`
  background-color: ${(props) =>
    props.mode === 'active'
      ? props.theme.colors.twoWeeks.green
      : props.theme.colors.pure.white};
  flex: 1;
  padding-vertical: 4px;
  border-radius: 7px;
  justify-content: center;
`;

export const Bubble = styled.Text`
  color: ${(props) =>
    props.mode === 'active'
      ? props.theme.colors.pure.white
      : props.theme.colors.selectedGrey};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  paddingHorizontal: 4px;
`;
// font-family: "SF Pro Display";

export const PressableBubble = styled.Pressable`
  width: 100%;
`;

export const NotificationContainer = styled.View`
  position: absolute;
  top: -8px;
  right: 0;
`;