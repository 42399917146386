import camelize from 'camelize';
import { parseDateTime, formattedShortDateTime } from 'utils/Dates';
import { apiSecureGet, apiSecurePost, apiSecurePut } from 'api/ApiService';

export const getImportPlayers = async ({ clubId, page, perPage }) => {
  const response = await apiSecureGet(`/v1/clubs/${clubId}/players/import?page=${page}&per_page=${perPage}`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return importPlayersTransform(data);
};

export const getCompletedImportPlayers = async ({ clubId, page, perPage }) => {
  const response = await apiSecureGet(`/v1/clubs/${clubId}/players/import?page=${page}&per_page=${perPage}&q[status_eq]='complete'`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return importPlayersTransform(data);
};

export const getImportPlayer = async ({ queryKey }) => {
  const clubId = queryKey[1]
  const importPlayerId = queryKey[3]
  const response = await apiSecureGet(`/v1/clubs/${clubId}/players/import/${importPlayerId}`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json()
  return importPlayerTransform(data)
};

export const createImportPlayer = async ({ clubId, importFile }) => {
  var formData = new FormData();
  formData.append("import_player[file]", importFile);
  const response = await apiSecurePost(`/v1/clubs/${clubId}/players/import`, formData);
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return importPlayerTransform(data);
};

export const updateImportPlayer = async (import_player) => {
  const response = await apiSecurePut(`/v1/clubs/${import_player.club_id}/players/import/${import_player.id}`, {
    import_player
  });
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return importPlayerTransform(data);
};

const transform = (result = {}) => {
  if (result?.imported_at) {
    result.importedAtShort = formattedShortDateTime(parseDateTime(result.imported_at));
  }
  result.createdAtShort = formattedShortDateTime(parseDateTime(result.created_at));
  result.updatedAtShort = formattedShortDateTime(parseDateTime(result.updated_at));
  return camelize(result);
};

export const importPlayerTransform = (result = {}) => {
  return transform(result.data);
};

export const importPlayersTransform = (results = {}) => {
  return results.data.map((result) => transform(result));
};
