import camelize from 'camelize';
import { apiPost } from './ApiService';

export const createCheckAccount = async ({ email }) => {
  const response = await apiPost(`/v1/check_accounts`, {
    check_account: {
      email
    }
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return camelize(data);
};
