import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { format, parseISO } from 'date-fns';

import {
  Container,
  HeaderContainer,
  HeaderText,
  RequiredText,
  LabelText,
  InputContainer,
  DatePicker,
  Pressable,
  DateText,
  WebInputSpacing,
} from './styles'
import { colors } from 'theme/colors';

const formattedWebDate = (date) => {
  if (date != '') {
    return format(date, 'yyyy-MM-dd');
  }
  return '';
};

const formattedAndroidDate = (date) => {
  return format(date, 'd MMM yyyy');
};

export const FormFieldTextDate = ({
  label,
  value,
  required,
  isDisabled,
  placeholder,
  keyboardType,
  autoCapitalize,
  onUpdated,
  onBlur,
}) => {
  const [initialValue, setInitialValue] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  useEffect(() => {
    if (value == '') return;
    const parsedDate = value;
    if (parsedDate) {
      switch (Platform.OS) {
        case 'android':
          setInitialValue(new Date(parsedDate));
          break;
        case 'ios':
          setInitialValue(new Date(parsedDate));
          break;
        case 'web':
          setInitialValue(parsedDate);
          break;
      }
    }
  }, [value]);

  return (
    <Container>
      {label && (
        <HeaderContainer>
          <HeaderText>{label}</HeaderText>
          {label && required && <RequiredText>(Required)</RequiredText>}
        </HeaderContainer>
      )}
      <InputContainer style={{ backgroundColor: (isDisabled ? colors.ui.disabled : colors.pure.white) }}>
        {Platform.OS === 'web' && (
          <input
            style={{
              fontSize: 15,
              border: 0,
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
              backgroundColor: 'transparent',
              paddingLeft: 16,
              paddingRight: 16,
              paddingBottom: 5,
              height: 56,
            }}
            type="date"
            value={initialValue}
            onBlur={(event) => {
              if (onBlur) {
                onBlur(event.target.value)
              }
            }}
            onChange={(event) => {
              setInitialValue(event.target.value);
              if (onUpdated) {
                onUpdated(event.target.value);
              }
            }}
          />
        )}
        {Platform.OS === 'ios' && (
          <DatePicker
            testID="dateTimePicker"
            value={initialValue || new Date()}
            onChange={(event, iosValue) => {
              if (onUpdated) {
                onUpdated(formattedWebDate(iosValue));
              }
              if (onBlur) {
                onBlur(formattedWebDate(iosValue))
              }
            }}
            style={{ margin: 10 }}
            locale="en-AU"
          />
        )}
        {initialValue && Platform.OS === 'android' && (
          <Pressable onPress={() => setShowDatePicker(true)}>
            <DateText>
              {initialValue && formattedAndroidDate(initialValue)}
            </DateText>
          </Pressable>
        )}
        {showDatePicker && (
          <DatePicker
            testID="dateTimePicker"
            value={initialValue}
            onChange={(event, selectedDate) => {
              setShowDatePicker(false);
              if (onUpdated) {
                onUpdated(formattedWebDate(selectedDate));
              }
              if (onBlur) {
                onBlur(formattedWebDate(selectedDate))
              }
            }}
          />
        )}
      </InputContainer>
    </Container>
  );
};
