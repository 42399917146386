import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getLeagueFields } from 'api/LeagueFieldsApi';

export const useGetLeagueFields = ({ leagueId }) => {
  return useQuery({
    queryKey: queryKey({ leagueId }),
    queryFn: getLeagueFields,
    // refetchInterval: 1000 * 60, // every minute
    enabled: !!leagueId
  });
};

export const refreshTrainers = ({ queryClient, leagueId }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey({ leagueId })
  });
};

const queryKey = ({ leagueId }) => {
    return ['leagues', leagueId, 'leagueFields'];
}
