import styled from 'styled-components/native';
import { colors } from '../../theme/colors';

export const AuthInput = styled.TextInput.attrs({
  placeholderTextColor: colors.selectedGrey
})`
  height: 40px;
  font-size: 15px;
  letter-spacing: 0px;
  padding-horizontal: 12px;
  margin: 4px;
`;
