import React from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';

import { AppTabNavigator } from './AppTabNavigator';
import { AppDrawerNavigator } from './AppDrawerNavigator';

import { ProfileContextProvider } from 'contexts/ProfileContext';
import { ReportTemplatesContextProvider } from 'contexts/ReportTemplatesContext';

import { ChangeClubScreen } from 'screens/ChangeClubScreen';
import { ChangeTeamScreen } from 'screens/ChangeTeamScreen';

const AppStack = createStackNavigator();

const createScreenOptions = () => {
  return {
    presentation: 'modal',
    headerShown: false,
  };
};

import styled from 'styled-components/native';

export const BarButton = styled.Pressable`
  padding-top: 6px;
  padding-right: 8px;
  padding-bottom: 4px;
`;

import PlusPNG from '../../assets/icons/plus-orange.png';
import { TrainersNavigator } from './TrainersNavigator';
export const PlusIcon = styled.Image.attrs({
  source: PlusPNG,
})`
  width: 24px;
  height: 24px;
`;

const isWeb = Platform.OS === 'web';

export const AppNavigator = () => {
  return (
    <ProfileContextProvider>
      <ReportTemplatesContextProvider>
        <AppStack.Navigator screenOptions={createScreenOptions}>
          <AppStack.Screen
            name="HomeNavigation"
            component={isWeb ? AppDrawerNavigator : AppTabNavigator}
            options={{ headerShown: false }}
          />
          <AppStack.Screen
            name="ChangeClubScreen"
            component={ChangeClubScreen}
          />
          <AppStack.Screen
            name="ChangeTeamScreen"
            component={ChangeTeamScreen}
          />
          <AppStack.Screen
            name="TrainersModal"
            component={TrainersNavigator}
            options={{
              headerRight: () => (
                <BarButton onPress={() => navigation.navigate("TrainerAddScreen")}>
                  <PlusIcon />
                </BarButton>
              ),
            }}
          />

        </AppStack.Navigator>
      </ReportTemplatesContextProvider>
    </ProfileContextProvider>
  );
};
