import styled, { css } from 'styled-components/native';

export const Container = styled.View`
  padding-horizontal: 16px;
  padding-vertical: 8px;
`;

export const Background = styled.View`
  width: 100%;
  ${(props) =>
    props.type === 'me' ?
      css`
      border-width: 1px;
      border-color: ${(p) => p.theme.colors.border};
      ` :
      css`
      border-width: 1px;
      border-color: ${(p) => p.theme.colors.border};
    `}
  border-radius: 12px;
  background-color: ${(props) =>
    props.type === 'me'
      ? props.theme.colors.card.mine
      : props.theme.colors.pure.white};
  padding-vertical: 16px;
  padding-horizontal: 32.5px;
`;

export const Row = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Author = styled.Text.attrs({
  selectable: true
})`
color: ${(props) => props.theme.colors.pure.black};
font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

export const Date = styled.Text.attrs({
  selectable: true
})`
  color: ${(props) => props.theme.colors.selectedGrey};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
`;
// font-family: "SF Pro Display";

export const Text = styled.Text.attrs({
  selectable: true
})`
  width: 100%;
  color: ${(props) => props.theme.colors.pure.black};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";