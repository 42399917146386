import React from 'react';

import { Text } from 'components/Text';
import {
  BlackBackground,
  SafeArea,
  BlueBackground,
  Content,
  Header,
  IconImage,
  Footer
} from './styles'

export const AccountBackground = ({
  children,
  includeHeader = true,
  includeFooter = true,
}) => {
  return (
    <BlackBackground>
      <SafeArea>
        <BlueBackground>
          {includeHeader && (
            <Header>
              <IconImage
                source={require('../../../assets/logos/primary_white.png')}
              />
            </Header>
          )}
          <Content>{children}</Content>
          {includeHeader && (
            <Footer>
              <Text variant="trademark">
                © 2022-2024 Simpler Software Pty Ltd. All rights reserved
              </Text>
            </Footer>
          )}
        </BlueBackground>
      </SafeArea>
    </BlackBackground>
  );
};
