import React from 'react';

import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';

import { PlayersScreen } from 'screens/PlayersScreen';
import { PlayerCreateScreen } from 'screens/PlayerCreateScreen';
import { PlayerUpdateScreen } from 'screens/PlayerUpdateScreen';
import { ImportPlayerScreen } from 'screens/ImportPlayerScreen';
import { ImportPlayersScreen } from 'screens/ImportPlayersScreen';
import { ImportPlayerUploadScreen } from 'screens/ImportPlayerUploadScreen';

const PlayersStack = createStackNavigator();

const createScreenOptions = () => {
  return {
    headerShown: false,
    ...TransitionPresets.DefaultTransition,
  };
};

export const PlayersNavigator = () => {
  return (
    <PlayersStack.Navigator screenOptions={createScreenOptions}>
      <PlayersStack.Screen
        name="PlayersScreen"
        component={PlayersScreen}
        options={{ title: 'Players - Headfirst' }}
      />
      <PlayersStack.Screen
        name="PlayerCreateScreen"
        component={PlayerCreateScreen}
        options={{ title: 'Add a Player - Headfirst' }}
      />
      <PlayersStack.Screen
        name="PlayerUpdateScreen"
        component={PlayerUpdateScreen}
        options={{ title: 'Update Player - Headfirst' }}
      />
      <PlayersStack.Screen
        name="ImportPlayerScreen"
        component={ImportPlayerScreen}
        options={{ title: 'Import Players - Headfirst' }}
      />
      <PlayersStack.Screen
        name="ImportPlayersScreen"
        component={ImportPlayersScreen}
        options={{ title: 'Import Players - Headfirst' }}
      />
      <PlayersStack.Screen
        name="ImportPlayerUploadScreen"
        component={ImportPlayerUploadScreen}
        options={{ title: 'Upload a players file - Headfirst' }}
      />
    </PlayersStack.Navigator>
  );
};
