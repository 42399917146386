import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  margin-vertical: 8px;
`;

export const LabelText = styled.Text`
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
  padding-horizontal: 16px;
  padding-bottom: 8px;
`;
// font-family: "SF Pro Display";

export const LabelWarningText = styled.Text`
  color: ${(props) => props.theme.colors.error.red};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
  padding-horizontal: 16px;
  padding-bottom: 8px;
`;
// font-family: "SF Pro Display";

export const InputContainer = styled.View`
  height: 120px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.pure.white};
  border-top-color: ${(props) => props.theme.colors.border};
  border-top-width: 0.5px;
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
`;

export const Input = styled.TextInput.attrs({
  multiline: true,
  // autoComplete: "username",
  // keyboardType: "email-address",
  // textContentType: "username",
  // autoCapitalize: "none",
  // returnKeyType: "next",
  // autoFocus: true,
})`
  flex: 1;
  width: 100%;
  color: ${(props) => props.theme.colors.pure.black};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";