import { useQuery } from '@tanstack/react-query';
import { getMe2 } from 'api/ProfileApi';

export const useGetMe = () => {
  return useQuery({
    queryKey: queryKey(),
    queryFn: getMe2,
    refetchInterval: 1000 * 60, // every minute
  });
};

export const refreshMe = ({ queryClient }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey()
  });
};

const queryKey = () => {
  return ['me'];
}