import React, { useContext, useLayoutEffect, useMemo, useState, } from 'react';

import { AvoidingView } from 'components/AvoidingView';
import { Background } from 'components/Background';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { Centered } from 'components/Centered';
import { FormTableSelect } from 'components/FormTableSelect';
import { FormFieldText } from 'components/FormFieldText';
import { VerticalScroll } from 'components/VerticalScroll';
import {
  Description,
  OtherContainer
} from './styles';

import { ProfileContext } from 'contexts/ProfileContext';
import { useGetTeams } from 'hooks/useTeam';
import { useGetPlayer } from 'hooks/usePlayer';

export const TeamSelectionScreen = ({ navigation, route }) => {
  const [search, setSearch] = useState('');
  const [title, setTitle] = useState('Select');
  const [header, setHeader] = useState('Select');
  const [description, setDescription] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [player, setPlayer] = useState(null);

  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => currentClub?.clubId, [currentClub]);

  const { data: teams } = useGetTeams({ clubId });
  const { data: playerRecord } = useGetPlayer({ clubId, playerId: player?.id });

  useLayoutEffect(() => {
    if (route.params?.title) {
      setTitle(route.params?.title);
    }
    if (route.params?.header) {
      setHeader(route.params?.header);
    }
    if (route.params?.description) {
      setDescription(route.params?.description);
    }
    if (route.params?.player) {
      setPlayer(route.params?.player);
    }
    if (route.params?.selectedItems) {
      setSelectedItems(route.params?.selectedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params]);

  const filteredTeams = useMemo(() => {
    if (!teams) return [];

    var t = teams;
    if (!!search) {
      t = t.filter(team => (team.name).toLowerCase().includes(search.toLowerCase()))
    }
    t = [
      { id: null, name: "No team", season: "None" },
      ...t
    ]
    return t;
  }, [currentClub, teams, search]);

  const playersTeam = (team) => {
    if (!player) return '';

    if (playerRecord?.teamPlayers?.map((teamPlayer) => teamPlayer.team.id).includes(team.id)) {
      return "(" + player?.name + "'s team)"
    }
    return '';
  }

  const formattedTeams = useMemo(() => {
    return filteredTeams.map((team) => {
      if (team.id) {
        return {
          key: team.id,
          value: `${team.name} (${team.season}) ${playersTeam(team)}`,
        }
      } else {
        return {
          key: team.id,
          value: `${team.name}`,
        }
      }
    })
  }, [filteredTeams, playerRecord]);

  const onSave = (item) => {
    navigation.navigate('CreateReportScreen', {
      items: item,
    });
  };

  return (
    <>
      <Background>
        {search ? (
          <BarHeaderSmall
            title={title}
            leftButtonTitle="Cancel"
            onLeftPress={() => {
              navigation.navigate('CreateReportScreen', {});
            }}
          />
        ) : (
          <BarHeaderSmall
            title={title}
            leftButtonTitle="Cancel"
            onLeftPress={() => {
              navigation.navigate('CreateReportScreen', {});
            }}
          />
        )}
        <AvoidingView>
          <Centered>
            <OtherContainer>
              <FormFieldText
                label="Team Search"
                placeholder="Search"
                value={search}
                onChangeText={(text) => {
                  setSearch(text);
                }}
                autoFocus={true}
              />
            </OtherContainer>
          </Centered>
          <VerticalScroll>
            <Centered>
              <FormTableSelect
                label={header}
                values={formattedTeams}
                initialKeys={[selectedItems.id]}
                multiselect={false}
                onSelected={(selected) => {
                  const id = selected[0];
                  const item = filteredTeams.find((i) => i.id === id);
                  setSelectedItems(item.name);
                  setTimeout(() => {
                    onSave(item);
                  }, 50);
                }}
              />
              <Description>{description}</Description>
            </Centered>
          </VerticalScroll>
        </AvoidingView>
      </Background>
    </>
  );
};
