import styled from 'styled-components/native';

export const CardInformationRow = styled.View`
  flex-direction: row;
`;

export const Container = styled.View`
  background-color: white;
  flex: 1;
`;

export const RequiredText = styled.Text`
  color: red;
`;

export const StatusText = styled.Text`
  text-align: center;
`;