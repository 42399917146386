import styled from 'styled-components/native';
import { DataTable } from 'react-native-paper';

export const Container = styled.View``;

export const DataTableView = styled(DataTable)`
  background-color: white;
`;

export const DataTableTitleCenter = styled(DataTable.Title).attrs({
  justifyContent: 'center',
})``;

export const DataTableCellCenter = styled(DataTable.Cell).attrs({
  justifyContent: 'center',
})``;

export const DataTableCellTitleCase = styled(DataTable.Cell)`
  text-transform: capitalize;
`;

// import PlusPNG from '../../../../assets/icons/add-full_blue.png';
import PlusPNG from '../../../assets/icons/plus-orange.png';
export const PlusIcon = styled.Image.attrs({
  source: PlusPNG,
})`
  width: 24px;
  height: 24px;
`;

export const RefreshText = styled.Text`
  color: ${(props) => props.theme.colors.twoWeeks.green};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  padding-horizontal: 8px;
  padding-vertical: 2px;
  border-radius: 7px;
  margin-horizontal: 8px;
`;

export const BarButton = styled.Pressable`
  padding-top: 6px;
  padding-left: 8px;
  padding-bottom: 4px;
`;

export const CellText = styled.Text`
  font-size: 16,
  font-weight: 500,
`;
