import styled from 'styled-components/native';

export const Container = styled.View`
  align-items: center;
  zIndex: -10;
`;

export const CenteredView = styled.View`
  min-width: 320px;
  max-width: 480px;
  width: 100%;
`;
