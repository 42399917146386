import React from 'react';

import {
  Container,
  LabelText,
  LabelWarningText,
  InputContainer,
  Input,
} from './styles'

export const FormFieldTextMulti = ({
  label,
  value,
  placeholder,
  onChangeText,
  isWarning = false,
}) => {
  return (
    <Container>
      {isWarning ? (
        <LabelWarningText>{label}</LabelWarningText>
      ) : (
        <LabelText>{label}</LabelText>
      )}
      <InputContainer>
        <Input
          value={value}
          placeholder={placeholder}
          onChangeText={onChangeText}
          editable={typeof onChangeText === 'function'}
          style={{
            paddingHorizontal: 16,
            paddingVertical: 8,
          }}
        />
      </InputContainer>
    </Container>
  );
};
