import styled from 'styled-components/native';
import { DataTable } from 'react-native-paper';

export const CardInformationRow = styled.View`
  flex-direction: row;
`;

export const Container = styled.View`
  background-color: white;
  flex: 1;
`;

export const RequiredText = styled.Text`
  color: red;
`;
