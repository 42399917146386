import React from 'react';

import { SettingsScreen } from 'screens/SettingsScreen';
import { CameraScreen } from 'screens/CameraScreen';

import {
  createStackNavigator,
  CardStyleInterpolators,
} from '@react-navigation/stack';

const SettingsStack = createStackNavigator();

export const SettingsNavigator = ({ route, navigation }) => {
  return (
    <SettingsStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
        headerMode: 'screen',
        headerBackTitle: 'Back'
      }}
    >
      <SettingsStack.Screen
        name="SettingsScreen"
        component={SettingsScreen}
        options={{ header: () => null, title: 'Settings - Headfirst' }}
        />
      <SettingsStack.Screen
        name="CameraScreen"
        component={CameraScreen}
        options={{ header: () => null, title: 'Camera - Headfirst' }}
      />
    </SettingsStack.Navigator>
  );
};
