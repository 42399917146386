import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Text, TouchableOpacity, View } from 'react-native';
import { DataTable } from 'react-native-paper';

import { BarHeaderLarge } from 'components/BarHeaderLarge';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { BarMenu } from 'components/BarMenu';
import { DropDownList } from 'components/DropDownList';
import { Loading } from 'components/Loading';
import { SearchTextInput } from 'components/SearchTextInput';
import { sizeClasses, SizeClassView } from 'components/SizeClassView';
import {
  Container,
  BarButton,
  SmallBarButton,
  DataTableView,
  TrainersList,
  RefreshControl,
  PlusIcon,
  IconText
} from './styles';

import { ProfileContext } from 'contexts/ProfileContext';
import { useQueryClient } from '@tanstack/react-query';
import { refreshTrainers, useGetTrainers } from 'hooks/useTrainer';

import { titleCase } from 'utils/Strings';
import { colors } from 'theme/colors';

export const TrainersScreen = ({ navigation, route }) => {
  const queryClient = useQueryClient()
  const { currentClub, currentTeam } = useContext(ProfileContext);
  const clubId = useMemo(() => currentClub?.clubId, [currentClub]);
  const { data: trainers, isLoading, isError, error } = useGetTrainers({ clubId });

  const [sortBy, setSortBy] = useState('email');
  const [sortDirection, setSortDirection] = useState('asc')

  const [trainerSearch, setTrainerSearch] = useState('');

  const [statusDropDownValue, setStatusDropDownValue] = useState('All Trainers');
  const statusItems = [
    { name: 'All Trainers', id: 'All Trainers' },
    { name: 'Active', id: 'active' },
    { name: 'Deactivated', id: 'deactivated' },
    { name: 'Whitelist', id: 'whitelist' },
  ];

  const [activeDropDown, setActiveDropDown] = useState('');

  useEffect(() => {
    if (!!isError) {
      Alert.alert('Something went wrong', error?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [isError]);

  const displayTeam = (item) => {
    if (item.teams.length === 0) return '';

    var teams = item.teams;
    return teams
      .map((team) => team.name)
      .join(', ')
  }

  const statusDisabled = (item) => {
    return item.status === 'disabled'
  }

  const displayStatus = (item) => {
    if (statusDisabled(item)) {
      return 'Deactivated';
    }
    return titleCase(item?.status);
  }

  const filteredTrainers = useMemo(() => {
    if (!trainers) return [];

    var records = trainers;
    records = records.map((record) => {
      var name = '';
      if (record?.user) {
        const firstName = record?.user?.profile?.firstName ?? '';
        const lastName = record?.user?.profile?.lastName ?? '';
        name = `${firstName} ${lastName}`;
      } else {
        const firstName = record?.invitationFirstName ?? '';
        const lastName = record?.invitationLastName ?? '';
        name = `${firstName} ${lastName}`
      }
      return {
        id: record?.id,
        email: (record?.invitationEmail || record?.user?.email) ?? '',
        name: name,
        teams: displayTeam(record),
        status: displayStatus(record)
      }
    })

    if (statusDropDownValue !== 'All Trainers') {
      records = records.filter(record => record.status?.toLowerCase() === statusDropDownValue)
    }
    if (trainerSearch) {
      records = records.filter(record => {
        const searchText = `${record.email} ${record.name} ${record.teams} ${record.status}`.toLowerCase()
        return searchText.includes(trainerSearch.toLowerCase())
      })
    }

    records = records.sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];
      const direction = sortDirection == 'asc' ? 1 : -1;
      return (aValue.toLowerCase() > bValue.toLowerCase()) ? direction : (aValue.toLowerCase() < bValue.toLowerCase()) ? -direction : 0;
    });
    return records;
  }, [trainers, statusDropDownValue, trainerSearch, sortBy, sortDirection]);

  const canManageTrainers = useMemo(() => {
    return currentClub?.role === 'club_admin';
  }, [currentClub])

  const navToAddTrainer = () => navigation.navigate("TrainerAddScreen");
  const navToUpdateTrainer = (trainerId) => navigation.navigate("TrainerUpdateScreen", { trainerId });

  const sortColumn = (field) => {
    if (field !== sortBy) return '';

    if (sortDirection == 'asc') {
      return 'ascending'
    } else {
      return 'descending'
    }
  }

  const directionArrow = (direction) => {
    switch (direction) {
      case 'ascending':
        return '↑';
      case 'descending':
        return '↓';
      default:
        return '';
    }
  }

  return (
    <>
      <SizeClassView size={sizeClasses.regular}>
        <BarHeaderLarge title="Trainers">
          {canManageTrainers && (
            <>
              <BarButton onPress={navToAddTrainer}>
                <PlusIcon />
              </BarButton>
            </>
          )}
        </BarHeaderLarge>
        <BarMenu>
          <View style={{ paddingHorizontal: 8 }}>
            <DropDownList
              placeholder="All Trainers"
              items={statusItems}
              isActive={activeDropDown == 'trainers'}
              onOpen={() => {
                setActiveDropDown('trainers');
              }}
              value={statusDropDownValue}
              // setValue={setStatusDropDownValue}
              onChangeValue={setStatusDropDownValue}
              containerStyle={{
                marginHorizontal: 4
              }}
            />
          </View>
          <View style={{ flex: 1 }}></View>
          <View style={{ width: '30%' }}>
            <SearchTextInput
              placeholder="Search"
              value={trainerSearch}
              onChangeText={(text) => {
                setTrainerSearch(text);
              }}
              autoFocus={true}
            />
          </View>
        </BarMenu>
      </SizeClassView>
      <SizeClassView size={sizeClasses.compact}>
        <BarHeaderSmall
          title="Trainers"
          leftButtonTitle="Close"
          onLeftPress={() => navigation.goBack()}
        >
          {canManageTrainers && (
            <>
              <SmallBarButton onPress={navToAddTrainer}>
                <PlusIcon />
              </SmallBarButton>
            </>
          )}
        </BarHeaderSmall>

        {/* <BarHeaderLarge title="Trainers">
        </BarHeaderLarge> */}

        <View style={{ paddingVertical: 8, paddingHorizontal: 2 }}>
          <SearchTextInput
            placeholder="Search"
            value={trainerSearch}
            onChangeText={(text) => {
              setTrainerSearch(text);
            }}
          />
        </View>

        <View style={{ paddingBottom: 8, paddingHorizontal: 8, width: '100%' }}>
          <View style={{ width: '50%' }}>
            <DropDownList
              placeholder="All Trainers"
              items={statusItems}
              isActive={activeDropDown == 'trainers'}
              onOpen={() => {
                setActiveDropDown('trainers');
              }}
              value={statusDropDownValue}
              // setValue={setStatusDropDownValue}
              onChangeValue={setStatusDropDownValue}
              containerStyle={{
                marginHorizontal: 4,
                borderWidth: 1,
                borderColor: colors.border,
                borderRadius: 8,
              }}
            />
          </View>
        </View>
      </SizeClassView>
      <Container style={{ zIndex: -10 }}>
        <DataTableView>
          <DataTable.Header>
            <DataTable.Title style={{ flex: 5 }}>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'name') {
                  setSortBy('name');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <Text style={{ color: sortBy == 'name' ? 'black' : null }}>Name</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'name') {
                  setSortBy('name');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <IconText>{directionArrow(sortColumn('name'))}</IconText>
              </TouchableOpacity>
            </DataTable.Title>
            <DataTable.Title style={{ flex: 7 }}>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'email') {
                  setSortBy('email');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <Text style={{ color: sortBy == 'email' ? 'black' : null }}>Email</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'email') {
                  setSortBy('email');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <IconText>{directionArrow(sortColumn('email'))}</IconText>
              </TouchableOpacity>
            </DataTable.Title>
            <SizeClassView size={sizeClasses.regular}>
              <DataTable.Title style={{ flex: 8 }}>
                <TouchableOpacity onPress={() => {
                  if (sortBy !== 'teams') {
                    setSortBy('teams');
                    setSortDirection('asc')
                  } else {
                    setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                  }
                }}>
                  <Text style={{ color: sortBy == 'teams' ? 'black' : null }}>Team</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                  if (sortBy !== 'teams') {
                    setSortBy('teams');
                    setSortDirection('asc')
                  } else {
                    setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                  }
                }}>
                  <IconText>{directionArrow(sortColumn('teams'))}</IconText>
                </TouchableOpacity>
              </DataTable.Title>
            </SizeClassView>
            <DataTable.Title style={{ flex: 2 }}>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'status') {
                  setSortBy('status');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <Text style={{ color: sortBy == 'status' ? 'black' : null }}>Status</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'status') {
                  setSortBy('status');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <IconText>{directionArrow(sortColumn('status'))}</IconText>
              </TouchableOpacity>
            </DataTable.Title>
          </DataTable.Header>
          <TrainersList
            data={filteredTrainers}
            renderItem={({ item }) => (
              <DataTable.Row
                key={item.id}
                onPress={() => {
                  if (canManageTrainers) {
                    navToUpdateTrainer(item.id);
                  }
                }}
                style={{
                  backgroundColor: statusDisabled(item) ? '#f7f7f7' : 'white',
                }}
              >
                <DataTable.Cell style={{ flex: 5, paddingRight: 8 }} textStyle={{ color: statusDisabled(item) ? '#a5a5a5' : 'black' }}>{item?.name}</DataTable.Cell>
                <DataTable.Cell style={{ flex: 7, paddingRight: 8 }} textStyle={{ color: statusDisabled(item) ? '#a5a5a5' : 'black' }}>{item?.email}</DataTable.Cell>
                <SizeClassView size={sizeClasses.regular}>
                  <DataTable.Cell style={{ flex: 8 }} textStyle={{ color: statusDisabled(item) ? '#a5a5a5' : 'black' }}>{item?.teams}</DataTable.Cell>
                </SizeClassView>
                <DataTable.Cell style={{ flex: 2 }} textStyle={{ color: statusDisabled(item) ? '#a5a5a5' : 'black' }}>{item?.status}</DataTable.Cell>
              </DataTable.Row>
            )}
            keyExtractor={(item) => item.id}
            refreshControl={
              <RefreshControl refreshing={isLoading} onRefresh={() => refreshTrainers({ queryClient })} />
            }
          />
        </DataTableView>
      </Container>
      <Loading isLoading={isLoading} />
    </>
  );
};
