import React from 'react';

import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { CardInformation } from 'components/CardInformation';
import { CardInformationRow, Container } from './styles';
import { Centered } from 'components/Centered';
import { Footer } from 'components/Footer';


export const ImportPage = ({ importPlayer, navigation }) => {

  const navBack = () => navigation.navigate('PlayersScreen');

  return (
    <>
      <BarHeaderSmall
        title={'Import Players - Importing'}
        leftButtonTitle="Back"
        onLeftPress={navBack}
      />
      {importPlayer && (
        <>
          <Container>
            <Centered>
              <CardInformationRow>
                <CardInformation title='Filename'>
                  {importPlayer?.file?.filename}
                </CardInformation>
              </CardInformationRow>
              <CardInformationRow>
                <CardInformation title='Progress'>
                  {"Queued"}
                </CardInformation>
              </CardInformationRow>
            </Centered>
          </Container>
          <Footer>
            <Centered>
            </Centered>
          </Footer>
        </>
      )}
    </>
  );
};
