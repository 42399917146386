import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Alert, FlatList, Keyboard, RefreshControl } from 'react-native';
import styled from 'styled-components/native';

import { ButtonBlue } from 'components/ButtonBlue';
import { CardNote } from 'components/CardNote';
import { Centered } from 'components/Centered';
import { FormFieldNote } from 'components/FormFieldNote';
import { Loading } from 'components/Loading';

import { ProfileContext } from 'contexts/ProfileContext';
import { useQueryClient } from '@tanstack/react-query';
import { refreshReportNotes, useCreateReportNote, useGetReportNotes } from 'hooks/useReportNote';

const NotesList = styled(FlatList).attrs({
  contentContainerStyle: {
    paddingTop: 0,
  },
})``;

export const ReportNotes = ({ clubId, reportId }) => {
  const queryClient = useQueryClient()

  const { profile } = useContext(ProfileContext);

  const { data: reportNotes, isLoading } = useGetReportNotes({ clubId, reportId });
  const { mutate: createReportNote, isPending, isSuccess, isError, error } = useCreateReportNote({ clubId, reportId });
  const [note, setNote] = useState('');

  useEffect(() => {
    if (isSuccess) {
      setNote('');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      Alert.alert('Something went wrong', error?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [isError]);

  return (
    <>
      <Centered>
        <FormFieldNote label={'New note'} value={note} onChangeText={setNote} />
        <ButtonBlue
          label={'Add'}
          onPress={() => {
            if (!!note) {
              createReportNote({ clubId, reportId, note });
              Keyboard.dismiss();
            }
          }}
          isDisabled={isPending}
        />
      </Centered>
      <NotesList
        data={reportNotes}
        renderItem={({ item }) => (
          <Centered>
            <CardNote
              date={item.createdAtShort}
              author={`${item?.user?.firstName || ''} ${item?.user?.lastName || ''}`}
              type={item.userId === profile.id ? 'me' : 'other'}
            >
              {item.note}
            </CardNote>
          </Centered>
        )}
        keyExtractor={(item) => item.id}
        refreshControl={
          <RefreshControl
            refreshing={isLoading}
            onRefresh={() => {
              refreshReportNotes({ queryClient, reportId });
            }}
          />
        }
      />
      <Loading isLoading={isLoading || isPending} />
    </>
  );
};
