import React, { useState, useEffect } from 'react';

import {
  Container,
  HeaderContainer,
  HeaderText,
  RequiredText,
  RowSelection,
  RowContainer,
  LabelText,
  ValueText,
  Extra,
  Icon,
  Switch,
} from './styles'

export const FormFieldToggle = ({
  label,
  header,
  required,
  initialValue,
  onSelected,
}) => {
  const [value, setValue] = useState(false);
  const valueText = (v) => {
    return v ? 'Yes' : 'No';
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Container>
      {!!header && (
        <HeaderContainer>
          <HeaderText>{header}</HeaderText>
          {required && <RequiredText>(Required)</RequiredText>}
        </HeaderContainer>
      )}
      <RowSelection>
        <RowContainer>
          <LabelText>{label}</LabelText>
          <ValueText>{valueText(value)}</ValueText>
          <Switch
            value={value}
            onValueChange={(v) => {
              setValue(v);
              onSelected(v);
            }}
          />
        </RowContainer>
      </RowSelection>
    </Container>
  );
};
