import React from 'react';

import {
  Container,
  Background,
  IconContainer,
  Icon,
  Name,
  PressableLink,
  Link
} from './styles';

export const BarChanger = ({ title, link, hasIcon, onPress }) => {
  return (
    <Container>
      <Background>
        <IconContainer>{!!hasIcon && <Icon />}</IconContainer>
        <Name>{title}</Name>
        <PressableLink onPress={onPress}>
          <Link>{link}</Link>
        </PressableLink>
      </Background>
    </Container>
  );
};
