import React from 'react';

import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';

import { TrainersScreen } from 'screens/TrainersScreen';
import { TrainerAddScreen } from 'screens/TrainerAddScreen';
import { TrainerUpdateScreen } from 'screens/TrainerUpdateScreen';

const TrainersStack = createStackNavigator();

const createScreenOptions = () => {
  return {
    headerShown: false,
    ...TransitionPresets.DefaultTransition,
  };
};

export const TrainersNavigator = () => {
  return (
    <TrainersStack.Navigator screenOptions={createScreenOptions}>
      <TrainersStack.Screen
        name="TrainersScreen"
        component={TrainersScreen}
        options={{ title: 'Trainers - Headfirst' }}
      />
      <TrainersStack.Screen
        name="TrainerAddScreen"
        component={TrainerAddScreen}
        options={{ title: 'Add a trainer - Headfirst' }}
      />
      <TrainersStack.Screen
        name="TrainerUpdateScreen"
        component={TrainerUpdateScreen}
        options={{ title: 'Update trainer - Headfirst' }}
      />
    </TrainersStack.Navigator>
  );
};
