import React from 'react';

import {
  Container,
  LabelText,
  InputContainer,
  Input,
} from './styles'

export const FormFieldNote = ({ label, value, placeholder, onChangeText }) => {
  return (
    <Container>
      {label && (
        <LabelText>{label}</LabelText>
      )}
      <InputContainer>
        <Input
          value={value}
          placeholder={placeholder}
          onChangeText={onChangeText}
        />
      </InputContainer>
    </Container>
  );
};
