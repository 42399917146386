import React, { useMemo } from 'react';

import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { ButtonBlue } from 'components/ButtonBlue';
import { CardInformation } from 'components/CardInformation';
import { CardInformationRow, Container } from './styles';
import { Centered } from 'components/Centered';
import { Footer } from 'components/Footer';
import { titleCase } from 'utils/Strings';


export const CompletePage = ({ importPlayer, navigation }) => {

  const canContinue = useMemo(() => {
    return true
  }, []);

  const navBack = () => navigation.navigate('PlayersScreen');

  return (
    <>
      <BarHeaderSmall
        title={'Import Players - Import Complete'}
        leftButtonTitle="Back"
        onLeftPress={navBack}
      />
      {importPlayer && (
        <>
          <Container>
            <Centered>
              <CardInformationRow>
                <CardInformation title='Filename'>
                  {importPlayer?.file?.filename}
                </CardInformation>
              </CardInformationRow>
              <CardInformationRow>
                <CardInformation title='Imported By'>
                  {importPlayer?.user?.name} ({importPlayer?.user?.email})
                </CardInformation>
              </CardInformationRow>
              <CardInformationRow>
                <CardInformation title='Imported On'>
                  {titleCase(importPlayer?.importedAtShort ?? "")}
                </CardInformation>
                <CardInformation title='Import Status'>
                  {titleCase(importPlayer?.status ?? "")}
                </CardInformation>
              </CardInformationRow>
            </Centered>
          </Container>
          <Footer>
            <Centered>
              <ButtonBlue
                label="Done"
                isDisabled={!canContinue}
                onPress={navBack}
              />
            </Centered>
          </Footer>
        </>
      )}
    </>
  );
};
