import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createClubChatMessage, getClubChatMessages } from 'api/ClubChatApi';

export const useGetClubChatMessages = ({ clubId, date }) => {
  return useQuery({
    queryKey: queryKey({ clubId, date }),
    queryFn: getClubChatMessages,
    refetchInterval: 1000 * 30, // 30 seconds
    enabled: !!clubId
  });
};

// Returns: { isLoading, data, hasNextPage, fetchNextPage }
export const useGetInfiniteClubChatMessages = ({ clubId, date }) => {
  return useInfiniteQuery({
    queryKey: queryKey({ clubId, date }),
    queryFn: getClubChatMessages,
    refetchInterval: 1000 * 5, // 5 seconds
    initialPageParam: 1,
    getPreviousPageParam: (lastPage, pages) => {
      return lastPage.meta.page.previous;
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.meta.page.next;
    },
    enabled: !!clubId
  });
};

export const useCreateClubChatMessage = ({ clubId, date }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createClubChatMessage,
    onSuccess: async (data) => {
      if (!clubId) return;

      refreshClubChatMessages({ queryClient, clubId, date });
    },
  })
};

export const refreshClubChatMessages = ({ queryClient, clubId, date }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey({ clubId, date })
  });
};

const queryKey = ({ clubId, date }) => {
  return ['clubs', clubId, 'chat', date, 'messages'];
}