import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ScrollView } from 'react-native';

import { CardInformationRow } from './styles';
import { ButtonWhite } from 'components/ButtonWhite';
import { Centered } from 'components/Centered';
import { CardInformation } from 'components/CardInformation';
import { Confirm } from 'components/Confirm';
import { FormFieldSelect } from 'components/FormFieldSelect';
import { FormFieldTextMulti } from 'components/FormFieldTextMulti';
import { Loading } from 'components/Loading';
import { Spacer } from 'components/Spacer';

import { ProfileContext } from 'contexts/ProfileContext';
import { useGetReport, useUpdateReport } from 'hooks/useReport';

export const ReportDetails = ({ clubId, reportId, navigation }) => {
  const { profile, currentClub } = useContext(ProfileContext);

  const { data: report, isLoading } = useGetReport({ clubId, reportId });
  const { mutate: updateReport, isPending: isUpdating } = useUpdateReport({ clubId, reportId });

  const [returnNonContact, setReturnNonContact] = useState('');
  const [returnContact, setReturnContact] = useState('');
  const [doctorCertificateValue, setDoctorCertificateValue] = useState('');
  const [reportStatusValue, setReportStatusValue] = useState('');
  const [concussionConfirmedValue, setConcussionConfirmedValue] = useState('');

  const isSubmittedByMe = useMemo(() => {
    return report?.fieldData?.submittedBy === `${profile?.profile?.firstName} ${profile?.profile?.lastName}`;
  }, [report, profile]);

  const isPendingAssessment = useMemo(() => {
    return report?.status === 'pending_assessment';
  }, [report]);

  const isUnderAssessment = useMemo(() => {
    return report?.status === 'under_assessment';
  }, [report]);

  const currentRole = useMemo(() => {
    return currentClub?.role;
  }, [currentClub]);

  const canEditReport = useMemo(() => {
    if (isPendingAssessment) {
      if (currentRole === 'trainer' && isSubmittedByMe) {
        return true;
      } else if (currentRole === 'club_admin') {
        return true;
      }
    }
    if (isUnderAssessment) {
      if (currentRole === 'club_admin') {
        return true;
      }
    }
    return false;
  }, [currentRole, isPendingAssessment, isSubmittedByMe]);

  const shouldUpdate = useMemo(() => {
    var shouldUpdate = false;
    if (reportStatusValue !== '' && reportStatusValue !== report?.status) { shouldUpdate = true }
    if (doctorCertificateValue !== '' && doctorCertificateValue !== report?.metaData?.doctorCertificate) { shouldUpdate = true }
    if (concussionConfirmedValue !== '' && concussionConfirmedValue !== report?.metaData?.concussionConfirmed) { shouldUpdate = true }
    if (returnNonContact !== report?.metaData?.returnToNonContact) { shouldUpdate = true }
    if (returnContact !== report?.metaData?.returnToContact) { shouldUpdate = true }
    return shouldUpdate;
  }, [report, reportStatusValue, doctorCertificateValue, concussionConfirmedValue, returnNonContact, returnContact]);

  const updateMetaData = ({ clubId, reportId, doctorCertificate, concussionConfirmed, returnNonContact, returnContact }) => {
    updateReport({
      clubId,
      reportId,
      report: {
        status: reportStatusValue,
        meta_data: {
          doctor_certificate: doctorCertificate,
          concussion_confirmed: concussionConfirmed,
          return_to_non_contact: returnNonContact,
          return_to_contact: returnContact,
        }
      }
    });
  }

  useEffect(() => {
    if (!!report) {
      setReportStatusValue(report?.status);
      setConcussionConfirmedValue(report?.metaData?.concussionConfirmed);
      setDoctorCertificateValue(report?.metaData?.doctorCertificate);
      setReturnNonContact(report?.metaData?.returnToNonContact);
      setReturnContact(report?.metaData?.returnToContact);

      if (!!currentRole && currentRole === 'club_admin' && report?.status == 'pending_assessment') {
        updateReport({
          clubId,
          reportId,
          report: { status: 'under_assessment' }
        })
      }
    }
  }, [report])

  React.useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        if (!shouldUpdate) return;

        e.preventDefault();

        Confirm('Discard changes?', `You have unsaved changes. Are you sure to discard them and leave the screen?`, () => {
          navigation.dispatch(e.data.action)
        }, () => { });
      }
      ), [navigation, shouldUpdate]);

  return (
    <>
      <ScrollView>
        <Centered>
          <CardInformationRow>
            <CardInformation title="Incident Date">
              {report?.fieldData?.incidentOnShort}
            </CardInformation>
            <CardInformation title="Status">{report?.statusShort}</CardInformation>
          </CardInformationRow>
          <CardInformationRow>
            <CardInformation title="Team">{report?.teamDetails?.teamName}</CardInformation>
            <CardInformation title="Grade">{report?.teamDetails?.gradeName}</CardInformation>
          </CardInformationRow>
          <Spacer size="medium" />
          <FormFieldSelect label="Player" values={[report?.fieldData?.player]} />
          {report?.fieldData?.activityType?.length > 0 && (
            <FormFieldSelect label="Type of activity" values={report?.fieldData?.activityType} />
          )}
          {report?.fieldData?.presentationReason?.length > 0 && (
            <FormFieldSelect label="Reason for presentation" values={report?.fieldData?.presentationReason} />
          )}
          <FormFieldSelect label="DRABCD" values={[report?.fieldData?.drabcd]} />
          <FormFieldSelect label="Suspected concussion" values={[report?.fieldData?.suspectedConcussion]} />
          {report?.fieldData?.injuries?.length > 0 && (
            <FormFieldSelect label="Injury" values={report?.fieldData?.injuries} />
          )}
          {report?.fieldData?.injuryCauses?.length > 0 && (
            <FormFieldSelect label="Cause of injury" values={report?.fieldData?.injuryCauses} />
          )}
          {report?.fieldData?.protectiveEquipment?.length > 0 && (
            <FormFieldSelect label="Protective equipment" values={report?.fieldData?.protectiveEquipment} />
          )}
          {report?.fieldData?.treatments?.length > 0 && (
            <FormFieldSelect label="Treatment" values={report?.fieldData?.treatments} />
          )}
          {report?.fieldData?.advicesGiven?.length > 0 && (
            <FormFieldSelect label="Advice given" values={report?.fieldData?.advicesGiven} />
          )}
          <FormFieldSelect label="Treatment assisted" values={[report?.fieldData?.treatmentsAssisted]} />
          {report?.fieldData?.referral?.length > 0 && (
            <FormFieldSelect label="Referral" values={report?.fieldData?.referral} />
          )}
          {report?.fieldData?.oppositionTeam?.length > 0 && (
            <FormFieldSelect label="Opposition team" values={report?.fieldData?.oppositionTeam} />
          )}
          <FormFieldSelect label="Venue" values={[report?.fieldData?.venue]} />
          <FormFieldTextMulti label="Additional comments" value={report?.fieldData?.comment} />
          {canEditReport && (
            <ButtonWhite
              label="Edit Report"
              onPress={() => {
                navigation.navigate('CreateReportScreen', { report: report });
              }}
            />
          )}
        </Centered>
      </ScrollView>
      <Loading isLoading={isLoading || isUpdating} />
    </>
  );
};
