import { Alert as ReactNativeAlert, Platform } from 'react-native';

export const Alert = (title, message = '') => {
  if (Platform.OS === 'web') {
    window.alert(`${title}: ${message.toString()}`);
  } else {
    ReactNativeAlert.alert(title, message.toString(), [
      { text: 'OK', onPress: null },
    ]);
  }
}
