import React from 'react';

import {
  Container,
  LabelText,
  PressableButton
} from './styles';

export const ButtonBlue = ({ label, isDisabled, onPress }) => {
  return (
    <PressableButton
      onPress={() => {
        if (!isDisabled) {
          onPress();
        }
      }}
      isDisabled={isDisabled}
    >
      <Container>
        <LabelText>{label}</LabelText>
      </Container>
    </PressableButton>
  );
};
