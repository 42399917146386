import styled from 'styled-components/native';

export const Container = styled.View`
  margin-top: 24px;
`;

export const Pressable = styled.Pressable``;

export const LinkText = styled.Text`
  padding-vertical: 8px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  `;
// font-family: "SF Pro Display";
