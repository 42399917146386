import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { AccountScreen } from 'screens/AccountScreen';
import { UserLoginScreen } from 'screens/UserLoginScreen';
import { UserRegisterationScreen } from 'screens/UserRegisterationScreen';
import { UserForgotPasswordScreen } from 'screens/UserForgotPasswordScreen';
import { CreatePasswordScreen } from 'screens/CreatePasswordScreen';

const Stack = createStackNavigator();

const createScreenOptions = () => {
  return {
    // presentation: "modal",
    headerShown: false,
  };
};

export const AccountNavigator = () => {
  return (
    <Stack.Navigator screenOptions={createScreenOptions}>
      <Stack.Screen
        name="Login"
        component={UserLoginScreen}
        options={{ title: 'Login - Headfirst' }}
      />
      <Stack.Screen
        name="ForgotPassword"
        component={UserForgotPasswordScreen}
        options={{ title: 'Reset Password - Headfirst' }}
        />
      <Stack.Screen
        name="CreatePassword"
        component={CreatePasswordScreen}
        options={{ title: 'Create New Password - Headfirst' }}
        />
      <Stack.Screen
        name="Activate"
        component={UserRegisterationScreen}
        options={{ title: 'Activate Account - Headfirst' }}
        />
      <Stack.Screen
        name="Main"
        component={AccountScreen}
        options={{ title: 'Main - Headfirst' }}
        />
    </Stack.Navigator>
  );
};
