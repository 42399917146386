import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Alert } from 'components/Alert';
import { AvoidingView } from 'components/AvoidingView';
import { Background } from 'components/Background';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { ButtonBlue } from 'components/ButtonBlue';
import { Centered } from 'components/Centered';
import { FormFieldDropDown } from 'components/FormFieldDropDown';
import { FormFieldText } from 'components/FormFieldText';
import { FormTableSelect } from 'components/FormTableSelect';
import { Loading } from 'components/Loading';
import { Spacer } from 'components/Spacer';
import { VerticalScroll } from 'components/VerticalScroll';

import { ProfileContext } from 'contexts/ProfileContext';
import { useQueryClient } from '@tanstack/react-query';
import { refreshMe } from 'hooks/useMe';
import { useGetLeagueAgeGroups } from 'hooks/useLeagueAgeGroup';
import { useGetPlayers } from 'hooks/usePlayer';
import { useCreateTeam } from 'hooks/useTeam';
import { useGetSeasons } from 'hooks/useSeason';

export const TeamAddScreen = ({ navigation, route }) => {
  const queryClient = useQueryClient()
  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => { return currentClub?.club?.id }, [currentClub]);
  const leagueId = useMemo(() => {
    if (currentClub) {
      return currentClub?.club.affiliations[0]?.leagueId;
    }
  }, [currentClub]);
  const { data: seasons } = useGetSeasons({ leagueId });
  const { mutate: createTeam, isPending, isSuccess, isError, error } = useCreateTeam({ clubId });
  const { data: players, isLoading: playersLoading } = useGetPlayers({ clubId });
  const { data: ageGroups, isLoading: ageGroupsLoading } = useGetLeagueAgeGroups({ leagueId });

  const ageGroupValues = useMemo(() => {
    if (!ageGroups?.data) return [];

    return [
      { id: 'Select an age group', name: 'Select an age group' },
      ...ageGroups.data.map((ageGroup) => { return { id: ageGroup.name, name: ageGroup.name }})
    ];
  }, [ageGroups]);

  const [name, setName] = useState('');
  const [ageGroup, setAgeGroup] = useState('');
  const [gradeName, setGradeName] = useState('');
  const [playerSearch, setPlayerSearch] = useState('');
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  const showLoading = useMemo(() => {
    return playersLoading || ageGroupsLoading
  }, [playersLoading, ageGroupsLoading])

  const filteredPlayers = useMemo(() => {
    if (!players) return []

    var p = [];
    if (!!playerSearch) {
      p = players
        .filter(player => (player.firstName + ' ' + player.lastName).toLowerCase().includes(playerSearch.toLowerCase()))
    } else {
      p = players
        .filter(player => selectedPlayers.includes(player.id))
    }
    p = p
      .map((player) => {
        return {
          id: player.id,
          name: player.firstName + ' ' + player.lastName,
          teamPlayers: player.teamPlayers,
        };
      })

    return p;
  }, [players, playerSearch, selectedPlayers]);

  useEffect(() => {
    if (seasons) {
      var today = new Date()
      var found = seasons.find((season) => {
        var startsOn = Date.parse(season.startsOn)
        var endsOn = Date.parse(season.endsOn)
        return today > startsOn && today < endsOn;
      })
      setSeason(found?.name ?? '')
    }
  }, [seasons]);
  const [season, setSeason] = useState('');

  const canSave = useMemo(() => {
    if (name !== '') {
      return true
    }
    return false;
  }, [name])

  const saveLabel = useMemo(() => {
    return isPending ? "Saving..." : "Save"
  }, [isPending, canSave])

  const save = () => {
    const club_team = {
      name,
      age_group: ageGroup,
      grade_name: gradeName,
      competition: 'N/A',
      season: season,
      player_ids: selectedPlayers
    }
    createTeam({ clubId, club_team });
  };

  useEffect(() => {
    if (isError) Alert('Something went wrong', error?.toString());
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      refreshMe({ queryClient });
      navigation.navigate('TeamsScreen');
    }
  }, [isSuccess]);

  return (
    <Background>
      <BarHeaderSmall
        title={'Add a team'}
        leftButtonTitle="Cancel"
        onLeftPress={() => {
          navigation.navigate('TeamsScreen');
        }}
      />
      <AvoidingView>
        <VerticalScroll>
          <Centered>
            <FormFieldText
              label="Name"
              value={name}
              onChangeText={setName}
              autoFocus={true}
            />
            <FormFieldDropDown
              label="Age Group"
              value={ageGroup}
              options={ageGroupValues}
              onChangeText={(value) => {
                if (value != 'Select an age group') {
                  setAgeGroup(value);
                } else {
                  setAgeGroup('');
                }
              }}
            />
            <FormFieldText
              label="Grade/Division"
              value={gradeName}
              onChangeText={setGradeName}
            />
            <FormFieldText
              label="Player Search"
              placeholder="Search"
              value={playerSearch}
              onChangeText={(text) => {
                setPlayerSearch(text);
              }}
              autoFocus={true}
            />
            <FormTableSelect
              label={!!playerSearch ? 'Selecting players' : "Team's Players"}
              values={filteredPlayers.map((player) => ({
                key: player.id,
                value: player.name,
              }))}
              initialKeys={selectedPlayers}
              multiselect={true}
              onSelected={(selected) => {
                setSelectedPlayers(selected)
              }}
            />
            <ButtonBlue
              label={saveLabel}
              isDisabled={!canSave}
              onPress={save}
            />
            <Spacer type="bottom" size="large" />
          </Centered>
        </VerticalScroll>
        <Loading isLoading={showLoading} />
      </AvoidingView>
    </Background>
  );
};
