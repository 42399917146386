import styled from 'styled-components/native';

export const Container = styled.View`
  flex: 1;
  padding-horizontal: 16px;
  padding-vertical: 8px;
`;

export const Subheading = styled.Text`
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  margin-bottom: 2px;
  text-transform: uppercase;
`;
// font-family: "SF Pro Display";

export const Text = styled.Text`
  color: #728596;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";