import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Alert, FlatList } from 'react-native';

import { Background } from 'components/Background';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { Loading } from 'components/Loading';

import { ProfileContext } from 'contexts/ProfileContext';
import { useGetImportPlayers } from 'hooks/useImportPlayer';

import { DataTable } from 'react-native-paper';
import {
  CellText,
  DataTableView,
  DataTableTitleCenter,
  DataTableCellCenter,
  DataTableCellTitleCase,
} from './styles';

import { titleCase } from 'utils/Strings';

const numberOfItemsPerPageList = [2, 3, 4];

const items = [
  {
    key: 1,
    name: 'Page 1',
  },
  {
    key: 2,
    name: 'Page 2',
  },
  {
    key: 3,
    name: 'Page 3',
  },
];

export const ImportPlayersScreen = ({ navigation, route }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [numberOfItemsPerPage, onItemsPerPageChange] = React.useState(numberOfItemsPerPageList[0]);

  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => currentClub?.clubId, [currentClub]);
  const { data: importPlayers, isFateching, isLoading, isError, error } = useGetImportPlayers({
    clubId, page, perPage,
  });

  useEffect(() => {
    if (isError) {
      Alert.alert('Something went wrong', error?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [isError]);

  useEffect(() => {
    if (!!importPlayers && !!perPage) {
      if (importPlayers.length < perPage) {
        if (page > 1 && importPlayers.length == 0) {
          setTotalPages(page - 1);
          setPage(page - 1);
        } else {
          setTotalPages(page);
        }
      }
    }
  }, [importPlayers, perPage])

  return (
    <Background>
      <BarHeaderSmall
        title={'Import Player History'}
        leftButtonTitle="Back"
        onLeftPress={() => {
          navigation.navigate('PlayersScreen', { updateTime: new Date().getTime() });
        }}
        rightButtonTitle={"Import"}
        onRightPress={() => {
          navigation.navigate('ImportPlayerUploadScreen');
        }}
      />
      <DataTableView>
        <DataTable.Header>
          <DataTable.Title style={{ flex: 2 }}>File Name</DataTable.Title>
          <DataTable.Title style={{ flex: 1 }}>Imported By</DataTable.Title>
          <DataTableTitleCenter style={{ flex: 1 }}>Imported On</DataTableTitleCenter>
          <DataTableTitleCenter style={{ flex: 1 }}>Status</DataTableTitleCenter>
        </DataTable.Header>
        <FlatList
          data={importPlayers}
          renderItem={({ item }) => (
            <DataTable.Row
              key={item?.id}
              onPress={() => { 
                navigation.navigate('ImportPlayerScreen', { importPlayerId: item.id }) 
              }}
              style={{ backgroundColor: item?.status === 'complete' ? '#f7f7f7' : 'white' }}
            >
              <DataTable.Cell style={{ flex: 2 }}>
                <CellText style={{ color: item?.status === 'complete' ? '#a5a5a5' : 'black' }}>
                  {item.file?.filename}
                </CellText>
              </DataTable.Cell>
              <DataTable.Cell style={{ flex: 1 }}>
                <CellText style={{ color: item?.status === 'complete' ? '#a5a5a5' : 'black' }}>
                  {item.user?.name}
                </CellText>
              </DataTable.Cell>
              <DataTableCellCenter style={{ flex: 1 }}>
                <CellText style={{ color: item?.status === 'complete' ? '#a5a5a5' : 'black' }}>
                  {item.importedAtShort}
                </CellText>
              </DataTableCellCenter>
              <DataTableCellCenter style={{ flex: 1 }}>
                <CellText style={{ color: item?.status === 'complete' ? '#a5a5a5' : 'black' }}>
                  {titleCase(item?.status || '')}
                </CellText>
              </DataTableCellCenter>
            </DataTable.Row>

          )}
          keyExtractor={(item) => item.id}
        // refreshControl={
        //   <RefreshControl
        //     refreshing={isLoading}
        //     onRefresh={() => {
        //       refreshPlayers();
        //     }}
        //   />
        // }
        />
      </DataTableView>
      <DataTable.Pagination
        page={page - 1}
        numberOfPages={totalPages}
        onPageChange={(p) => {
          if (p > -1) {
            if (!totalPages) {
              setPage(p + 1);
            } else if (p <= totalPages) {
              setPage(p + 1);
            }
          }
        }}
        label={`${page} of ${totalPages || '∞'}`}
        showFastPaginationControls
      />
      <Loading isLoading={isLoading} />
    </Background>
  );
};
