import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Text, TouchableOpacity, View } from 'react-native';
import { DataTable } from 'react-native-paper';

import { Background } from 'components/Background';
import { BarHeaderLarge } from 'components/BarHeaderLarge';
import { BarMenu } from 'components/BarMenu';
import { DropDownList } from 'components/DropDownList';
import { Loading } from 'components/Loading';
import { SearchTextInput } from 'components/SearchTextInput';
import { sizeClasses, SizeClassView } from 'components/SizeClassView';
import {
  Container,
  BarButton,
  DataTableView,
  TrainersList,
  RefreshControl,
  PlusIcon,
  TrainerContainer,
  TrainerItem,
  IconText
} from './styles';

import { ProfileContext } from 'contexts/ProfileContext';
import { useQueryClient } from '@tanstack/react-query';
import { refreshTeams, useGetTeams } from 'hooks/useTeam';

export const TeamsScreen = ({ navigation, route }) => {
  const queryClient = useQueryClient()
  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => currentClub?.clubId, [currentClub]);
  const { data: teams, isLoading, isError, error } = useGetTeams({ clubId });

  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc')

  const [teamSearch, setTeamSearch] = useState('');

  useEffect(() => {
    if (!!isError) {
      Alert.alert('Something went wrong', error?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [isError]);

  const statusDisabled = (item) => {
    return false;
    // return item.status === 'disabled'
  }

  const filteredTeams = useMemo(() => {
    if (!teams) return [];

    var records = teams;
    records = records.map((record) => {
      return {
        id: record?.id,
        name: record?.name,
        ageGroup: record?.ageGroup,
        gradeName: record?.gradeName,
        totalPlayers: record?.totalPlayers,
        trainers: record?.trainers?.sort((a, b) => {
          const aValue = `${a.firstName} ${a.lastName}`.toLowerCase();
          const bValue = `${b.firstName} ${b.lastName}`.toLowerCase();
          return (aValue > bValue) ? 1 : (aValue < bValue) ? -1 : 0;
        }),
      }
    })

    if (teamSearch) {
      records = records.filter(record => {
        const searchText = `${record.name} ${record.ageGroup} ${record.gradeName}`.toLowerCase()
        return searchText.includes(teamSearch.toLowerCase())
      })
    }

    records = records.sort((a, b) => {
      const aValue = a[sortBy] ?? '';
      const bValue = b[sortBy] ?? '';
      const direction = sortDirection == 'asc' ? 1 : -1;
      if (typeof aValue === 'string') {
        return (aValue.toLowerCase() > bValue.toLowerCase()) ? direction : (aValue.toLowerCase() < bValue.toLowerCase()) ? -direction : 0;
      } else if (typeof aValue === 'number') {
        return (aValue > bValue) ? direction : (aValue < bValue) ? -direction : 0;
      } else {
        return 0;
      }
    });
    return records;
  }, [teams, teamSearch, sortBy, sortDirection]);

  const canManageTeams = useMemo(() => {
    return currentClub?.role === 'club_admin';
  }, [currentClub])

  const navToAddTeam = () => navigation.navigate("TeamAddScreen");
  const navToUpdateTeam = (teamId) => navigation.navigate("TeamUpdateScreen", { teamId });

  const sortColumn = (field) => {
    if (field !== sortBy) return '';

    if (sortDirection == 'asc') {
      return 'ascending'
    } else {
      return 'descending'
    }
  }

  const directionArrow = (direction) => {
    switch (direction) {
      case 'ascending':
        return '↑';
      case 'descending':
        return '↓';
      default:
        return '';
    }
  }

  return (
    <Background>
      <BarHeaderLarge title="Teams">
        <SizeClassView size={sizeClasses.regular}>
          {canManageTeams && (
            <>
              <BarButton onPress={navToAddTeam}>
                <PlusIcon />
              </BarButton>
            </>
          )}
        </SizeClassView>
      </BarHeaderLarge>
      <BarMenu>
        <View style={{ flex: 2 }}></View>
        <View style={{ width: '30%' }}>
          <SearchTextInput
            placeholder="Search"
            value={teamSearch}
            onChangeText={(text) => {
              setTeamSearch(text);
            }}
            autoFocus={true}
          />
        </View>
      </BarMenu>
      <Container style={{ zIndex: -10 }}>
        <DataTableView>
          <DataTable.Header>
            <DataTable.Title style={{ flex: 2 }}>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'name') {
                  setSortBy('name');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <Text style={{ color: sortBy == 'name' ? 'black' : null }}>Name</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'name') {
                  setSortBy('name');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <IconText>{directionArrow(sortColumn('name'))}</IconText>
              </TouchableOpacity>
            </DataTable.Title>
            <DataTable.Title style={{ flex: 1 }}>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'ageGroup') {
                  setSortBy('ageGroup');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <Text style={{ color: sortBy == 'ageGroup' ? 'black' : null }}>Age Group</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'ageGroup') {
                  setSortBy('ageGroup');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <IconText>{directionArrow(sortColumn('ageGroup'))}</IconText>
              </TouchableOpacity>
            </DataTable.Title>
            <DataTable.Title style={{ flex: 2 }}>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'gradeName') {
                  setSortBy('gradeName');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <Text style={{ color: sortBy == 'gradeName' ? 'black' : null }}>Grade Name</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'gradeName') {
                  setSortBy('gradeName');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <IconText>{directionArrow(sortColumn('gradeName'))}</IconText>
              </TouchableOpacity>
            </DataTable.Title>
            <DataTable.Title style={{ flex: 1 }}>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'totalPlayers') {
                  setSortBy('totalPlayers');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <Text style={{ color: sortBy == 'totalPlayers' ? 'black' : null }}>Players</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'totalPlayers') {
                  setSortBy('totalPlayers');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <IconText>{directionArrow(sortColumn('totalPlayers'))}</IconText>
              </TouchableOpacity>
            </DataTable.Title>
            <DataTable.Title style={{ flex: 2 }}>
              <Text>Trainer/s</Text>
            </DataTable.Title>
          </DataTable.Header>
          <TrainersList
            data={filteredTeams}
            renderItem={({ item }) => (
              <DataTable.Row
                key={item.id}
                onPress={() => {
                  if (canManageTeams) {
                    navToUpdateTeam(item.id);
                  }
                }}
                style={{
                  backgroundColor: statusDisabled(item) ? '#f7f7f7' : 'white',
                }}
              >
                <DataTable.Cell style={{ flex: 2 }} textStyle={{ color: statusDisabled(item) ? '#a5a5a5' : 'black' }}>{item?.name}</DataTable.Cell>
                <DataTable.Cell style={{ flex: 1 }} textStyle={{ color: statusDisabled(item) ? '#a5a5a5' : 'black' }}>{item?.ageGroup}</DataTable.Cell>
                <DataTable.Cell style={{ flex: 2 }} textStyle={{ color: statusDisabled(item) ? '#a5a5a5' : 'black' }}>{item?.gradeName}</DataTable.Cell>
                <DataTable.Cell style={{ flex: 1 }} textStyle={{ color: statusDisabled(item) ? '#a5a5a5' : 'black' }}>{item?.totalPlayers}</DataTable.Cell>
                <DataTable.Cell style={{ flex: 2 }} textStyle={{ color: statusDisabled(item) ? '#a5a5a5' : 'black' }}>
                  <TrainerContainer>
                    {item?.trainers?.map((trainer) => (
                      <TrainerItem>
                        {trainer?.firstName} {trainer?.lastName}
                      </TrainerItem>
                    ))}
                  </TrainerContainer>
                </DataTable.Cell>
              </DataTable.Row>
            )}
            keyExtractor={(item) => item.id}
            refreshControl={
              <RefreshControl refreshing={isLoading} onRefresh={() => refreshTeams({ queryClient })} />
            }
          />
        </DataTableView>
      </Container>
      <Loading isLoading={isLoading} />
    </Background>
  );
};
