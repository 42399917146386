import camelize from 'camelize';
import { parseDateTime, formattedShortDate } from 'utils/Dates';
import { apiSecureGet } from 'api/ApiService';

export const getSeasons = async ({ queryKey }) => {
  const leagueId = queryKey[1];
  const response = await apiSecureGet(`/v1/leagues/${leagueId}/seasons`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return seasonsTransform(data);
};

const transform = (result = {}) => {
  result.createdAtShort = formattedShortDate(parseDateTime(result.created_at));
  result.updatedAtShort = formattedShortDate(parseDateTime(result.updated_at));
  return camelize(result);
};

export const seasonTransform = (result = {}) => {
  return transform(result.data);
};

export const seasonsTransform = (results = {}) => {
  return results.data.map((result) => transform(result));
};
