import { useQuery } from '@tanstack/react-query';
import { getRounds } from 'api/RoundsApi';

export const useGetRounds = ({ leagueId }) => {
  return useQuery({
    queryKey: queryKey({ leagueId }),
    queryFn: getRounds,
    refetchInterval: 1000 * 60 * 4, // every 4 hours
    enabled: !!leagueId
  });
};

export const refreshRounds = ({ queryClient, leagueId }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey({ leagueId })
  });
};

const queryKey = ({ leagueId }) => {
  return ['leagues', leagueId, 'rounds'];
}