import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from "expo-secure-store";

const TOKEN_KEY = "secureToken";
const isWeb = Platform.OS === 'web';

export const setAuthToken = async (authToken: string) => {
  if (!authToken) return;

  if (isWeb) {
    await AsyncStorage.setItem(TOKEN_KEY, authToken);
  } else {
    await SecureStore.setItemAsync(TOKEN_KEY, authToken)
  }
};

export const getAuthToken = async () => {
  try {
    if (isWeb) {
      const authToken = await AsyncStorage.getItem(TOKEN_KEY)
      return { authToken };
    } else {
      const authToken = await SecureStore.getItemAsync(TOKEN_KEY);
      return { authToken };
    }
  } catch (e) {
    return {};
  }

};

export const deleteAuthToken = async () => {
  if (isWeb) {
    await AsyncStorage.removeItem(TOKEN_KEY);
  } else {
    await SecureStore.deleteItemAsync(TOKEN_KEY);
  }
};
