import React from 'react';

import {
  Background,
} from './styles'

export const Footer = ({ children }) => {
  return (
    <Background>
      {children}
    </Background>
  );
};
