import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getReportNotes, createReportNote } from 'api/ReportNotesApi';

export const useGetReportNotes = ({ clubId, reportId }) => {
  return useQuery({
    queryKey: queryKey({ clubId, reportId }),
    queryFn: getReportNotes,
    refetchInterval: 1000 * 60, // every minute
    enabled: !!reportId
  });
};

export const useCreateReportNote = ({ clubId, reportId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createReportNote,
    onSuccess: async (data) => {
      if (!reportId) return;
      refreshReportNotes({ queryClient, clubId, reportId })
    },
  })
};

export const refreshReportNotes = ({ queryClient, clubId, reportId }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey({ clubId, reportId })
  });
};

const queryKey = ({ clubId, reportId }) => {
  return ['clubs', clubId, 'reports', reportId, 'notes'];
}
