import React from 'react';

import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';

import { TeamsScreen } from 'screens/TeamsScreen';
import { TeamAddScreen } from 'screens/TeamAddScreen';
import { TeamUpdateScreen } from 'screens/TeamUpdateScreen';

const TeamsStack = createStackNavigator();

const createScreenOptions = () => {
  return {
    headerShown: false,
    ...TransitionPresets.DefaultTransition,
  };
};

export const TeamsNavigator = () => {
  return (
    <TeamsStack.Navigator screenOptions={createScreenOptions}>
      <TeamsStack.Screen
        name="TeamsScreen"
        component={TeamsScreen}
        options={{ title: 'Teams - Headfirst' }}
      />
      <TeamsStack.Screen
        name="TeamAddScreen"
        component={TeamAddScreen}
        options={{ title: 'Add a team - Headfirst' }}
      />
      <TeamsStack.Screen
        name="TeamUpdateScreen"
        component={TeamUpdateScreen}
        options={{ title: 'Update team - Headfirst' }}
      />
    </TeamsStack.Navigator>
  );
};
