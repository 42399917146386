import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { AuthenticationContext } from 'contexts/AuthenticationContext';
import { ProfileContext } from 'contexts/ProfileContext';

import { getReportTemplates, reportTemplatesTransform, } from 'api/ReportTemplatesApi';

export const ReportTemplatesContext = createContext();

export const ReportTemplatesContextProvider = ({ children }) => {
  const { token } = useContext(AuthenticationContext);
  const { currentClub } = useContext(ProfileContext);

  const [reportTemplates, setReportTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const leagueId = useMemo(() => {
    if (currentClub) {
      return currentClub?.club.affiliations[0]?.leagueId;
    }
  }, [currentClub]);

  const onRetrieve = async () => {
    if (currentClub) {
      setIsLoading(true);
      setReportTemplates([]);
      await getReportTemplates({ leagueId })
        .then(reportTemplatesTransform)
        .then((results) => {
          setIsLoading(false);
          setReportTemplates(results);
          setError(null);
        })
        .catch((err) => {
          setIsLoading(false);
          setReportTemplates([]);
          setError(err);
        });
    }
  };

  useEffect(() => {
    if (token) {
      onRetrieve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, currentClub]);

  return (
    <ReportTemplatesContext.Provider
      value={{
        reportTemplates,
        isLoading,
        error,
        onRetrieve,
      }}
    >
      {children}
    </ReportTemplatesContext.Provider>
  );
};
