import React from 'react';

import {
  Background,
  Container,
  Title,
  LeftContainer,
  LeftTitle,
  LeftButton,
  RightContainer,
  RightButton,
  RightTitle,
  ContainerButtons,
} from './styles'

export const BarHeaderSmall = ({
  title,
  leftButtonTitle,
  onLeftPress,
  rightButtonTitle,
  onRightPress,
  children,
}) => {
  return (
    <Background>
      <Container>
        <Title>{title}</Title>
      </Container>
      <ContainerButtons>
        {onLeftPress && (
          <LeftContainer>
            <LeftButton onPress={onLeftPress}>
              <LeftTitle>{leftButtonTitle}</LeftTitle>
            </LeftButton>
          </LeftContainer>
        )}
        {onRightPress && (
          <RightContainer>
            <RightButton onPress={onRightPress}>
              <RightTitle>{rightButtonTitle}</RightTitle>
            </RightButton>
          </RightContainer>
        )}
        {children}
      </ContainerButtons>
    </Background>
  );
};
