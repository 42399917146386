import { format, parse, isWithinInterval } from 'date-fns';
import {
  SHORT_DATE_FORMAT,
  SHORT_DATE_TIME_FORMAT,
  LONG_DATE_FORMAT,
  LONG_DATE_TIME_FORMAT,
  ISO_DATE_FORMAT,
  ISO_DATE_TIME_FORMAT
} from './constants';

export const parseDate = (
  _date: string
): Date => {
  return parse(_date, ISO_DATE_FORMAT, new Date());
}

export const parseDateTime = (
  _datetime: string
): Date => {
  return parse(_datetime, ISO_DATE_TIME_FORMAT, new Date());
}

export const formattedDate = (
  _date: Date
) => {
  return format(_date, LONG_DATE_FORMAT);
};

export const formattedShortDate = (
  _date: Date
) => {
  if (!formattedShortDate) return '';
  if (_date.getFullYear() === new Date().getFullYear()) {
    return format(_date, SHORT_DATE_FORMAT);
  } else {
    return format(_date, LONG_DATE_FORMAT);
  }
};

export const formattedShortDateTime = (
  _date: Date
) => {
  if (_date.getFullYear() === new Date().getFullYear()) {
    return format(_date, SHORT_DATE_TIME_FORMAT);
  } else {
    return format(_date, LONG_DATE_TIME_FORMAT);
  }
};

export const formattedISODate = (
  _date: Date
) => {
  return format(_date, ISO_DATE_FORMAT);
};

export const formattedISODateTime = (
  _date: Date
) => {
  return format(_date, ISO_DATE_TIME_FORMAT);
};

export const isBetween = (
  date: Date,
  startsOn: Date,
  endsOn: Date
) => {
  return isWithinInterval(date, {
    start: startsOn,
    end: endsOn
  })
}