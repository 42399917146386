import React, { useContext } from 'react';
import { NavigationContainer } from '@react-navigation/native';

import { AppNavigator } from './AppNavigator';
import { AccountNavigator } from './AccountNavigator';

import { AuthenticationContext } from 'contexts/AuthenticationContext';

export const Navigation = ({ linking }) => {
  const { token } = useContext(AuthenticationContext);

  return (
    <NavigationContainer linking={linking}>
      {!!token ? <AppNavigator /> : <AccountNavigator />}
    </NavigationContainer>
  );
};
