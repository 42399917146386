import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { Alert as ReactNativeAlert, Platform } from 'react-native';
import styled from 'styled-components/native';
import { ActivityIndicator } from 'react-native-paper';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';

import { useGetAppVersion } from 'hooks/useAppVersion';

import { Alert } from 'components/Alert';
import { AccountBackground } from 'components/AccountBackground';
import { AccountButton } from 'components/AccountButton';
import { AccountContainer } from 'components/AccountContainer';
import { AccountLink } from 'components/AccountLink';
import { ActivateLink } from 'components/ActivateLink';
import { AuthFields } from 'components/AuthFields';
import { AuthInput } from 'components/AuthInput';
import { AuthInputBorder } from 'components/AuthInputBorder';
import { Spacer } from 'components/Spacer';
import { Text } from 'components/Text';

import { AuthenticationContext } from 'contexts/AuthenticationContext';
import { useCreateCheckAccount } from 'hooks/useCheckAccount';
import { colors } from '../../theme/colors';

const EMAIL = '';
const PASSWORD = '';

const isWeb = Platform.OS === 'web';

const VerticalScroll = styled.ScrollView.attrs({
  horizontal: false,
})``;

const ErrorText = styled.Text`
  color: ${(props) => props.theme.colors.error.red};
  border-color: ${(props) => props.theme.colors.error.red};
  border-width: 1px;
  border-radius: 7px;
  background-radius: 7px;
  background-color: white;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  padding: 8px;
  margin-bottom: 10px;
  overflow: hidden;
`;

export const UserLoginScreen = ({ navigation }) => {
  const [formState, setFormState] = useState('searchEmail');
  const [email, setEmail] = useState(EMAIL);
  const [password, setPassword] = useState(PASSWORD);
  const [showingAlert, setShowingAlert] = useState(false);
  const { reuseToken, onLogin, isLoading, error } = useContext(AuthenticationContext);
  const passwordField = useRef(null);

  const { data: appVersion } = useGetAppVersion();
  const { mutate: checkAccount, data: checkData, isPending, isSuccess, isError, error: checkAccountError } = useCreateCheckAccount();

  useEffect(() => {
    if (checkData?.nextAction === 'login') {
      setFormState('userLogin');
      setTimeout(() => {
        passwordField?.current?.focus();
      }, 100);
    }
    if (checkData?.nextAction === 'activate') {
      navigation.navigate('Activate', { checkData })
    }
  }, [isSuccess, checkData])

  useEffect(() => {
    if (isError) {
      Alert('Login Failed', "Couldn't find your Headfirst account", [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [isError]);

  const saveLabel = useMemo(() => {
    return formState == 'searchEmail' ? "Next" : "Let Me In"
  }, [formState])

  const isValidForm = useMemo(() => {
    if (formState == 'searchEmail') {
      return email !== ''
    }
    if (formState == 'userLogin') {
      return email !== '' && password !== ''
    }
  }, [formState, email, password])

  useEffect(() => {
    if (error) {
      Alert('Login Failed', error.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [error]);

  useEffect(() => {
    reuseToken()
  }, []);

  var hasNewUpdate = useMemo(() => {
    if (appVersion === undefined) {
      return false;
    }
    if (isWeb) {
      return false;
    }
    const semver = require('semver');
    const thisVersion = Constants.expoConfig.version;
    const serverVersion = appVersion.version;
    try {
      if (semver.valid(serverVersion) && semver.valid(thisVersion)) {
        const hasNew = semver.gt(serverVersion, thisVersion);
        return hasNew;
      }
    } catch (e) {
      console.log('hasNewUpdate', e);
    }
    return false;
  }, [appVersion]);

  const openAppStore = () => {
    switch (Platform.OS) {
      case 'android':
        const androidlink =
          'https://play.google.com/store/apps/details?id=fm.simpler.app';
        Linking.canOpenURL(androidlink).then(
          (supported) => {
            supported && Linking.openURL(androidlink);
          },
          (err) => console.log(err),
        );
        break;
      case 'ios':
        const ioslink =
          'itms-apps://apps.apple.com/id/app/simpler-medical-reports/id1501455635?l=id';
        Linking.canOpenURL(ioslink).then(
          (supported) => {
            supported && Linking.openURL(ioslink);
          },
          (err) => console.log(err),
        );
        break;
    }
  };

  useEffect(() => {
    if (hasNewUpdate && !showingAlert) {
      setShowingAlert(true);
      ReactNativeAlert.alert('New App Version Available', appVersion.message, [
        {
          text: 'Update',
          onPress: () => {
            setShowingAlert(false);
            openAppStore();
          },
        },
        {
          text: 'Cancel',
          onPress: () => {
            setShowingAlert(false);
          },
          style: 'cancel',
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNewUpdate]);

  return (
    <AccountBackground>
      <AccountContainer>
        <Spacer size="heading" position="bottom">
          <Text variant="accountHeading">Log in</Text>
        </Spacer>
        <VerticalScroll>
          <AuthFields>
            <AuthInputBorder>
              <AuthInput
                value={email}
                placeholder="Email"
                autoComplete="username"
                keyboardType="email-address"
                textContentType="username"
                autoCapitalize="none"
                returnKeyType="next"
                onChangeText={setEmail}
                onSubmitEditing={() => {
                  if (email) {
                    checkAccount({ email });
                  }
                }}
              />
            </AuthInputBorder>
            {formState == 'userLogin' && (
              <AuthInput
                ref={passwordField}
                value={password}
                placeholder="Password"
                autoComplete="password"
                textContentType="password"
                autoCapitalize="none"
                secureTextEntry={true}
                returnKeyType="go"
                onSubmitEditing={() => onLogin(email, password)}
                onChangeText={setPassword}
              />
            )}
          </AuthFields>
          <AccountLink onPress={() => navigation.navigate('ForgotPassword')}>
            Forgot Password?
          </AccountLink>
          <Spacer size="large">
            {!isLoading ? (
              <AccountButton
                title={saveLabel}
                isDisabled={!isValidForm}
                onPress={() => {
                  if (formState == 'searchEmail') {
                    checkAccount({ email });
                  }
                  if (formState == 'userLogin') {
                    onLogin(email, password);
                  }
                }}
              />
            ) : (
              <ActivityIndicator animating={true} color={colors.twoWeeks.green} />
            )}
          </Spacer>
          <ActivateLink onPress={() => { navigation.navigate('Activate') }}>
            Activate Account
          </ActivateLink>
        </VerticalScroll>
      </AccountContainer>
    </AccountBackground>
  );
};
