import React, { useContext, useMemo } from 'react';
import styled from 'styled-components/native';

import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { Centered } from 'components/Centered';
import { FormTableSelect } from 'components/FormTableSelect';
import { Spacer } from 'components/Spacer';

import { ProfileContext } from 'contexts/ProfileContext';

const Background = styled.View``;
const Scroll = styled.ScrollView.attrs({
  horizontal: false,
})``;

const generateTeamValues = (_teams) => {
  let tempTeams = _teams.map((team) => ({
    key: team.id,
    value: team.name,
  }));
  if (tempTeams.length > 1) {
    tempTeams = [
      {
        key: null,
        value: 'All my teams',
      },
      ...tempTeams,
    ];
  }
  return tempTeams;
};

export const ChangeTeamScreen = ({ navigation }) => {
  const { currentClub, currentTeam, setCurrentTeam } = useContext(ProfileContext);
  const teamValues = useMemo(() => generateTeamValues(currentClub?.teams), [currentClub]);

  const _setTeam = (selected) => {
    if (selected.length > 0) {
      const index = currentClub?.teams.findIndex((team) => team.id === selected[0]);
      let selectedTeam = currentClub?.teams[index];
      setCurrentTeam(selectedTeam);
      navigation.goBack();
    }
  };

  return (
    <Background>
      <BarHeaderSmall
        title="Pick a team"
        leftButtonTitle="Cancel"
        onLeftPress={() => navigation.goBack()}
      />
      <Scroll>
        <Centered>
          <FormTableSelect
            label={''}
            values={teamValues}
            initialKeys={[currentTeam?.id]}
            multiselect={false}
            onSelected={_setTeam}
          />
        </Centered>
        <Spacer size="large" />
        <Spacer size="large" />
        <Spacer size="large" />
        <Spacer size="large" />
        <Spacer size="large" />
        <Spacer size="large" />
      </Scroll>
    </Background>
  );
};
