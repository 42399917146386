import { apiPost, apiPut, apiSecureDelete } from 'api/ApiService';

export const loginRequest = ({ email, password }) => {
  return apiPost('/v1/auth/sign_in', {
    email: email,
    password: password,
  })
    .then((res) =>
      res.json().then((json) => ({
        headers: res.headers,
        json,
      })),
    )
    .then(({ headers, json }) => {
      if (json.success === false) {
        return json;
      } else {
        const token = {
          'authorization': headers.get('authorization'),
        };
        return { token, user: json.data };
      }
    })
    .catch((error) => {
      return { errors: [error] };
    });
};

export const logoutRequest = async () => {
  return apiSecureDelete('/v1/auth/sign_out')
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const deleteAccountRequest = async () => {
  return apiSecureDelete('/v1/auth')
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const resetPasswordRequest = async (email) => {
  return apiPost('/v1/auth/password', {
    email
  })
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const resetPassword = async (
  password,
  passwordConfirmation,
  resetToken,
) => {
  return apiPut('/v1/auth/password', {
    password,
    password_confirmation: passwordConfirmation,
    reset_password_token: resetToken,
  })
    .then((response) => response.json())
    .catch((error) => {
      return { errors: [error] };
    });
};

export const registerRequest = (
  firstName,
  lastName,
  email,
  password,
  passwordConfirmation,
) => {
  return apiPost('/v1/auth', {
    email: email,
    password: password,
    password_confirmation: passwordConfirmation,
    profile_attributes: {
      first_name: firstName,
      last_name: lastName,
    },
  })
    .then((res) =>
      res.json().then((json) => ({
        headers: res.headers,
        json,
      })),
    )
    .then(({ headers, json }) => {
      if (json.success === false) {
        return json;
      } else {
        const token = {
          'authorization': headers.get('authorization'),
        };
        return { token, user: json.data };
      }
    })
    .catch((error) => {
      return { errors: [error] };
    });
};
