import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Alert } from 'components/Alert';
import { Background } from 'components/Background';
import { Loading } from 'components/Loading';

import { ProfileContext } from 'contexts/ProfileContext';
import { useQueryClient } from '@tanstack/react-query';
import { useGetImportPlayer, refreshImportPlayer } from 'hooks/useImportPlayer';

import { DraftPage } from './DraftPage';
import { PreviewPage } from './PreviewPage';
import { ImportPage } from './ImportPage';
import { ImportingPage } from './ImportingPage';
import { CompletePage } from './CompletePage';
import { refreshPlayers } from 'hooks/usePlayer';
import { ProcessPage } from './ProcessPage';
import { ProcessingPage } from './ProcessingPage';

export const ImportPlayerScreen = ({ route, navigation }) => {
  const queryClient = useQueryClient();
  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => currentClub?.clubId, [currentClub]);

  const { data: importPlayer, isLoading, isSuccess, isError, error } = useGetImportPlayer({
    clubId,
    importPlayerId: route.params?.importPlayerId
  });

  useEffect(() => {
    if (isError) Alert('Something went wrong', error?.toString());
  }, [isError]);

  const refreshStuff = (ip) => {
    if (['process', 'processing', 'import', 'importing'].includes(ip?.status)) {
      refreshImportPlayer({ queryClient, clubId: ip?.clubId, importPlayerId: ip?.id });
    }
  }

  useEffect(() => {
    let timer = setInterval(() => refreshStuff(importPlayer), 1000);
    if (!['process', 'processing', 'import', 'importing'].includes(importPlayer?.status)) {
      clearInterval(timer);
    }

    return () => clearInterval(timer)
  }, [importPlayer])

  useEffect(() => {
    if (importPlayer?.status === 'complete') {
      refreshPlayers({ queryClient, clubId })
    }
  }, [importPlayer])

  return (
    <Background>
      {isLoading && (
        <Loading isLoading={true} />
      )}
      {importPlayer?.status == 'draft' && (
        <DraftPage importPlayer={importPlayer} navigation={navigation} />
      )}
      {importPlayer?.status == 'process' && (
        <ProcessPage importPlayer={importPlayer} navigation={navigation} />
      )}
      {importPlayer?.status == 'processing' && (
        <ProcessingPage importPlayer={importPlayer} navigation={navigation} />
      )}
      {importPlayer?.status == 'previewing' && (
        <PreviewPage importPlayer={importPlayer} navigation={navigation} />
      )}
      {importPlayer?.status == 'import' && (
        <ImportPage importPlayer={importPlayer} navigation={navigation} />
      )}
      {importPlayer?.status == 'importing' && (
        <ImportingPage importPlayer={importPlayer} navigation={navigation} />
      )}
      {importPlayer?.status == 'complete' && (
        <CompletePage importPlayer={importPlayer} navigation={navigation} />
      )}
    </Background >
  );
};
