import React, { useContext, useEffect, useMemo, useState } from 'react';

import { DataTable } from 'react-native-paper';
import { Alert } from 'components/Alert';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { ButtonBlue } from 'components/ButtonBlue';
import { DataTableView, Heading, RequiredText, Text } from './styles';
import { Centered } from 'components/Centered';
import { DropDownList } from 'components/DropDownList';
import { Footer } from 'components/Footer';
import { Loading } from 'components/Loading';
import { VerticalScroll } from 'components/VerticalScroll';

import { useUpdateImportPlayer } from 'hooks/useImportPlayer';

const mappableFields = [
  { id: 'first_name', name: 'First name', required: true },
  { id: 'last_name', name: 'Last name', required: true },
  { id: 'team_name', name: 'Team (Team name on PlayHQ)', required: true },
  { id: 'grade_name', name: 'Division (Grade name on PlayHQ)', required: true },
  { id: 'status', name: 'Status', required: false },
  { id: 'role', name: 'Role', required: false },
];

const shorten = (string) => {
  var truncatedString = string.substring(0, 50)
  if (string.length > 50) {
    truncatedString += '...'
  }
  return truncatedString;
}

export const DraftPage = ({ importPlayer, navigation }) => {
  const { mutate: updateImportPlayer, isPending: isUpdating, isError, error } = useUpdateImportPlayer({ clubId: importPlayer?.clubId });
  const [fieldMapping, setFieldMapping] = useState([])

  const importableFields = useMemo(() => {
    if (importPlayer?.fieldNames) {
      const fields = importPlayer.fieldNames.map((fieldName, index) => {
        return {
          id: fieldName,
          name: shorten(fieldName),
          example: importPlayer?.sampleValues[index]
        }
      })
      return [
        { id: '', name: 'Select a field', example: '' },
        ...fields,
      ]
    }
  }, [importPlayer])

  useEffect(() => {
    if (!importPlayer?.fieldNames) return;
    if (!importPlayer?.fieldMapping) return;

    if (mappableFields) {
      setFieldMapping(mappableFields.map((mappableField) => {
        const matchingFieldIndex = importPlayer?.fieldNames.findIndex((element) => (element === mappableField.name));
        const matchingMappableFieldIndex = importPlayer?.fieldMapping.findIndex((fieldMap) => (fieldMap.to === mappableField.id));

        var value = '';
        var example = '';

        if (matchingMappableFieldIndex >= 0) {
          value = importPlayer?.fieldMapping[matchingMappableFieldIndex].from
        }
        if (matchingFieldIndex >= 0) {
          if (!value) {
            value = importPlayer?.fieldNames[matchingFieldIndex]
          }
          example = importPlayer?.sampleValues[matchingFieldIndex]
        }
        return {
          id: mappableField.id,
          fieldName: mappableField.name,
          required: mappableField.required,
          value: value,
          example: example
        }
      }));
    }
  }, [importPlayer, mappableFields]);

  const changeFieldFocus = (fieldName, isFocus) => {
    const mappingIndex = fieldMapping.findIndex((element) => (element.fieldName == fieldName));
    if (mappingIndex != -1) {
      const newFieldMapping = fieldMapping.map((fieldMap, index) => {
        if (index === mappingIndex) {
          fieldMap.isFocus = isFocus
        }
        return fieldMap;
      });
      setFieldMapping(newFieldMapping);
    }
  }

  const changeFieldMapping = (fieldName, value, example) => {
    const mappingIndex = fieldMapping.findIndex((element) => (element.fieldName == fieldName));
    if (mappingIndex != -1) {
      const newFieldMapping = fieldMapping.map((fieldMap) => {
        if (fieldMap.fieldName === fieldName) {
          fieldMap.value = value
          fieldMap.example = example
        } else {
          if (fieldMap.value === value) {
            fieldMap.value = 'ignore'
            fieldMap.example = ''
          }
        }
        return fieldMap;
      });
      setFieldMapping(newFieldMapping);
    }
  }

  const canContinue = useMemo(() => {
    if (!importPlayer?.fieldNames) return false;

    const requiredFields = fieldMapping.filter((field) => field.required);
    const mappedFields = fieldMapping.filter((field) => field.required && !!field.value);
    return JSON.stringify(mappedFields) === JSON.stringify(requiredFields);
  }, [fieldMapping]);

  const save = () => {
    const field_mapping = fieldMapping
      .filter((f) => !!f.value)
      .map((f) => { return { from: f.value, to: f.id } })

    const formData = {
      id: importPlayer?.id,
      club_id: importPlayer?.clubId,
      status: 'process',
      field_mapping
    };
    updateImportPlayer(formData);
  };

  const navBack = () => navigation.navigate('PlayersScreen');

  useEffect(() => {
    if (isError) Alert('Something went wrong', error?.toString());
  }, [isError]);

  return (
    <>
      <BarHeaderSmall
        title={'Import Players - Draft'}
        leftButtonTitle="Back"
        onLeftPress={navBack}
      />
      {fieldMapping && (
        <>
          <DataTableView>
            <DataTable.Header>
              <DataTable.Title style={{ flex: 1 }}>Headfirst fields</DataTable.Title>
              <DataTable.Title style={{ flex: 2 }}>Columns on your spreadsheet</DataTable.Title>
              <DataTable.Title style={{ flex: 1 }}>Sample values</DataTable.Title>
            </DataTable.Header>
            < VerticalScroll >
              {fieldMapping.map((field, index) => {
                return (
                  <DataTable.Row key={index}>
                    <DataTable.Cell style={{ flex: 1 }} textStyle={{ fontWeight: (field?.isFocus ? 700 : 'normal') }}>
                      {field.fieldName}
                      <RequiredText>
                        {field.required ? '*' : ''}
                      </RequiredText>
                    </DataTable.Cell>
                    <DataTable.Cell style={{ flex: 2 }}>
                      <DropDownList
                        items={importableFields}
                        value={field.value}
                        onFocus={() => changeFieldFocus(field.fieldName, true)}
                        onBlur={() => changeFieldFocus(field.fieldName, false)}
                        onChangeValue={(value) => {
                          const example = importableFields.find((element) => (element.name == value))?.example;
                          changeFieldMapping(field.fieldName, value, example);
                        }}
                      />
                    </DataTable.Cell>
                    <DataTable.Cell style={{ flex: 1 }}>{field.example}</DataTable.Cell>
                  </DataTable.Row>
                )
              })}
              {(fieldMapping.length === 0) && (
                <Centered>
                  <Heading style={{ paddingTop: 64 }}>Incorrect file format</Heading>
                  <Text>Make sure you’re uploading .xlsx or .csv files only</Text>
                </Centered>
              )}
            </VerticalScroll>
          </DataTableView>
          <Footer>
            <Centered>
              <ButtonBlue
                label="Continue"
                isDisabled={!canContinue}
                onPress={() => {
                  save();
                }}
              />
            </Centered>
          </Footer>
        </>
      )}
      <Loading isLoading={isUpdating} />
    </>
  );
};
