import React from 'react';

import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { CardInformation } from 'components/CardInformation';
import { CardInformationRow, Container, StatusText } from './styles';
import { Centered } from 'components/Centered';
import { colors } from 'theme/colors';
import { Footer } from 'components/Footer';
import { ProgressBar } from 'react-native-paper';

export const ImportingPage = ({ importPlayer, navigation }) => {
  const navBack = () => navigation.navigate('PlayersScreen');

  return (
    <>
      <BarHeaderSmall
        title={'Import Players - Importing'}
        leftButtonTitle="Back"
        onLeftPress={navBack}
      />
      {importPlayer && (
        <>
          <Container>
            <Centered>
              <CardInformationRow>
                <CardInformation title='Filename'>
                  {importPlayer?.file?.filename}
                </CardInformation>
              </CardInformationRow>
              <CardInformationRow>
                <CardInformation title='Importing'>
                </CardInformation>
              </CardInformationRow>
              <ProgressBar progress={importPlayer.totalImported / importPlayer.totalPlayers} color={colors.twoWeeks.green} />
              <StatusText>{`${importPlayer.totalImported} of ${importPlayer.totalPlayers}`}</StatusText>
            </Centered>
          </Container>
          <Footer>
            <Centered>
            </Centered>
          </Footer>
        </>
      )}
    </>
  );
};
