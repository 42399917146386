import React from 'react';

import {
  Container,
  HeaderContainer,
  HeaderText,
  RequiredText,
  RowSelection,
  RowContainer,
  ValuesContainer,
  LabelText,
  ValueText,
  Extra,
  Icon,
} from './styles'

export const FormFieldSelect = ({
  label,
  header,
  required,
  values,
  extra,
  onSelected,
}) => {
  return (
    <Container>
      {header && (
        <HeaderContainer>
          <HeaderText>{header}</HeaderText>
          {required && <RequiredText>(Required)</RequiredText>}
        </HeaderContainer>
      )}
      <RowSelection onPress={onSelected}>
        <RowContainer>
          <LabelText>{label}</LabelText>
          <ValuesContainer>
            {Array.isArray(values) && values?.map((v) => {
              return <ValueText key={`${label}${v}`}>{v}</ValueText>;
            })}
          </ValuesContainer>
          {onSelected ? <Icon /> : <Extra>{extra}</Extra>}
        </RowContainer>
      </RowSelection>
    </Container>
  );
};
