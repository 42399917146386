import React from 'react';

import {
  Background,
  Switcher
} from './styles';

export const BarMenu = ({ children }) => {
  return (
    <Background>
      <Switcher>{children}</Switcher>
    </Background>
  );
};
