import { useQuery } from '@tanstack/react-query';
import { getLeagueAgeGroups } from 'api/LeagueAgeGroupsApi';

export const useGetLeagueAgeGroups = ({ leagueId }) => {
  return useQuery({
    queryKey: queryKey({ leagueId }),
    queryFn: getLeagueAgeGroups,
    // refetchInterval: 1000 * 60, // every minute
    enabled: !!leagueId
  });
};

export const refreshLeagueAgeGroups = ({ queryClient, leagueId }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey({ leagueId })
  });
};

const queryKey = ({ leagueId }) => {
    return ['leagues', leagueId, 'ageGroups'];
}
