import { getAuthToken } from "utils/AuthToken";
import { host } from "../Env";

export const apiUrl = (path: string) =>
  new URL(path, host).toString();

const formatRequestBody = (body) =>
  (body instanceof FormData) ? body : JSON.stringify(body);

const formatRequestHeader = (body) =>
  (body instanceof FormData) ? {
    Accept: 'application/json',
  } : {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

const formatSecureRequestHeader = (authToken, body) =>
  (body instanceof FormData) ? {
    Authorization: `Bearer ${authToken}`,
    Accept: 'application/json',
  } : {
    Authorization: `Bearer ${authToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

export const apiGet = (path) => {
  return fetch(apiUrl(path), {
    method: 'GET',
    headers: formatRequestHeader({}),
  })
}

export const apiPost = async (path, body) => {
  return fetch(apiUrl(path), {
    method: 'POST',
    headers: formatRequestHeader(body),
    body: formatRequestBody(body)
  })
}

export const apiPut = async (path, body) => {
  return fetch(apiUrl(path), {
    method: 'PUT',
    headers: formatRequestHeader(body),
    body: formatRequestBody(body)
  })
}

export const apiSecureGet = async (path) => {
  const { authToken } = await getAuthToken();
  return fetch(apiUrl(path), {
    method: 'GET',
    headers: formatSecureRequestHeader(authToken, {}),
  })
}

export const apiSecurePost = async (path, body) => {
  const { authToken } = await getAuthToken();

  return fetch(apiUrl(path), {
    method: 'POST',
    headers: formatSecureRequestHeader(authToken, body),
    body: formatRequestBody(body)
  })
}

export const apiSecurePut = async (path, body) => {
  const { authToken } = await getAuthToken();

  return fetch(apiUrl(path), {
    method: 'PUT',
    headers: formatSecureRequestHeader(authToken, body),
    body: formatRequestBody(body)
  })
}

export const apiSecureDelete = async (path) => {
  const { authToken } = await getAuthToken();
  return fetch(apiUrl(path), {
    method: 'DELETE',
    headers: formatSecureRequestHeader(authToken, {}),
  })
}

