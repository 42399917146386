import { useInfiniteQuery } from '@tanstack/react-query';
import { getImportPlayerRows } from 'api/ImportPlayerRowsApi';

// Returns: { isLoading, data, hasNextPage, fetchNextPage }
export const useGetInfiniteImportPlayerRows = ({ clubId, importPlayerId }) => {
  return useInfiniteQuery({
    queryKey: queryKey({ clubId, importPlayerId }),
    queryFn: getImportPlayerRows,
    initialPageParam: 1,
    // refetchInterval: 1000 * 5, // 5 seconds
    getNextPageParam: (lastPage, pages) => {
      return lastPage.meta.page.next;
    },
    enabled: !!clubId && !!importPlayerId
  });
};

export const refreshImportPlayerJobs = ({ queryClient, clubId, importPlayerId }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey({ clubId, importPlayerId })
  });
};

const queryKey = ({ clubId, importPlayerId }) => {
  return ['clubs', clubId, 'importPlayers', importPlayerId, 'rows'];
}
