import React, { useEffect, useState } from 'react';

import {
  Background,
  Date,
  TitleRow,
  Title,
} from './styles'

import { format } from 'date-fns'

export const BarHeaderLarge = ({ title, children }) => {
  const [date, setDate] = useState('');

  useEffect(() => {
    const today = new window.Date();
    setDate(format(today, 'EEEE, d MMMM'));
  }, []);

  return (
    <Background>
      <Date>{date}</Date>
      <TitleRow>
        <Title>{title}</Title>
        {children}
      </TitleRow>
    </Background>
  );
};
