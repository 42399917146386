import camelize from 'camelize';
import {
  parseDate,
  parseDateTime,
  formattedDate,
  formattedShortDate,
} from 'utils/Dates';
import { apiSecureGet, apiSecureDelete, apiSecurePost, apiSecurePut } from 'api/ApiService';
import { formattedShortDateTime } from 'utils/Dates';

export const getTrainers = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/trainers`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return trainersTransform(data);
};

export const getTrainer = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const trainerId = queryKey[3];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/trainers/${trainerId}`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return trainerTransform(data);
};

export const createTrainer = async ({ clubId, club_trainer }) => {
  const response = await apiSecurePost(`/v1/clubs/${clubId}/trainers`, {
    club_trainer
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const responseData = await response.json();
  return trainerTransform(responseData);
};

export const updateTrainer = async ({ clubId, trainerId, club_trainer }) => {
  const response = await apiSecurePut(`/v1/clubs/${clubId}/trainers/${trainerId}`, {
    club_trainer
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const responseData = await response.json();
  return trainerTransform(responseData);
};

export const deleteTrainer = async ({ clubId, trainerId }) => {
  const response = await apiSecureDelete(`/v1/clubs/${clubId}/trainers/${trainerId}`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
};

const transform = (result = {}) => {
  if (result?.user) {
    result.user.createdAtShort = formattedShortDateTime(parseDateTime(result.user.created_at));
  }
  result.createdAtShort = formattedShortDateTime(parseDateTime(result.created_at));
  result.updatedAtShort = formattedShortDateTime(parseDateTime(result.updated_at));
  return camelize(result);
};

export const trainerTransform = (result = {}) => {
  return transform(result.data);
};

export const trainersTransform = (results = {}) => {
  return results.data.map((result) => transform(result));
};
