import React from 'react';

import {
  LoadingActivityIndicator,
  LoadingContainer,
} from './styles'
import { colors } from 'theme/colors';

export const Loading = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <LoadingContainer>
          <LoadingActivityIndicator
            size={50}
            animating={true}
            color={colors.twoWeeks.green}
          />
        </LoadingContainer>
      )}
    </>
  );
};
