import { useQuery } from '@tanstack/react-query';
import { getAppVersion } from 'api/AppVersionsApi';

export const useGetAppVersion = () => {
  return useQuery({
    queryKey: ['app_version'],
    queryFn: getAppVersion,
    refetchInterval: 1000 * 60 * 30, // every 30 minutes
  });
};
