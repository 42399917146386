import React from 'react';
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';

import { InsightsScreen } from 'screens/InsightsScreen';

const InsightsStack = createStackNavigator();

const createScreenOptions = () => {
  return {
    headerShown: false,
    ...TransitionPresets.DefaultTransition,
  };
};

export const InsightsNavigator = () => {
  return (
    <InsightsStack.Navigator screenOptions={createScreenOptions}>
      <InsightsStack.Screen name="InsightsScreen" component={InsightsScreen} />
    </InsightsStack.Navigator>
  );
};
